export const ServiceTypeOptions = [
  { label: "管理", value: 1 },
  { label: "融资", value: 2 },
  { label: "催收", value: 3 },
  { label: "担保", value: 4 },
];

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
