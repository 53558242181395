import * as React from "react";
import {
  getRepaymentApplyDetail,
  getBusinessDetail,
} from "@/requests/business";
import { useHistory, useLocation } from "react-router";
import {
  message,
  Spin,
  Result,
  Divider,
  Descriptions,
  Steps,
  Button,
} from "antd";
import { toThousands } from "@/utils/string";
import FileViewer from "@/ui-components/FileViewer";
import moment from "moment";
import RepaymentSMApprovalModal from "../components/RepaymentSMApprovalModal";
import RepaymentBSApprovalModal from "../components/RepaymentBSApprovalModal";
import RepaymentCFOApprovalModal from "../components/RepaymentCFOApprovalModal";

enum RepaymentType {
  "正常期还款" = 1,
  "逾期还款",
  "提前还款",
  "提前回购",
  "其他还款",
}

enum applyRes {
  "待业务人员审核" = 1,
  "待业务主管审核" = 2,

  "待财务人员审核" = 3,

  "审核完成" = 4,
}

const RepaymentApplyDetail = () => {
  const [data, setData] = React.useState<RepaymentApplyListDetail>();
  const [busibess, setBusiness] = React.useState<BusinessItem>();
  const location = useLocation();
  const history = useHistory();
  const [approveVisible, setApproveVisible] = React.useState(false);
  const [repaymentId, setRepaymentId] = React.useState(0);

  //获取当前审核状态
  const getStatus = (status: number) => {
    if (status === 22) {
      return "success";
    }

    if (status === 19) {
      return "error";
    }
    return "info";
  };

  const getSubTitle = (status: number) => {
    if (status === 17) {
      return "等待审核中，请尽快完成还款信息补充，提交业务主管审核";
    }
    if (status === 18) {
      return "业务人员审核已完成，请结合业务人员审核结果，提交复审意见";
    }
    if (status === 19) {
      return "业务员审核不通过，申请关闭";
    }
    if (status === 20) {
      return "业务部门审核完成，请结合业务部门审核结果，提交财务审批意见";
    }
    if (status === 21) {
      return "业务主管审核不通过，待业务员重新审核";
    }
    if (status === 22) {
      return "还款审核通过，还款已生效";
    }
    if (status === 24) {
      return "财务人员审核不通过，待业务主管重新审核";
    }

    return "审核中";
  };

  const fetchData = async (id: RepaymentApplyListItem["repayment_id"]) => {
    if (!id) {
      id = repaymentId;
    }
    const res = await getRepaymentApplyDetail(id);
    if (res.code === 0) {
      setData(res.data);
    } else {
      message.error("网络错误");
    }
  };

  const fetchBusiness = async (id: number) => {
    const res = await getBusinessDetail(id);
    if (res.code === 0) {
      setBusiness(res.data);
    } else {
      message.error("网络错误");
    }
  };

  //获取当前步骤
  const getCurrent = (status: number) => {
    if (status === 17 || status === 21) {
      return 1;
    } else if (status === 18 || status === 24) {
      return 2;
    } else if (status === 20) {
      return 3;
    } else if (status === 22) {
      return 4;
    } else {
      return 4;
    }
  };

  React.useEffect(() => {
    if (
      location.pathname === "/workbench/business/repayment/apply-list/detail"
    ) {
      if (location.search) {
        const repaymenId = location.search.split("&")[0].split("=")[1];
        const businessId = location.search.split("&")[1].split("=")[1];
        setRepaymentId(Number(repaymenId));
        fetchData(Number(repaymenId));
        fetchBusiness(Number(businessId));
      }
    }
  }, [location]);
  if (!data || !busibess) return null;
  return (
    <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
      <Steps
        current={getCurrent(data.status)}
        size="small"
        style={{ margin: "16px 0" }}
      >
        <Steps.Step title="提交" description="用户提交申请"></Steps.Step>
        <Steps.Step title="业务员审核" description={"业务员"}></Steps.Step>
        <Steps.Step title="业务主管审核" description="业务主管"></Steps.Step>

        <Steps.Step title="财务审核" description="财务主管"></Steps.Step>

        <Steps.Step title="审核完成" description="完成业务审批"></Steps.Step>
      </Steps>
      <Result
        style={{ padding: 16 }}
        status={getStatus(data.status)}
        title={applyRes[getCurrent(data.status)]}
        subTitle={getSubTitle(data.status)}
        extra={[
          data.status !== 22 && data.status !== 19 && (
            <Button
              type="primary"
              onClick={() => {
                setApproveVisible(true);
              }}
            >
              开始审核
            </Button>
          ),
        ]}
      />
      <Divider>还款详情</Divider>
      <Descriptions>
        <Descriptions.Item label="还款类型">
          {RepaymentType[data.type]}
        </Descriptions.Item>
        <Descriptions.Item label="还款金额">
          {toThousands(data.amount)} 元
        </Descriptions.Item>

        <Descriptions.Item label="还款凭证">
          <FileViewer file={data.transfer_certificate} />
        </Descriptions.Item>
        <Descriptions.Item label="申请时间">
          {data.create_time}
        </Descriptions.Item>
        <Descriptions.Item label="逾期天数">
          {data.overdue_day ? <span>{data.overdue_day} 天</span> : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="逾期利率">
          {data.overdue_rate ? <span>{data.overdue_rate}%</span> : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="正常期利率">
          {data.normal_rate ? <span>{data.normal_rate}%</span> : "-"}
        </Descriptions.Item>

        <Descriptions.Item label="宽限期利率">
          {data.grace_rate ? <span>{data.grace_rate}%</span> : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="计息天数">
          {data.interest_day ? <span>{data.interest_day} 天</span> : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="业务审核备注">
          {data.memo ? data.memo : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="应还总金额">
          {data.total ? <span>{toThousands(data.total)} 元</span> : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="实际收款金额">
          {data.collection_amount ? (
            <span>{toThousands(data.collection_amount)} 元</span>
          ) : (
            "-"
          )}
        </Descriptions.Item>

        <Descriptions.Item label="收款时间">
          {data.collection_time ? data.collection_time : "-"}
        </Descriptions.Item>
      </Descriptions>
      <Divider>业务信息</Divider>
      <Descriptions>
        <Descriptions.Item label="保理融资款金额">
          {toThousands(busibess.quota)} 元
        </Descriptions.Item>
        <Descriptions.Item label="申请时间">
          {busibess.create_time}
        </Descriptions.Item>
        <Descriptions.Item label="放款时间">
          {busibess.billing_loan.billing_time}
        </Descriptions.Item>

        <Descriptions.Item label="保理融资期限">
          {busibess.quota_deadline ? (
            <span>{busibess.quota_deadline} 天</span>
          ) : (
            "-"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="到期日">
          {moment(
            new Date(busibess.create_time).setDate(
              new Date(busibess.create_time).getDate() + busibess.quota_deadline
            )
          ).format("YYYY-MM-DD")}
        </Descriptions.Item>
        <Descriptions.Item label="">
          <a
            onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname: `/workbench/business/business-list/detail`,

                search: `id=${busibess.id}`,
              });
            }}
          >
            查看业务详情
          </a>
        </Descriptions.Item>
      </Descriptions>
      {data && (data.status === 17 || data.status === 21) && (
        <RepaymentSMApprovalModal
          visible={approveVisible}
          setVisible={setApproveVisible}
          fetchData={fetchData}
          data={data}
          id={repaymentId}
        />
      )}
      {data && (data.status === 18 || data.status === 24) && (
        <RepaymentBSApprovalModal
          visible={approveVisible}
          setVisible={setApproveVisible}
          fetchData={fetchData}
          data={data}
          id={repaymentId}
        />
      )}
      {data && data.status === 20 && (
        <RepaymentCFOApprovalModal
          visible={approveVisible}
          setVisible={setApproveVisible}
          fetchData={fetchData}
          data={data}
          id={repaymentId}
        />
      )}
    </div>
  );
};

export default RepaymentApplyDetail;
