import * as React from "react";
import { getCompanyApplyList } from "@/requests/company";
import { message, Form, Select, Button, List, Input } from "antd";
import "../styles/index.less";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { useCtxState } from "@/store/App.store";

const CompanyApplyList = () => {
  const [{ userInfo }] = useCtxState();
  const history = useHistory();
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = React.useState(false);
  const [filterName, setFilterName] = React.useState("");
  const [data, setData] = React.useState<CompanyProfileApplyItem[]>([]);
  const [type, setType] = React.useState("");

  const fetchCompanyApplyList = async (page?: number, pageSize?: number) => {
    setLoading(true);
    if (!page) {
      page = pagination.page;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    const res = await getCompanyApplyList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
      ...(type && {
        process_definition_key: type,
      }),
    });
    if (res.code === 0) {
      setData(res.data.filter((x) => x !== null));
      setPagination({
        page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    setLoading(true);
    // fetchCompanyApplyList();
  }, []);

  React.useEffect(() => {
    fetchCompanyApplyList();
  }, [type]);
  return (
    <div className="company-apply-list-page">
      <div className="tools-wrapper">
        <div className="company-apply-list-filter-input-container">
          <Form layout="inline" colon={false}>
            <Form.Item label="申请类型" className="filter-item">
              <Select
                style={{ minWidth: 168 }}
                placeholder="全部"
                onChange={(value: string) => setType(value)}
                value={type}
              >
                <Select.Option value={""}>全部</Select.Option>
                <Select.Option value={"submit_company_info_approval"}>
                  企业绑定申请
                </Select.Option>
                <Select.Option value={"submit_update_base_info_approval"}>
                  企业基础信息变更申请
                </Select.Option>
                <Select.Option value={"submit_legal_person_approval"}>
                  企业法人变更申请
                </Select.Option>
                <Select.Option value={"submit_agent_info_approval"}>
                  企业经办人变更申请
                </Select.Option>
                <Select.Option value={"submit_finance_taxation_info_approval"}>
                  企业财税信息变更申请
                </Select.Option>
                <Select.Option value={"submit_shareholder_info_approva"}>
                  企业股东信息变更申请
                </Select.Option>
                <Select.Option value={"submit_open_permit_info_approval"}>
                  企业开户许可证变更申请
                </Select.Option>
                <Select.Option value={"submit_charter_info_approval"}>
                  企业章程变更申请
                </Select.Option>
              </Select>
            </Form.Item>
            {/* <Form.Item label="公司名称" className="filter-item">
              <Input
                placeholder="输入公司名称搜索"
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
              />
            </Form.Item> */}
          </Form>
        </div>
        <div style={{ display: "flex" }}>
          <Button
            type="primary"
            onClick={() => fetchCompanyApplyList()}
            icon={<SearchOutlined />}
          >
            搜索
          </Button>
        </div>
      </div>
      <div style={{ backgroundColor: "#FFFFFF", padding: 16 }}>
        <List
          dataSource={data}
          loading={loading}
          renderItem={(item) => {
            if (item === null) return null;
            return (
              <List.Item
                key={item.id}
                actions={[
                  <a
                    key="view"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/workbench/company-management/company-apply-detail`,
                        search: `id=${item.id}`,
                        state: {
                          apply: item,
                        },
                      });
                    }}
                  >
                    查看详情
                  </a>,
                ]}
              >
                <List.Item.Meta
                  title={`${item.company_name}--${item.process_definition_name}`}
                  description={`提交时间：${item.create_time}`}
                />
              </List.Item>
            );
          }}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchCompanyApplyList(page, pageSize);
            },
            style: { marginRight: 16 },
            showTotal: (total) => <span>共{total}条数据</span>,
          }}
        />
      </div>
    </div>
  );
};

export default CompanyApplyList;
