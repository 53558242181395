import * as React from "react";
import { getContractList, contractPreview } from "@/requests/contractCommon";
import { message, Radio, Table } from "antd";
import { newWin } from "@/utils/network";
import { sendContract } from "@/requests/contractCommon";

//合同状态
enum ContractStatus {
  "未发送" = 1,
  "待签署",
  "签署完成",
  "拒绝签署",
}

const ContractList = () => {
  const [data, setData] = React.useState<ContractListItem[]>([]);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = React.useState(false);
  const [filterStatus, setFilterStatus] = React.useState(0);

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.page;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    setLoading(true);
    const res = await getContractList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
      ...(filterStatus !== 0 && {
        status: filterStatus,
      }),
    });
    if (res.code === 0) {
      setData(res.data);
      setPagination({
        page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const preview = async (contract_id: string) => {
    message.loading("即将前往预览");
    const res = await contractPreview({
      page_parameter: {
        contractId: contract_id,
      },
      target_page: "permissionView",
    });
    if (res.code === 0) {
      newWin(res.data.value, res.data.value);
    } else {
      message.error("网络错误");
    }
  };

  const doSendContract = async (contract: ContractListItem) => {
    if (!contract) return;
    if (!data) return;
    message.loading("正在发送合同");
    let map: any = {};
    JSON.parse(contract.recipient).map((x: any) => {
      map[x.role] = JSON.parse(
        JSON.stringify({ enterpriseName: x.name, userAccount: x.phone })
      );
    });
    console.log(map);
    const res = await sendContract({
      biz_type: 0,
      template_content: JSON.parse(contract.contract_content),
      template_id: contract.template_id,
      template_roles: map,
      template_type: contract.type,
      approval_id: contract.approval_id,
      uuid: contract.uuid,
    });

    if (res.code === 0) {
      message.destroy();
      message.success("发送成功，请提醒客户签署");
      fetchData();
    } else {
      message.error(res.message);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);
  React.useEffect(() => {
    fetchData();
  }, [filterStatus]);
  return (
    <div style={{ padding: 16, backgroundColor: "#ffffff" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: 24,
        }}
      >
        <Radio.Group
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
          buttonStyle={"solid"}
        >
          <Radio.Button value={0}>全部</Radio.Button>
          <Radio.Button value={1}>未发送</Radio.Button>
          <Radio.Button value={2}>待签署</Radio.Button>
          <Radio.Button value={3}>已完成</Radio.Button>
          <Radio.Button value={4}>拒绝签署</Radio.Button>
        </Radio.Group>
      </div>
      <Table
        dataSource={data}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            fetchData(page, pageSize);
          },
          showTotal: (total) => <span>共 {total} 条数据</span>,
        }}
        loading={loading}
        columns={[
          {
            title: "序号",
            align: "center",
            render: (value, record, index) => index + 1,
          },
          {
            title: "合同名称",
            align: "center",
            render: (value, record, index) => record.template_name,
          },
          {
            title: "接收方",
            align: "center",
            render: (value, record, index) =>
              record.recipient
                ? JSON.parse(record.recipient)
                    .map((x: Recipient) =>
                      x.enterpriseName ? x.enterpriseName : x.name
                    )
                    .toString()
                : "无",
          },
          {
            title: "合同状态",
            align: "center",
            render: (value, record, index) => ContractStatus[record.status],
          },
          {
            title: "合同编号",
            align: "center",
            render: (value, record, index) =>
              record.contract_id ? record.contract_id : "无",
          },
          {
            title: "创建日期",
            align: "center",
            render: (value, record, index) => record.create_time,
          },
          {
            title: "操作",
            align: "center",
            render: (value, record, index) => (
              <span>
                {record.status === 3 && (
                  <a
                    style={{ paddingRight: 8 }}
                    onClick={(e) => {
                      e.preventDefault();
                      preview(record.contract_id);
                    }}
                  >
                    查看
                  </a>
                )}
                {record.status === 1 && (
                  <a
                    style={{ paddingRight: 8 }}
                    onClick={(e) => {
                      e.preventDefault();
                      doSendContract(record);
                    }}
                  >
                    发送
                  </a>
                )}
              </span>
            ),
          },
        ]}
      />
    </div>
  );
};

export default ContractList;
