import React from "react";
import { Modal, message, Spin, Empty, Button, Checkbox, Row, Col } from "antd";
import * as authRequests from "@/requests/auth";
import { useHistory } from "react-router";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

interface Props {
  visible: boolean;
  setVisible: Function;
  fetchData: Function;
  role: RoleItem | undefined;
}

const UpdateRoleAuthTable = (props: Props) => {
  const { visible, setVisible, fetchData, role } = props;
  const [fetching, setFetching] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [authes, setAuthes] = React.useState<SysMenuItem[]>([]);
  const [selectedAuthes, setSelectedAuthes] = React.useState<
    CheckboxValueType[]
  >([]);
  const history = useHistory();
  const handleOk = async () => {
    if (!role) return;
    doUpdate({
      id: role.id,
      sys_menu_id_list: selectedAuthes.map((x) => Number(x)),
    });
  };

  const handleCancel = () => {
    Modal.confirm({
      title: "温馨提示",
      content: "确认放弃当前编辑内容吗？",
      okText: "放弃",
      cancelText: "继续编辑",
      autoFocusButton: "cancel",
      onOk: () => setVisible(false),
      onCancel: () => {},
    });
  };

  const fetchAuthes = async () => {
    const res = await authRequests.getSysMenuList({
      limit: 1000,
      skip: 0,
    });
    if (res.code === 0) {
      setAuthes(res.data);
    } else {
      message.error(res.message);
    }
    setLoading(false);
    return res.data;
  };

  const fetchRoleAuthInfo = async () => {
    if (!role) return;
    const res = await authRequests.getAuthListByRoleId(role.id);

    if (res.code === 0) {
      setSelectedAuthes(res.data.map((x) => x.id));
    } else {
      message.error(res.message);
    }
  };

  React.useEffect(() => {
    setLoading(true);

    fetchAuthes();
    fetchRoleAuthInfo();
  }, []);

  const doUpdate = async (input: UpdateRoleAuthReq) => {
    setFetching(true);
    const res = await authRequests.updateRoleAuth(input);
    if (res.code === 0) {
      message.success("保存成功");
      setVisible(false);
      fetchData();
    } else {
      message.error("保存失败");
    }
    setFetching(false);
  };

  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>权限设置
        </span>
      }
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="保存"
      cancelText="取消"
      okButtonProps={{ loading: fetching }}
      destroyOnClose
    >
      <div style={{ minHeight: 300 }}>
        {loading ? (
          <Spin
            tip="加载中"
            spinning={loading}
            style={{ position: "absolute", top: "50%", left: "50%" }}
          />
        ) : (
          <>
            {authes.length < 1 && (
              <div>
                <Empty description="暂无权限，请先创建权限">
                  <Button
                    type="primary"
                    onClick={() =>
                      history.push("/workbench/auth-management/auth")
                    }
                  >
                    去创建
                  </Button>
                </Empty>
              </div>
            )}
            <Checkbox.Group
              style={{ width: "100%" }}
              onChange={(checkedValue: CheckboxValueType[]) =>
                setSelectedAuthes(checkedValue)
              }
              value={selectedAuthes}
            >
              <Row>
                {authes.map((x) => {
                  return (
                    <Col span={8} key={x.id} style={{ padding: 10 }}>
                      <Checkbox value={x.id}>{x.name}</Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </>
        )}
      </div>
    </Modal>
  );
};

export default UpdateRoleAuthTable;
