import * as React from "react";
import { getRepaymentApplyList } from "@/requests/business";
import { Input, Button, Form, List, DatePicker, Tag, message } from "antd";
import { RedoOutlined, SearchOutlined } from "@ant-design/icons";
import { toThousands } from "@/utils/string";
import "../styles/index.less";
import { useHistory, useLocation } from "react-router";

enum Status {
  "待业务员审核" = 17,
  "待业务主管审核" = 18,
  "业务员审核失败，申请关闭" = 19,
  "待财务人员审核" = 20,
  "待业务员重新审核" = 21,
  "已完成" = 22,
  "待业务主管重新审核" = 24,
}

const StatusTag = ({ status }: { status: number }) => {
  if (status === 17) {
    return <Tag color={"cyan"}>{Status[status]}</Tag>;
  }
  if (status === 18) {
    return <Tag color={"cyan"}>{Status[status]}</Tag>;
  }
  if (status === 19) {
    return <Tag color={"red"}>{Status[status]}</Tag>;
  }
  if (status === 20) {
    return <Tag color={"cyan"}>{Status[status]}</Tag>;
  }
  if (status === 21) {
    return <Tag color={"cyan"}>{Status[status]}</Tag>;
  }
  if (status === 22) {
    return <Tag color={"green"}>{Status[status]}</Tag>;
  }
  if (status === 24) {
    return <Tag color={"cyan"}>{Status[status]}</Tag>;
  }
  return <Tag color={"cyan"}>{Status[status]}</Tag>;
};

const RepaymentApplyList = () => {
  const [data, setData] = React.useState<RepaymentApplyListItem[]>([]);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = React.useState(false);

  const history = useHistory();
  const location = useLocation();

  const fetchData = async (page?: number, pageSize?: number) => {
    setLoading(true);
    if (!page) {
      page = pagination.page;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    const res = await getRepaymentApplyList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
    });
    if (res.code === 0) {
      setData(res.data);
      setPagination({
        ...pagination,
        page: page,
        pageSize: pageSize,
        total: res.total,
      });
    } else {
      message.error("网络错误");
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (location.pathname === "/workbench/business/repayment/apply-list") {
      fetchData();
    }
  }, [location]);
  return (
    <div className="repayment-apply-list-page">
      <div className="tools-wrapper">
        <Form colon={false} layout="inline">
          <Form.Item label="企业名称" className="filter-item">
            <Input style={{ maxWidth: 200 }} placeholder="全部"></Input>
          </Form.Item>

          <Form.Item label="申请时间" className="filter-item">
            <DatePicker.RangePicker style={{ minWidth: 200 }} showTime />
          </Form.Item>
        </Form>

        <div style={{ display: "flex" }}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            style={{ marginRight: 10 }}
            onClick={() => fetchData()}
          >
            搜索
          </Button>
          <Button icon={<RedoOutlined />}>重置</Button>
        </div>
      </div>
      <div style={{ backgroundColor: "#ffffff", padding: 16, marginTop: 16 }}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          loading={loading}
          renderItem={(item) => {
            return (
              <List.Item
                actions={[
                  <StatusTag status={item.status} />,
                  <a
                    key="view"
                    onClick={() =>
                      history.push({
                        pathname: `/workbench/business/repayment/apply-list/detail`,

                        search: `id=${item.repayment_id}&business_id=${item.product_apply_id}`,
                      })
                    }
                  >
                    查看详情
                  </a>,
                ]}
              >
                <List.Item.Meta
                  title={
                    <span style={{ color: "inherit" }}>
                      企业名称：
                      <span>
                        {item.company_name ? item.company_name : "未知"}
                      </span>
                    </span>
                  }
                  description={
                    <div style={{ display: "flex" }}>
                      <span>{`还款金额：${toThousands(item.amount)} 元`}</span>
                      <span
                        style={{ padding: "0 16px" }}
                      >{`申请时间：${item.create_time}`}</span>
                    </div>
                  }
                ></List.Item.Meta>
              </List.Item>
            );
          }}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
          }}
        ></List>
      </div>
    </div>
  );
};

export default RepaymentApplyList;
