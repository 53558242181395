import * as React from "react";
import {
  message,
  Table,
  Tag,
  Form,
  Input,
  DatePicker,
  Button,
  Select,
} from "antd";
import { getBusinessList } from "@/requests/business";
import { useHistory, useLocation } from "react-router";
import { toThousands } from "@/utils/string";
import { getProductList } from "@/requests/product";
import { SearchOutlined, RedoOutlined } from "@ant-design/icons";
import "../styles/index.less";
import moment, { Moment } from "moment";

const StatusTag = ({ status }: { status: number }) => {
  switch (status) {
    case 0:
      return <Tag color={"gold"}>审核中</Tag>;
    case 11:
      return <Tag color={"orange"}>放款中</Tag>;
    case 15:
      return <Tag color={"orange"}>放款中</Tag>;
    case 2:
      return <Tag color={"red"}>审核失败</Tag>;
    case 16:
      return <Tag color={"green"}>已放款</Tag>;
    case 17:
      return <Tag color={"gold"}>还款审核中</Tag>;
    case 22:
      return <Tag color={"magenta"}>已还款</Tag>;
    default:
      if (status > 17) {
        return <Tag color={"gold"}>还款审核中</Tag>;
      } else {
        return <Tag color={"gold"}>审核中</Tag>;
      }
  }
};
const BusinessList = () => {
  const [data, setData] = React.useState<BusinessListItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [productList, setProductList] = React.useState<ProductItem[]>([]);
  const [filterCompanyName, setFilterCompanyName] = React.useState("");
  const [filterStatus, setFilterStatus] = React.useState(-1);
  const [filterCreateTime, setFilterCreateTime] = React.useState<Moment | null>(
    null
  );
  const location = useLocation();
  const history = useHistory();

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.page;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    setLoading(true);
    const res = await getBusinessList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
      ...(filterStatus !== -1 && {
        status: filterStatus,
      }),
      ...(filterCompanyName && {
        company_name: filterCompanyName,
      }),
      ...(filterCreateTime && {
        create_time:
          moment(filterCreateTime).format("YYYY-MM-DD") + " " + "00:00:00",
      }),
    });
    if (res.code === 0) {
      setData(res.data);
      setPagination({
        page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };
  const fetchProductList = async () => {
    const res = await getProductList({
      limit: 100,
      skip: 0,
    });
    if (res.code === 0) {
      const _data = res.data.reverse().filter((x) => x.public_sign === 1);
      setProductList(_data);
    } else {
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (location.pathname === "/workbench/business/business-list") {
      fetchData();
      fetchProductList();
    }
  }, [location]);

  return (
    <div className="business-list-page">
      <div className="tools-wrapper">
        <Form colon={false} layout="inline">
          <Form.Item label="企业名称" className="filter-item">
            <Input
              style={{ maxWidth: 200 }}
              placeholder="全部"
              value={filterCompanyName}
              onChange={(e) => {
                setFilterCompanyName(e.target.value);
              }}
            ></Input>
          </Form.Item>

          <Form.Item label="状态" className="filter-item">
            <Select
              value={filterStatus}
              onChange={(value) => {
                setFilterStatus(value);
              }}
            >
              <Select.Option value={-1}>全部</Select.Option>
              <Select.Option value={1}>审核中</Select.Option>
              <Select.Option value={2}>审核失败</Select.Option>
              <Select.Option value={3}>放款中</Select.Option>
              <Select.Option value={4}>已放款</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="申请日期" className="filter-item">
            <DatePicker
              style={{ minWidth: 200 }}
              placeholder="全部"
              value={filterCreateTime}
              onChange={(value) => {
                setFilterCreateTime(value);
              }}
            />
          </Form.Item>
        </Form>

        <div style={{ display: "flex" }}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            style={{ marginRight: 10 }}
            onClick={() => fetchData()}
          >
            搜索
          </Button>
          <Button
            icon={<RedoOutlined />}
            onClick={() => {
              setFilterCompanyName("");
              setFilterCreateTime(null);
              setFilterStatus(0);
            }}
          >
            重置
          </Button>
        </div>
      </div>
      <Table
        dataSource={data}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => fetchData(page, pageSize),
          showTotal: (total) => <span>共 {total} 条数据</span>,
        }}
        loading={loading}
        style={{ marginTop: 16 }}
        scroll={{
          x: true,
        }}
        columns={[
          {
            title: "序号",
            key: "order",
            render: (value, record: BusinessListItem, index) => {
              return index + 1;
            },
            align: "center",
          },
          {
            title: "企业名称",
            key: "company_name",
            render: (value, record: BusinessListItem, index) => {
              return record.company_name;
            },
            align: "center",
          },
          {
            title: "金额（元）",
            key: "amount",
            render: (value, record: BusinessListItem, index) => {
              return toThousands(record.quota);
            },
            align: "center",
          },
          {
            title: "融资期限（天）",
            key: "quota_deadline",
            render: (value, record: BusinessListItem, index) => {
              return record.quota_deadline;
            },
            align: "center",
          },
          {
            title: "到期日",
            key: "deadline_date",
            render: (value, record: BusinessListItem, index) => {
              let date = new Date(record.create_time);
              date.setDate(date.getDate() + record.quota_deadline - 1);
              return moment(date).format("YYYY-MM-DD");
            },
            align: "center",
          },
          {
            title: "应收账款债务人",
            key: "debtor",
            render: (value, record: BusinessListItem, index) => {
              return record.debtor;
            },
            align: "center",
          },
          {
            title: "状态",
            key: "status",
            render: (value, record: BusinessListItem, index) => {
              return <StatusTag status={record.status} />;
            },
            align: "center",
          },
          {
            title: "申请时间",
            key: "create_time",
            render: (value, record: BusinessListItem, index) => {
              return record.create_time;
            },
            align: "center",
          },
          {
            title: "操作",
            key: "actions",
            render: (value, record: BusinessListItem, index) => {
              const product = productList.find(
                (x) => x.id === record.product_id
              );
              return (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({
                      pathname: "/workbench/business/business-list/detail",
                      state: {
                        record: record,
                        product: product,
                      },
                      search: `id=${record.id}`,
                    });
                  }}
                >
                  查看详情
                </a>
              );
            },
            align: "center",
          },
        ]}
      ></Table>
    </div>
  );
};

export default BusinessList;
