import * as React from "react";
import { Button, Modal, Form, message, InputNumber } from "antd";
import PicWalL from "@/ui-components/PicWall";
import { billing } from "@/requests/loan";

interface PropsType {
  visible: boolean;
  setVisible: Function;
  fetchData: Function;
  data: BillingListItem;
}

const BillingModal: React.FC<PropsType> = (props: PropsType) => {
  const { visible, setVisible, fetchData, data } = props;

  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();

  const handleCancel = () => {
    setVisible(false);
  };

  const doBilling = async (input: BillingReq) => {
    setLoading(true);
    const res = await billing(input);
    if (res.code === 0) {
      message.success("提交成功");
      fetchData();
      setVisible(false);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const handleOK = (values: any) => {
    doBilling({
      ...values,
      billing_img: values["billing_img"]
        .map((x: any) => x.response.data)
        .toString(),
      id: data.id,
    });
    console.log(values);
  };

  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>放款制单
        </span>
      }
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      maskClosable={false}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            justifyContent: "center",
          }}
        >
          <Form form={form} preserve={false} onFinish={handleOK}>
            <Form.Item label="制单金额" required>
              <Form.Item
                noStyle
                name="billing_amount"
                rules={[{ required: true, message: "请输入制单金额" }]}
                initialValue={data.billing_amount}
              >
                <InputNumber style={{ width: 200 }} />
              </Form.Item>
              <span style={{ paddingLeft: 4 }}>元</span>
            </Form.Item>

            <Form.Item
              label="制单截图"
              name="billing_img"
              rules={[{ required: true, message: "请上传制单截图" }]}
            >
              <PicWalL action="/api/file/upload" />
            </Form.Item>

            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 36,
                  padding: "0 100px",
                }}
              >
                <Button
                  style={{ padding: "0 36px" }}
                  loading={loading}
                  onClick={handleCancel}
                >
                  取消
                </Button>
                <Button
                  type="primary"
                  htmlType={"submit"}
                  style={{ padding: "0 36px" }}
                >
                  完成
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default BillingModal;
