/// <reference path="type.d.ts" />
/// <reference path="log.d.ts" />

import request from "./_base";

/**
 * 获取授信申请列表
 * @param  {BaseListReq} input
 */
export async function getLogList(
  input: BaseListReq
): Promise<ResponseProto<{ data: CreditOperationLogItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/operation/log/credit_log/list`,
    data: input,
  }) as any;
}
