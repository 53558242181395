import * as React from "react";
import { Descriptions, Image, Divider, Table } from "antd";
import ShareholderDisplayer from "@/pages/CompanyManagement/components/ShareholderDisplayer";
import FileViewer from "@/ui-components/FileViewer";
import ContractPreviewer from "@/ui-components/ContractPreviewer";
interface PropsType {
  data: CompanyInfoItem;
}

const taxColumns = (type: number) => {
  enum Type {
    "纳税记录" = 1,
    "财务报表",
    "审计报告",
    "工资发放记录",
  }
  return [
    {
      title: "分类",
      key: "order",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) => {
        const obj = {
          children: Type[type],
          props: {} as any,
        };
        if (index === 0) {
          obj.props.rowSpan = 99;
        } else {
          obj.props.rowSpan = 0;
        }

        return obj;
      },
    },
    {
      title: "序号",
      width: 80,
      key: "order",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) =>
        `${index + 1}`,
    },
    {
      title: "时间",
      key: "time",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) => {
        return record.time;
      },
    },
    {
      title: "文件",
      key: "files",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) => {
        return record.files.map((x) => <FileViewer file={x} key={x} />);
      },
    },
  ];
};

const CompanyInfoDisplayer = ({ data }: PropsType) => {
  if (!data) return null;
  return (
    <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
      <div>
        <Divider>企业信息</Divider>
        <Descriptions title="" bordered>
          <Descriptions.Item label="企业名称">
            {data.company_name}
          </Descriptions.Item>
          <Descriptions.Item label="企业电话">
            {data.company_phone}
          </Descriptions.Item>
          <Descriptions.Item label="行业类型">
            {data.industry_type === " " ? "无" : data.industry_type}
          </Descriptions.Item>

          <Descriptions.Item label="注册地址" span={3}>
            {data.register_address}
          </Descriptions.Item>
          <Descriptions.Item label="联系地址" span={3}>
            {data.contact_address}
          </Descriptions.Item>
          <Descriptions.Item label="绑定时间">
            {data.create_time}
          </Descriptions.Item>

          <Descriptions.Item label="社会统一信用代码">
            {data.uniform_social_credit_code}
          </Descriptions.Item>
          <Descriptions.Item label="营业执照" span={2}>
            <FileViewer file={data.business_license_pic} />
          </Descriptions.Item>
          <Descriptions.Item label="企业征信">
            <FileViewer file={data.company_credit} />
          </Descriptions.Item>
          <Descriptions.Item label="企业简介">
            <FileViewer file={data.company_profile} />
          </Descriptions.Item>
          <Descriptions.Item label="企业章程">
            <FileViewer file={data.charter} />
          </Descriptions.Item>
          <Descriptions.Item label="开户许可证">
            <FileViewer file={data.opening_permit} />
          </Descriptions.Item>
        </Descriptions>
        <Divider>法人信息</Divider>
        <Descriptions title="" bordered>
          <Descriptions.Item label="姓名">
            {data.legal_person}
          </Descriptions.Item>
          <Descriptions.Item label="职位">
            {data.legal_person_position}
          </Descriptions.Item>
          <Descriptions.Item label="联系电话">
            {data.legal_person_phone}
          </Descriptions.Item>
          <Descriptions.Item label="电子邮箱">
            {data.legal_person_email}
          </Descriptions.Item>

          <Descriptions.Item label="联系地址" span={3}>
            {data.legal_person_address}
          </Descriptions.Item>
          <Descriptions.Item label="身份证号">
            {data.legal_person_id_card_num}
          </Descriptions.Item>

          <Descriptions.Item label="身份证信息">
            {data.legal_person_id_card_pic.map((x, index) => {
              return x ? (
                <FileViewer file={x.file_name} key={x.file_name} />
              ) : null;
            })}
          </Descriptions.Item>
          <Descriptions.Item label="法人征信" span={2}>
            <FileViewer file={data.legal_credit} />
          </Descriptions.Item>
        </Descriptions>
        <Divider>经办人信息</Divider>
        <Descriptions title="" bordered>
          <Descriptions.Item label="姓名">{data.agent_name}</Descriptions.Item>
          <Descriptions.Item label="职位">
            {data.agent_position}
          </Descriptions.Item>
          <Descriptions.Item label="联系电话">
            {data.agent_phone}
          </Descriptions.Item>
          <Descriptions.Item label="电子邮箱">
            {data.agent_email}
          </Descriptions.Item>

          <Descriptions.Item label="联系地址" span={3}>
            {data.agent_address}
          </Descriptions.Item>
          <Descriptions.Item label="身份证号">
            {data.agent_id_card_num}
          </Descriptions.Item>
          <Descriptions.Item label="身份证信息">
            {data.agent_id_card_pic.map((x, index) => {
              return x ? (
                <FileViewer file={x.file_name} key={x.file_name} />
              ) : null;
            })}
          </Descriptions.Item>
          <Descriptions.Item label="法人授权书">
            <ContractPreviewer contract_id={data.legal_person_auth_pic} />
          </Descriptions.Item>
        </Descriptions>
        <Divider>财税信息</Divider>
        {data.tax_record && (
          <Table
            dataSource={data.tax_record}
            bordered
            columns={taxColumns(1)}
            size="small"
          />
        )}
        {data.financial_statement && (
          <Table
            dataSource={data.financial_statement}
            bordered
            columns={taxColumns(2)}
            size="small"
          />
        )}
        {data.audit_report && (
          <Table
            dataSource={data.audit_report}
            bordered
            columns={taxColumns(3)}
            size="small"
          />
        )}
        {data.payroll_record && (
          <Table
            dataSource={data.payroll_record}
            bordered
            columns={taxColumns(4)}
            size="small"
          />
        )}
        <Divider>股东信息</Divider>
        {data.shareholder_message.map((x) => (
          <ShareholderDisplayer item={x} key={x.shareholding_ratio} />
        ))}
      </div>
    </div>
  );
};

export default CompanyInfoDisplayer;
