/**
 * 转换货币格式
 * @param  {number} num
 */
// export function toThousands(num) {
//   return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
// }

export function toThousands(num) {
  num = num.toString().replace(/[^\d\.-]/g,''); //转成字符串并去掉其中除数字, . 和 - 之外的其它字符。
  
  if(isNaN(num)) num = "0"; //是否非数字值
  
  var sign = (num == (num = Math.abs(num)));
  
  num = Math.floor(num * 100 + 0.50000000001); //下舍入
  
  var cents = num % 100; //求余 余数 = 被除数 - 除数 * 商
  
  cents = (cents < 10) ? "0" + cents : cents; //小于2位数就补齐
  
  num = Math.floor(num / 100).toString();
  
  for(var i = 0; i < Math.floor( (num.length - (1+i)) / 3 ); i++ ){ //每隔三位小数分始开隔
  
  num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
  
  }
  
  return (((sign) ? '' : '-' ) + num + '.' + cents);
  
  }