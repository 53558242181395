import * as React from "react";

import {
  message,
  Modal,
  Button,
  Form,
  Input,
  Select,
  Empty,
  Divider,
  Table,
} from "antd";
import * as authRequests from "@/requests/auth";
import "../styles/index.less";
import CreateAuthModal from "../components/CreateAuthModal";
import UpdateAuthModal from "../components/UpdateAuthModal";
import { formItemLayout } from "../configs";
import { PlusOutlined } from "@ant-design/icons";

const AuthorityManage = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<SysMenuItem[] | undefined>(undefined);
  const [createAuthModalVisible, setCreateAuthModalVisible] =
    React.useState(false);

  const [parentId, setParentId] = React.useState<number | null>(0);

  const [viewingAuth, setViewingAuth] = React.useState<SysMenuItem | undefined>(
    undefined
  );
  const [updateAuthModalVisible, setUpdateAuthModalVisible] =
    React.useState(false);
  const [editingAuth, setEditingAuth] = React.useState<SysMenuItem | undefined>(
    undefined
  );
  const [filterName, setFilterName] = React.useState("");
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) page = 1;
    if (!pageSize) pageSize = 10;
    const res = await authRequests.getSysMenuList({
      limit: pageSize,
      skip: pageSize * (page - 1),
    });
    if (res.code === 0) {
      if (res.data.length > 0) {
        setData(res.data);
        setPagination({
          ...pagination,
          page,
          pageSize,
          total: res.total,
        });
      }
    } else {
      message.error(res.message);
    }
    setLoading(false);
    return res.data;
  };

  React.useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const colunms = [
    {
      title: "序号",
      dataIndex: "order",
      key: "order",
      align: "center" as "left",
      render: (text: any, record: SysMenuItem, index: number) => `${index + 1}`,
    },
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
      align: "left" as "left",
      render: (text: any, record: SysMenuItem, index: number) =>
        `${record.name}`,
    },
    {
      title: "描述",
      dataIndex: "description",
      key: "description",
      align: "left" as "left",
      render: (text: any, record: SysMenuItem, index: number) =>
        `${record.comment ? record.comment : "-"}`,
    },
    {
      title: "url",
      dataIndex: "url",
      key: "url",
      align: "left" as "left",
      render: (text: any, record: SysMenuItem, index: number) =>
        `${record.url}`,
    },

    {
      title: "操作",
      key: "actions",
      align: "center" as "left",
      render: (text: any, record: SysMenuItem, index: number) => {
        return (
          <span>
            <a
              key="view"
              onClick={() => {
                setViewingAuth(record);
              }}
            >
              查看
            </a>

            <Divider type="vertical" />

            <a
              key="edit"
              onClick={() => {
                setEditingAuth(record);
                setUpdateAuthModalVisible(true);
              }}
            >
              编辑
            </a>

            <Divider type="vertical" />

            <a
              key="delete"
              onClick={(e) => {
                e.preventDefault();
                Modal.confirm({
                  title: "提示",
                  content: "将删除该权限以及其所有子节点权限，确认删除吗？",
                  okText: "确认",
                  onOk: async () => {
                    const { id } = record;
                    const res = await authRequests.deleteSysMenu({
                      id,
                    });
                    if (res.code === 0) {
                      message.success("删除成功！");
                      fetchData();
                    } else {
                      message.error(res.message);
                    }
                  },
                  cancelText: "取消",
                  onCancel: () => {},
                });
              }}
            >
              删除权限
            </a>
          </span>
        );
      },
    },
  ];

  const AuthInfoModal = ({
    authItem,
    setEditingAuth,
  }: {
    authItem: SysMenuItem | undefined;
    setEditingAuth: Function;
  }) => {
    if (!authItem) return null;
    const { name, id, comment, create_time, request_type, url, menu_type } =
      authItem;
    return (
      <Modal
        title={
          <span>
            <div className="title-prefix"></div>权限详情
          </span>
        }
        okText="确认"
        cancelText={null}
        visible={!!authItem}
        onOk={() => setEditingAuth(undefined)}
        onCancel={() => setEditingAuth(undefined)}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <Form {...formItemLayout}>
          <Form.Item label="权限名称">
            <Input value={name} disabled />
          </Form.Item>
          <Form.Item label="id">
            <Input value={id} disabled />
          </Form.Item>

          <Form.Item label="描述">
            <Input.TextArea value={comment} disabled />
          </Form.Item>
          <Form.Item label="请求类型">
            <Select value={request_type} disabled />
          </Form.Item>
          <Form.Item label="url">
            <Input value={url} disabled />
          </Form.Item>
          <Form.Item label="权限类型">
            <Input value={menu_type} disabled />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return (
    <div className="auth-manage-page">
      <div className="tools-wrapper">
        <div className="filter-item">
          <Input.Search
            onSearch={() => fetchData()}
            style={{ maxWidth: 200 }}
            placeholder="搜索权限"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          ></Input.Search>
        </div>

        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setCreateAuthModalVisible(true)}
        >
          创建权限
        </Button>
      </div>
      <div style={{ backgroundColor: "#ffffff" }}>
        <Table
          dataSource={data}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
            style: { marginRight: 16 },
          }}
          style={{ height: "100%" }}
          columns={colunms as any}
          expandIcon={() => null}
          rowKey={(record) => record.id.toString()}
          loading={loading}
          locale={{
            emptyText: (
              <Empty description="还未创建任何权限，点击创建根权限">
                <Button
                  type="primary"
                  onClick={() => setCreateAuthModalVisible(true)}
                  disabled={loading}
                >
                  创建权限
                </Button>
              </Empty>
            ),
          }}
        ></Table>
      </div>

      <CreateAuthModal
        visible={createAuthModalVisible}
        setVisible={setCreateAuthModalVisible}
        fetchData={fetchData}
        parent_id={parentId}
      />
      <AuthInfoModal authItem={viewingAuth} setEditingAuth={setViewingAuth} />
      {updateAuthModalVisible && (
        <UpdateAuthModal
          visible={updateAuthModalVisible}
          setVisible={setUpdateAuthModalVisible}
          fetchData={fetchData}
          authItem={editingAuth}
        />
      )}
    </div>
  );
};

export default AuthorityManage;
