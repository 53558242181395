import * as React from "react";
import { useHistory, useLocation } from "react-router";
import {} from "@/requests/credit";
import {
  message,
  Result,
  Button,
  Form,
  Radio,
  Spin,
  Upload,
  InputNumber,
  Divider,
  Descriptions,
} from "antd";
import "./index.less";
import { UploadOutlined } from "@ant-design/icons";
import PicWall from "@/ui-components/PicWall";
import {} from "@/requests/credit";
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};

const UploadButton = ({ disabled = false }) => (
  <Button icon={<UploadOutlined />} disabled={disabled}>
    点击上传
  </Button>
);

const SubmitCredit = () => {
  const location = useLocation();
  const history = useHistory();
  const [apply, setApply] = React.useState<CreditApplyItem | undefined>(
    undefined
  );
  const [loading, setLoading] = React.useState(false);
  const [fetching, setFeching] = React.useState(false);
  const [form] = Form.useForm();
  const [done, setDone] = React.useState(false);

  const fetchData = async (id: CreditApplyItem["id"]) => {
    // const res = await getCreditApplyById(id);
    // if (res.code === 0) {
    //   setApply(res.data);
    // } else {
    //   message.error("网络错误");
    // }
  };

  React.useEffect(() => {
    setLoading(true);
    if (location.state) {
      const { data } = location.state as any;
      setApply(data);
    } else {
      if (location.search) {
        fetchData(Number(location.search.split("=")[1]));
      } else {
        message.error("未知错误");
        history.goBack();
      }
    }
    setLoading(false);
  }, []);

  const doSubmit = async (value: any) => {
    // setFeching(true);
    // const res = await submitCredit(value);
    // if (res.code === 0) {
    //   message.success("提交成功");
    //   setDone(true);
    // } else {
    //   message.error(res.message);
    // }
    // setFeching(false);
  };

  const handleFinish = (values: any) => {
    if (!apply) return;
    doSubmit({
      quota_application_id: apply.id,
      litigation_illustration: values.litigation_illustration
        ? values.litigation_illustration.file.response.data
        : "",
      litigation_result: values.litigation_result,
      query_picture: values.query_picture
        .map((x: any) => x.response.data)
        .toString(),
      submit_credit_line: values.submit_credit_line,
      report: values.report.file.response.data,
    });
  };
  return (
    <div className="submit-credit-page">
      {done ? (
        <Result
          status="success"
          title="提交成功"
          subTitle="请等待审批人员审核"
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => history.push("/")}
            >
              返回
            </Button>,
          ]}
        />
      ) : (
        <>
          {!!!apply ? (
            <Spin spinning={loading || !apply} />
          ) : (
            <div className="content">
              <Divider>基本信息</Divider>
              <Descriptions
                title=""
                column={4}
                bordered
                style={{ width: "100%" }}
              >
                <Descriptions.Item label="申请人">
                  {apply.company_name}
                </Descriptions.Item>
                <Descriptions.Item label="申请时间">
                  {apply.create_time}
                </Descriptions.Item>

                <Descriptions.Item label="申请额度">
                  {apply.credit_line} 万元
                </Descriptions.Item>
                <Descriptions.Item label="申请期限">
                  {apply.credit_term} 月
                </Descriptions.Item>
              </Descriptions>
              <Divider>授信提交</Divider>
              <Form
                style={{ width: 500 }}
                labelAlign="right"
                {...layout}
                form={form}
                onFinish={handleFinish}
              >
                <Form.Item
                  label="涉诉查询结果"
                  name="litigation_result"
                  rules={[{ required: true, message: "请选择涉诉查询结果" }]}
                >
                  <Radio.Group>
                    <Radio value={0}>无涉诉</Radio>
                    <Radio value={1}>有涉诉</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label="涉诉查询截图"
                  name="query_picture"
                  rules={[{ required: true, message: "请上传涉诉查询截图" }]}
                  extra={
                    <span>
                      涉诉查询截图
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          // setPersonAVisible(true);
                        }}
                      >
                        示例
                      </a>
                    </span>
                  }
                  valuePropName="value"
                >
                  <PicWall
                    action={"/api/file/credit_submit/upload"}
                    data={{ type: 3 }}
                  />
                </Form.Item>

                <Form.Item
                  label="涉诉情况说明"
                  name="litigation_illustration"
                  extra="若涉诉查询结果未有涉诉，则必须上传"
                >
                  <Upload
                    action={"/api/file/credit_submit/upload"}
                    onChange={({ file, fileList }) => {
                      if (file.status === "success") {
                        console.log(file.response);
                      }
                    }}
                    data={{ type: 1 }}
                    onRemove={() => {
                      // form.setFieldsValue({ business_license_pic: undefined });
                    }}
                  >
                    <UploadButton />
                  </Upload>
                </Form.Item>
                <Form.Item
                  label="现场尽调报告"
                  name="report"
                  rules={[{ required: true, message: "请上传现场尽调报告" }]}
                >
                  <Upload
                    action={"/api/file/credit_submit/upload"}
                    onChange={({ file, fileList }) => {
                      if (file.status === "success") {
                        console.log(file.response);
                      }
                    }}
                    data={{ type: 1 }}
                    onRemove={() => {
                      // form.setFieldsValue({ business_license_pic: undefined });
                    }}
                  >
                    <UploadButton />
                  </Upload>
                </Form.Item>
                <Form.Item
                  label="提交额度"
                  rules={[{ required: true, message: "请填写提交额度" }]}
                >
                  <Form.Item
                    name="submit_credit_line"
                    noStyle
                    rules={[{ required: true, message: "请输入提交额度" }]}
                  >
                    <InputNumber style={{ marginRight: 10 }} />
                  </Form.Item>
                  <span>万元</span>
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit" loading={fetching}>
                    {fetching ? "正在提交" : "提交"}
                  </Button>
                </Form.Item>
              </Form>
              <Divider>说明</Divider>
              <div style={{ margin: "0 48px" }}>
                <h4
                  style={{
                    color: "rgba(0,0,0,.45)",
                    fontSize: 15,
                    lineHeight: "22px",
                  }}
                >
                  关于授信提交
                </h4>
                <p style={{ lineHeight: "22px", color: "rgba(0,0,0,.45)" }}>
                  1.请认真查看客户提交各项资料，无公章的资料不具有法律效应，将会影响授信审核结果。
                </p>
                <p style={{ lineHeight: "22px", color: "rgba(0,0,0,.45)" }}>
                  2.诉讼情况说明文件支持格式为图片或pdf文件；尽调报告文件支持格式为pdf、docx、doc；查询截图只支持图片格式。
                </p>
                <p style={{ lineHeight: "22px", color: "rgba(0,0,0,.45)" }}>
                  3.上传文件大小不得超过10M。
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SubmitCredit;
