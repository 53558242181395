/// <reference path="type.d.ts" />
/// <reference path="company.d.ts" />

import request from "./_base";
export const DOWNLOAD_ACTION = `/api/file/download/`;
export const UPLOAD_ACTION = `/api/file/upload`;

/**
 * 获取公司列表
 * @param  {GetCompanyListReq} input
 */
export async function getCompanyList(
  input: GetCompanyListReq
): Promise<ResponseProto<{ data: CompanyInfoItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/company_info/list`,
    data: input,
  }) as any;
}

/**
 * 业务人员审核
 * @param  {CompanyApprovalReqBase} input
 */
export async function CompanySalesmanApproval(
  input: CompanyApprovalReqBase
): Promise<ResponseProto<{ data: null }>> {
  return request({
    method: "POST",
    url: `/company_info/approval/salesman/approval`,
    data: input,
  }) as any;
}

/**
 * 业务主管审核
 * @param  {CompanyApprovalReqBase} input
 */
export async function CompanyManagerApproval(
  input: CompanyApprovalReqBase
): Promise<ResponseProto<{ data: null }>> {
  return request({
    method: "POST",
    url: `/company_info/approval/business_supervisor/approval`,
    data: input,
  }) as any;
}
/**
 * 财务人员审核
 * @param  {CompanyApprovalReqBase} input
 */
export async function taxApproval(
  input: CompanyApprovalReqBase
): Promise<ResponseProto<{ data: null }>> {
  return request({
    method: "POST",
    url: `/company_info/approval/taxation_staff/approval`,
    data: input,
  }) as any;
}
/**
 * 获取基础资料审批操作历史
 * @param  {GetCompanyApprovalReacordReq} input
 */
export async function getCompanyApprovalReacord(
  input: GetCompanyApprovalReacordReq
): Promise<ResponseProto<{ data: CompanyApprovalLogItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/operation/log/company-info`,
    data: input,
  }) as any;
}

/**
 * 查询单条公司信息
 * @param  {number}id
 */
export async function getCompanyDetail(
  id: number
): Promise<ResponseProto<{ data: CompanyInfoItem }>> {
  return request({
    method: "GET",
    url: `/company_info/${id}`,
  }) as any;
}

/**
 * 分页查询用户端提交的基础资料提交申请列表
 * @param  {GetCompanyListReq} input
 */
export async function getCompanyApplyList(
  input: GetCompanyListReq
): Promise<ResponseProto<{ data: CompanyProfileApplyItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/company_info/approval/candidate/list`,
    data: input,
  }) as any;
}

/**
 * 查询单条申请详情
 * @param  {string} id
 */
export async function getCompanyApprovalDetail(
  id: number
): Promise<ResponseProto<{ data: CompanyProfileApplyItem }>> {
  return request({
    method: "GET",
    url: `/company_info/approval/${id}`,
  }) as any;
}

/**
 * 导出企业清单
 */
export async function exportCompanyList(): Promise<
  ResponseProto<{ data: any }>
> {
  return request({
    method: "GET",
    url: `/company_info/list/export`,
  }) as any;
}
