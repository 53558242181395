import * as React from "react";

import {
  message,
  Modal,
  Button,
  Form,
  Input,
  Divider,
  Table,
  Empty,
} from "antd";
import * as authRequests from "@/requests/auth";
import UpdateRoleAuthesModal from "../components/UpdateRoleAuthModal";
import { PlusOutlined } from "@ant-design/icons";
import "../styles/index.less";

interface BaseRoleFormValues {
  name: string;
  parent_id: number;
  description: string;
}
interface UpdateRoleFormValues {
  name: string;
  description: string;
}
const RoleInfoModal = ({
  role,
  setEditingRole,
}: {
  role: RoleItem | undefined;
  setEditingRole: Function;
}) => {
  if (!role) return null;
  const { name, id, description, create_time } = role;
  return (
    <Modal
      title="角色信息"
      okText="确认"
      cancelText={null}
      visible={!!role}
      onOk={() => setEditingRole(undefined)}
      onCancel={() => setEditingRole(undefined)}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Form layout="vertical">
        <Form.Item label="角色名">
          <Input value={name} disabled />
        </Form.Item>
        <Form.Item label="角色id">
          <Input value={id} disabled />
        </Form.Item>
        <Form.Item label="描述">
          <Input.TextArea value={description} disabled />
        </Form.Item>
        <Form.Item label="创建时间">
          <Input value={create_time} disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const RoleManagement = () => {
  const [data, setData] = React.useState<RoleItem[]>([]);
  const [fetching, setFetching] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });

  const [createRoleModalVisible, setCreateRoleVisible] = React.useState(false);

  const [filterRole, setFilterRole] = React.useState("");
  const [editingRole, setEditingRole] = React.useState<RoleItem | undefined>(
    undefined
  );
  const [viewingRole, setViewingRole] = React.useState<RoleItem | undefined>(
    undefined
  );

  const [updateRoleModalVisible, setUpdateRoleVisible] = React.useState(false);

  const [createRoleForm] = Form.useForm();
  const handleCreateRoleModalOK = async (values: BaseRoleFormValues) => {
    doCreateRole(values);
  };
  const handleCreateRoleCancel = () => {
    setCreateRoleVisible(false);
  };

  const [updateRoleForm] = Form.useForm();
  const handleUpdateRoleModalOK = (values: UpdateRoleFormValues) => {
    doUpdateRole(values);
  };
  const handleUpdateRoleCancel = () => {
    setUpdateRoleVisible(false);
  };

  const [updateRoleAuthesVisible, setUpdateRoleAuthesVisible] =
    React.useState(false);
  const [grantingRole, setGrantingRole] = React.useState<RoleItem | undefined>(
    undefined
  );

  const fetchData = async (page?: number, pageSize?: number) => {
    setLoading(true);

    if (!page) page = 1;
    if (!pageSize) pageSize = 10;
    const res = await authRequests.getRoleList({
      limit: pageSize,
      skip: pageSize * (page - 1),
      // name: filterRole,
    });
    if (res.code === 0) {
      setPagination({
        ...pagination,
        total: res.total,
      });
      setData(res.data);
    } else {
      setData([]);
      message.error(res.message);
    }
    setLoading(false);
  };
  //创建角色function
  const doCreateRole = async (values: BaseRoleFormValues) => {
    setFetching(true);
    const res = await authRequests.createRole({
      name: values["name"],
      description: values["description"],
    });
    if (res.code === 0) {
      message.success("创建成功");
      fetchData();

      setCreateRoleVisible(false);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  //更新角色function
  const doUpdateRole = async (values: UpdateRoleFormValues) => {
    if (!editingRole) return;
    if (
      values["name"] === editingRole.name &&
      values["description"] === editingRole.description
    ) {
      setUpdateRoleVisible(false);
      return;
    }
    setFetching(true);
    const res = await authRequests.updateRole({
      name: values["name"],
      description: values["description"],
      id: editingRole.id,
    });
    if (res.code === 0) {
      message.success("更新成功");
      fetchData();
      setUpdateRoleVisible(false);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  React.useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const CreateRoleForm = () => {
    return (
      <Form
        form={createRoleForm}
        preserve={false}
        layout="vertical"
        onFinish={handleCreateRoleModalOK}
      >
        <Form.Item
          label="角色名"
          name="name"
          rules={[
            {
              required: true,
              message: "请输入角色名称！",
            },
          ]}
        >
          <Input placeholder="请输入角色名称" />
        </Form.Item>

        <Form.Item
          label="角色描述"
          name="description"
          rules={[{ required: true, message: "请输入角色描述！" }]}
        >
          <Input.TextArea placeholder="请输角色描述" />
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 36,
              padding: "0 100px",
            }}
          >
            <Button
              type="primary"
              htmlType={"submit"}
              style={{ padding: "0 36px" }}
              loading={fetching}
            >
              确认
            </Button>
            <Button
              onClick={handleCreateRoleCancel}
              style={{ padding: "0 36px" }}
            >
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };

  const UpdateRoleForm = () => {
    if (!editingRole) return null;
    return (
      <Form
        form={updateRoleForm}
        preserve={false}
        layout="vertical"
        onFinish={handleUpdateRoleModalOK}
      >
        <Form.Item
          label="角色名"
          name="name"
          rules={[
            {
              required: true,
              message: "请输入角色名称！",
            },
          ]}
          initialValue={editingRole.name}
        >
          <Input placeholder="请输入角色名称" />
        </Form.Item>

        <Form.Item
          label="角色描述"
          name="description"
          rules={[{ required: true, message: "请输入角色描述！" }]}
          initialValue={editingRole.description}
        >
          <Input.TextArea placeholder="请输角色描述" />
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 36,
              padding: "0 100px",
            }}
          >
            <Button
              type="primary"
              htmlType={"submit"}
              style={{ padding: "0 36px" }}
              loading={fetching}
            >
              确认
            </Button>
            <Button
              onClick={handleUpdateRoleCancel}
              style={{ padding: "0 36px" }}
            >
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };

  const colunms = [
    {
      title: "序号",
      dataIndex: "order",
      key: "order",
      align: "center" as "left",
      render: (text: any, record: RoleItem, index: number) => `${index + 1}`,
    },
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
      align: "left" as "left",
      render: (text: any, record: RoleItem, index: number) => `${record.name}`,
    },
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      align: "left" as "left",
      render: (text: any, record: RoleItem, index: number) => `${record.id}`,
    },
    {
      title: "描述",
      dataIndex: "description",
      key: "description",
      align: "left" as "left",
      render: (text: any, record: RoleItem, index: number) =>
        `${record.description ? record.description : "-"}`,
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
      key: "create_time",
      align: "left" as "left",
      render: (text: any, record: RoleItem, index: number) =>
        `${record.create_time}`,
    },
    {
      title: "操作",
      key: "actions",
      align: "center" as "left",
      render: (text: any, record: RoleItem, index: number) => {
        return (
          <span>
            <a
              key="view"
              onClick={(e) => {
                e.preventDefault();
                setViewingRole(record);
              }}
            >
              查看
            </a>

            <Divider type="vertical" />

            <a
              key="edit"
              onClick={(e) => {
                setEditingRole(record);
                setUpdateRoleVisible(true);
              }}
            >
              编辑
            </a>

            <Divider type="vertical" />
            <a
              key="setting"
              onClick={(e) => {
                e.preventDefault();
                setGrantingRole(record);
                setUpdateRoleAuthesVisible(true);
              }}
            >
              权限设置
            </a>

            <Divider type="vertical" />

            <a
              key="delete"
              onClick={(e) => {
                e.preventDefault();
                Modal.confirm({
                  title: "提示",
                  content: "确认删除该角色吗？",
                  okText: "确认",
                  onOk: async () => {
                    const { id } = record;
                    const res = await authRequests.deleteRole({ id });
                    if (res.code === 0) {
                      message.success("删除成功！");
                      fetchData();
                    } else {
                      message.error(res.message);
                    }
                  },
                  cancelText: "取消",
                  onCancel: () => {},
                });
              }}
            >
              删除角色
            </a>
          </span>
        );
      },
    },
  ];

  return (
    <div className="role-manage-page">
      <div className="tools-wrapper">
        <div className="filter-item">
          <Input.Search
            onSearch={() => fetchData()}
            style={{ maxWidth: 200 }}
            placeholder="搜索角色"
            value={filterRole}
            onChange={(e) => setFilterRole(e.target.value)}
          ></Input.Search>
        </div>

        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setCreateRoleVisible(true)}
        >
          创建角色
        </Button>
      </div>
      <div style={{ backgroundColor: "#ffffff" }}>
        <Table
          dataSource={data}
          columns={colunms}
          pagination={{
            ...pagination,
            onChange: (page, pagesize) => {
              setPagination({ ...pagination, page: page });
              fetchData(page, pagesize);
            },
            showTotal: (total: number) => {
              return <span>{`共 ${total} 条数据`}</span>;
            },
            showQuickJumper: true,
            style: { marginRight: 16 },
          }}
          locale={{
            emptyText: (
              <Empty description="暂无角色，点击添加角色，创建根角色">
                <Button
                  type="primary"
                  onClick={() => setCreateRoleVisible(true)}
                  icon={<PlusOutlined />}
                >
                  创建角色
                </Button>
              </Empty>
            ),
          }}
          loading={loading}
          rowKey={(record) => record.id.toString()}
        ></Table>
      </div>
      {viewingRole && (
        <RoleInfoModal role={viewingRole} setEditingRole={setViewingRole} />
      )}
      <Modal
        visible={createRoleModalVisible}
        title={
          <span>
            <div className="title-prefix"></div>创建角色
          </span>
        }
        onCancel={handleCreateRoleCancel}
        footer={null}
        destroyOnClose
      >
        <CreateRoleForm />
      </Modal>
      <Modal
        visible={updateRoleModalVisible}
        title={
          <span>
            <div className="title-prefix"></div>编辑角色
          </span>
        }
        footer={null}
        onCancel={handleUpdateRoleCancel}
      >
        <UpdateRoleForm />
      </Modal>
      {updateRoleAuthesVisible && (
        <UpdateRoleAuthesModal
          visible={updateRoleAuthesVisible}
          setVisible={setUpdateRoleAuthesVisible}
          role={grantingRole}
          fetchData={fetchData}
        />
      )}
    </div>
  );
};

export default RoleManagement;
