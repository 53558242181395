import * as React from "react";
import { useLocation } from "react-router";
import {
  Steps,
  Result,
  Descriptions,
  Image,
  Divider,
  Button,
  Modal,
  Form,
  Radio,
  message,
  Input,
  List,
  Avatar,
  Table,
} from "antd";
import {
  CompanyManagerApproval,
  CompanySalesmanApproval,
  getCompanyApprovalReacord,
  getCompanyApprovalDetail,
  taxApproval,
} from "@/requests/company";
import ShareholderDisplayer from "../components/ShareholderDisplayer";
import FileViewer from "@/ui-components/FileViewer";
enum applyResDes {
  "等待审核中，请尽快完成基础资料审核" = 0,
  "业务人员审核已完成,请结合业务人员审核结果，提交复审意见" = 1,
  "业务员审核失败，申请已关闭" = 2,
  "业务主管审核通过，审批完成" = 3,
  "业务主管审核不通过，申请已关闭" = 4,
  "财务人员审核通过，审批完成" = 5,
  "财务人员审核不通过，申请已关闭" = 6,
}

enum applyRes {
  "待审核" = 0,
  "审核中" = 1,
  "待复审" = 2,
  "审核完成" = 3,
}
enum taxapplyRes {
  "待审核" = 0,
  "审核中" = 1,
  "审核完成" = 2,
}

const applyType = {
  COMPANY_INFO_APPROVAL: "submit_company_info_approval",
  SUBMIT_LEGAL_PERSON_APPROVAL: "submit_legal_person_approval",
  SUBMIT_AGENT_INFO_APPROVAL: "submit_agent_info_approval",
  SUBMIT_SHAREHOLDER_INFO_APPROVAL: "submit_shareholder_info_approval",
  SUBMIT_CHARTER_INFO_APPROVAL: "submit_charter_info_approval",
  SUBMIT_OPEN_PERMIT_INFO_APPROVAL: "submit_open_permit_info_approval",
  SUBMIT_UPDATE_BASE_INFO_APPROVAL: "submit_update_base_info_approval",
  SUBMIT_FINANCE_TAXATION_INFO_APPROVAL:
    "submit_finance_taxation_info_approval",
};

const taxColumns = (type: number) => {
  enum Type {
    "纳税记录" = 1,
    "财务报表",
    "审计报告",
    "工资发放记录",
  }
  return [
    {
      title: "分类",
      key: "order",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) => {
        const obj = {
          children: Type[type],
          props: {} as any,
        };
        if (index === 0) {
          obj.props.rowSpan = 99;
        } else {
          obj.props.rowSpan = 0;
        }

        return obj;
      },
    },
    {
      title: "序号",
      width: 80,
      key: "order",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) =>
        `${index + 1}`,
    },
    {
      title: "时间",
      key: "time",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) => {
        return record.time;
      },
    },
    {
      title: "文件",
      key: "files",
      align: "center" as "left",
      render: (text: any, record: BaseTaxProfileItem, index: number) => {
        return record.files.map((x) => <FileViewer file={x} key={x} />);
      },
    },
  ];
};

const CompanyApplyDetail = () => {
  const location = useLocation();
  const [data, setData] = React.useState<CompanyProfileApplyItem | undefined>(
    undefined
  );
  const [firstVisible, setFirstVisible] = React.useState(false);
  const [secondVisible, setSecondVisible] = React.useState(false);
  const [taxVisible, setTaxVisible] = React.useState(false);
  const [firstForm] = Form.useForm();
  const [secondForm] = Form.useForm();
  const [taxForm] = Form.useForm();
  const [fetching, setFetching] = React.useState(false);
  const [log, setLog] = React.useState<CompanyApprovalLogItem[]>([]);

  const getApprovalLog = async (id?: CompanyInfoItem["id"]) => {
    if (!id) {
      id = Number(location.search.split("=")[1]);
    }
    const res = await getCompanyApprovalReacord({
      limit: 10000,
      skip: 0,
      company_info_approval_id: id,
    });
    if (res.code === 0) {
      setLog(res.data);
    } else {
      message.error(res.message);
    }
  };

  const fetchData = async (id?: number) => {
    if (!id) {
      id = Number(location.search.split("=")[1]);
    }
    if (isNaN(id)) return;
    const res = await getCompanyApprovalDetail(id);
    if (res.code === 0) {
      setData(res.data);
    }
  };

  const doFirstApprove = async (input: CompanyApprovalReqBase) => {
    setFetching(true);
    const res = await CompanySalesmanApproval(input);
    if (res.code === 0) {
      message.success("提交成功");
      fetchData();
      getApprovalLog();
      setFirstVisible(false);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  const handleFirstApproveCancel = () => {
    setFirstVisible(false);
  };

  const handleFirstApproveOk = (values: any) => {
    if (!data) return;
    doFirstApprove({
      task_id: data.task_id,
      approval: values.approval,
      comment: values.comment,
    });
  };
  const doSecondApprove = async (input: CompanyApprovalReqBase) => {
    setFetching(true);
    const res = await CompanyManagerApproval(input);
    if (res.code === 0) {
      message.success("提交成功");
      fetchData();
      getApprovalLog();
      setSecondVisible(false);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  const handleSecondApproveCancel = () => {
    setSecondVisible(false);
  };

  const handleSecondApproveOk = (values: any) => {
    if (!data) return;
    doSecondApprove({
      task_id: data.task_id,
      approval: values.approval,
      comment: values.comment,
    });
  };
  const doTaxApproval = async (input: CompanyApprovalReqBase) => {
    setFetching(true);
    const res = await taxApproval(input);
    if (res.code === 0) {
      message.success("提交成功");
      fetchData();
      getApprovalLog();
      setTaxVisible(false);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  const handleTaxApprovalOk = (values: any) => {
    if (!data) return;
    doTaxApproval({
      task_id: data.task_id,
      approval: values.approval,
      comment: values.comment,
    });
  };
  const handleTaxApprovalCancel = () => {
    setTaxVisible(false);
  };

  const getCurrent = (status: number) => {
    if (status === 0) {
      return 1;
    } else if (status === 1 || status === 5 || status === 6) {
      return 2;
    } else {
      return 3;
    }
  };

  const getStatus = (status: number) => {
    if (status === 0 || status === 1) {
      return "info";
    } else if (status === 2 || status === 4 || status === 6) {
      return "error";
    } else {
      return "success";
    }
  };

  React.useEffect(() => {
    if (location.state) {
      const { apply } = location.state as any;
      setData(apply);
    }
    if (!location.state) {
      fetchData();
    }
    if (location.search) {
      getApprovalLog(Number(location.search.split("=")[1]));
    }
  }, [location]);
  return (
    <div>
      <Modal
        title="基础资料审批（初审）"
        footer={null}
        onCancel={handleFirstApproveCancel}
        visible={firstVisible}
      >
        <Form
          form={firstForm}
          layout="vertical"
          onFinish={handleFirstApproveOk}
          preserve={false}
        >
          <Form.Item
            label="审批结果"
            name="approval"
            rules={[{ required: true, message: "请选择审批结果" }]}
          >
            <Radio.Group>
              <Radio value={0}>不通过</Radio>
              <Radio value={1}>通过</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="审批意见"
            name="comment"
            rules={[{ required: true, message: "请输入审批意见" }]}
          >
            <Input.TextArea placeholder="请输入审批意见"></Input.TextArea>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: 36,
                padding: "0 100px",
              }}
            >
              <Button
                type="primary"
                htmlType={"submit"}
                style={{ padding: "0 36px" }}
                loading={fetching}
              >
                确认
              </Button>
              <Button
                onClick={handleFirstApproveCancel}
                style={{ padding: "0 36px" }}
              >
                取消
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="基础资料审批（复审）"
        footer={null}
        onCancel={handleSecondApproveCancel}
        visible={secondVisible}
      >
        <Form
          form={secondForm}
          layout="vertical"
          onFinish={handleSecondApproveOk}
          preserve={false}
        >
          <Form.Item
            label="审批结果"
            name="approval"
            rules={[{ required: true, message: "请选择审批结果" }]}
          >
            <Radio.Group>
              <Radio value={0}>不通过</Radio>
              <Radio value={1}>通过</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="审批意见"
            name="comment"
            rules={[{ required: true, message: "请输入审批意见" }]}
          >
            <Input.TextArea placeholder="请输入审批意见"></Input.TextArea>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: 36,
                padding: "0 100px",
              }}
            >
              <Button
                type="primary"
                htmlType={"submit"}
                style={{ padding: "0 36px" }}
                loading={fetching}
              >
                确认
              </Button>
              <Button
                onClick={handleSecondApproveCancel}
                style={{ padding: "0 36px" }}
              >
                取消
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="基础资料审批（财务）"
        footer={null}
        onCancel={handleTaxApprovalCancel}
        visible={taxVisible}
      >
        <Form
          form={taxForm}
          layout="vertical"
          onFinish={handleTaxApprovalOk}
          preserve={false}
        >
          <Form.Item
            label="审批结果"
            name="approval"
            rules={[{ required: true, message: "请选择审批结果" }]}
          >
            <Radio.Group>
              <Radio value={0}>不通过</Radio>
              <Radio value={1}>通过</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="审批意见"
            name="comment"
            rules={[{ required: true, message: "请输入审批意见" }]}
          >
            <Input.TextArea placeholder="请输入审批意见"></Input.TextArea>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: 36,
                padding: "0 100px",
              }}
            >
              <Button
                type="primary"
                htmlType={"submit"}
                style={{ padding: "0 36px" }}
                loading={fetching}
              >
                确认
              </Button>
              <Button
                onClick={handleTaxApprovalCancel}
                style={{ padding: "0 36px" }}
              >
                取消
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      {data && (
        <div style={{ backgroundColor: "#FFFFFF", padding: "32px 32px" }}>
          {data.process_definition_key ===
            applyType.SUBMIT_FINANCE_TAXATION_INFO_APPROVAL ||
          data.approval_status === 5 ||
          data.approval_status === 6 ? (
            <Steps current={getCurrent(data.approval_status)}>
              <Steps.Step
                title="提交"
                description="用户提交财税资料"
              ></Steps.Step>

              <Steps.Step title="审核" description="财务人员审核"></Steps.Step>
              <Steps.Step
                title="审核完成"
                description="完成财税资料审批"
              ></Steps.Step>
            </Steps>
          ) : (
            <Steps current={getCurrent(data.approval_status)}>
              <Steps.Step
                title="提交"
                description="用户提交企业基础资料"
              ></Steps.Step>
              <Steps.Step title="初审" description="业务人员审核"></Steps.Step>
              <Steps.Step title="复审" description="业务主管审核"></Steps.Step>
              <Steps.Step
                title="审核完成"
                description="完成基础资料审批"
              ></Steps.Step>
            </Steps>
          )}
          {log.length > 0 && (
            <>
              <Divider>审批记录</Divider>
              <List
                dataSource={log}
                renderItem={(item) => (
                  <List.Item key={item.id}>
                    <List.Item.Meta
                      avatar={
                        <Avatar style={{ backgroundColor: "#c37878" }}>
                          {item.operation_user_name.slice(0, 1)}
                        </Avatar>
                      }
                      title={
                        <a onClick={(e) => e.preventDefault()}>
                          {item.operation_user_name}
                        </a>
                      }
                      description={
                        <>
                          <span style={{ paddingRight: 16 }}>
                            审批结果：{item.operation_result}
                          </span>
                          <span style={{ paddingRight: 16 }}>
                            审批意见：{item.comment}
                          </span>
                          <span style={{ paddingRight: 16 }}>
                            审批时间：{item.create_time}
                          </span>
                        </>
                      }
                    ></List.Item.Meta>
                  </List.Item>
                )}
              />
              <Divider></Divider>
            </>
          )}
          <Result
            style={{ padding: 16 }}
            status={getStatus(data.approval_status)}
            title={
              data.approval_status === 5 || data.approval_status === 6
                ? taxapplyRes[getCurrent(data.approval_status)]
                : applyRes[getCurrent(data.approval_status)]
            }
            subTitle={applyResDes[data.approval_status]}
            extra={[
              data.approval_status === 0 &&
                data.process_definition_key !==
                  applyType.SUBMIT_FINANCE_TAXATION_INFO_APPROVAL && (
                  <Button type="primary" onClick={() => setFirstVisible(true)}>
                    提交初审结果
                  </Button>
                ),
              data.approval_status === 1 && (
                <Button type="primary" onClick={() => setSecondVisible(true)}>
                  提交复审结果
                </Button>
              ),
              data.approval_status === 0 &&
                data.process_definition_key ===
                  applyType.SUBMIT_FINANCE_TAXATION_INFO_APPROVAL && (
                  <Button type="primary" onClick={() => setTaxVisible(true)}>
                    提交审核结果
                  </Button>
                ),
            ]}
          />
        </div>
      )}
      {data && data.process_definition_key === applyType.COMPANY_INFO_APPROVAL && (
        <div style={{ backgroundColor: "#ffffff", padding: 16, marginTop: 20 }}>
          <Divider>企业信息(新绑定企业)</Divider>
          <Descriptions title="">
            <Descriptions.Item label="企业名称">
              {data.company_name}
            </Descriptions.Item>
            <Descriptions.Item label="企业电话">
              {data.company_phone}
            </Descriptions.Item>
            <Descriptions.Item label="行业类型">
              {data.industry_type === " " ? "无" : data.industry_type}
            </Descriptions.Item>

            <Descriptions.Item label="注册地址">
              {data.register_address}
            </Descriptions.Item>
            <Descriptions.Item label="联系地址">
              {data.contact_address}
            </Descriptions.Item>

            <Descriptions.Item label="社会统一信用代码">
              {data.uniform_social_credit_code}
            </Descriptions.Item>
            <Descriptions.Item label="营业执照" span={2}>
              <FileViewer file={data.business_license_pic} />
            </Descriptions.Item>
          </Descriptions>
          <Divider>法人信息</Divider>
          <Descriptions title="">
            <Descriptions.Item label="姓名">
              {data.legal_person}
            </Descriptions.Item>
            <Descriptions.Item label="职位">
              {data.legal_person_position}
            </Descriptions.Item>
            <Descriptions.Item label="联系电话">
              {data.legal_person_phone}
            </Descriptions.Item>
            <Descriptions.Item label="电子邮箱">
              {data.legal_person_email}
            </Descriptions.Item>

            <Descriptions.Item label="联系地址">
              {data.legal_person_address}
            </Descriptions.Item>
            <Descriptions.Item label="身份证号">
              {data.legal_person_id_card_num}
            </Descriptions.Item>

            <Descriptions.Item label="身份证信息" span={3}>
              {data.legal_person_id_card_pic.map((x, index) => {
                if (x === null) return null;
                return <FileViewer file={x.file_name} />;
              })}
            </Descriptions.Item>
          </Descriptions>
          <Divider>经办人信息</Divider>
          <Descriptions title="">
            <Descriptions.Item label="姓名">
              {data.agent_name}
            </Descriptions.Item>
            <Descriptions.Item label="职位">
              {data.agent_position}
            </Descriptions.Item>
            <Descriptions.Item label="联系电话">
              {data.agent_phone}
            </Descriptions.Item>
            <Descriptions.Item label="电子邮箱">
              {data.agent_email}
            </Descriptions.Item>

            <Descriptions.Item label="联系地址">
              {data.agent_address}
            </Descriptions.Item>
            <Descriptions.Item label="身份证号">
              {data.agent_id_card_num}
            </Descriptions.Item>
            <Descriptions.Item label="身份证信息">
              {data.agent_id_card_pic.map((x, index) => {
                if (x === null) return null;
                return <FileViewer file={x.file_name} />;
              })}
            </Descriptions.Item>
            <Descriptions.Item label="法人授权书">
              <FileViewer file={data.legal_person_auth_pic} />
            </Descriptions.Item>
          </Descriptions>
        </div>
      )}
      {data &&
        data.process_definition_key ===
          applyType.SUBMIT_LEGAL_PERSON_APPROVAL && (
          <div
            style={{ backgroundColor: "#ffffff", padding: 16, marginTop: 20 }}
          >
            <Divider>提交内容（修改法人信息）</Divider>
            <Descriptions title="">
              <Descriptions.Item label="姓名">
                {data.legal_person}
              </Descriptions.Item>
              <Descriptions.Item label="职位">
                {data.legal_person_position}
              </Descriptions.Item>
              <Descriptions.Item label="联系电话">
                {data.legal_person_phone}
              </Descriptions.Item>
              <Descriptions.Item label="电子邮箱">
                {data.legal_person_email}
              </Descriptions.Item>

              <Descriptions.Item label="联系地址">
                {data.legal_person_address}
              </Descriptions.Item>
              <Descriptions.Item label="身份证号">
                {data.legal_person_id_card_num}
              </Descriptions.Item>

              <Descriptions.Item label="身份证信息" span={3}>
                {data.legal_person_id_card_pic.map((x, index) => {
                  if (x === null) return null;
                  return <FileViewer file={x.file_name} />;
                })}
              </Descriptions.Item>
              <Descriptions.Item label="法人征信" span={2}>
                <FileViewer file={data.legal_credit} />
              </Descriptions.Item>
            </Descriptions>
          </div>
        )}
      {data &&
        data.process_definition_key ===
          applyType.SUBMIT_AGENT_INFO_APPROVAL && (
          <div
            style={{ backgroundColor: "#ffffff", padding: 16, marginTop: 20 }}
          >
            <Divider>提交内容（修改经办人信息）</Divider>
            <Descriptions title="">
              <Descriptions.Item label="姓名">
                {data.agent_name}
              </Descriptions.Item>
              <Descriptions.Item label="职位">
                {data.agent_position}
              </Descriptions.Item>
              <Descriptions.Item label="联系电话">
                {data.agent_phone}
              </Descriptions.Item>
              <Descriptions.Item label="电子邮箱">
                {data.agent_email}
              </Descriptions.Item>

              <Descriptions.Item label="联系地址" span={3}>
                {data.agent_address}
              </Descriptions.Item>
              <Descriptions.Item label="身份证号">
                {data.agent_id_card_num}
              </Descriptions.Item>
              <Descriptions.Item label="身份证信息">
                {data.agent_id_card_pic.map((x, index) => {
                  if (x === null) return null;
                  return <FileViewer file={x.file_name} />;
                })}
              </Descriptions.Item>
              <Descriptions.Item label="法人授权书">
                <FileViewer file={data.legal_person_auth_pic} />
              </Descriptions.Item>
            </Descriptions>
          </div>
        )}

      {data &&
        data.process_definition_key ===
          applyType.SUBMIT_FINANCE_TAXATION_INFO_APPROVAL && (
          <div
            style={{ backgroundColor: "#ffffff", padding: 16, marginTop: 20 }}
          >
            <Divider>提交内容（修改财税信息）</Divider>
            {data.tax_record && (
              <Table
                dataSource={data.tax_record}
                bordered
                columns={taxColumns(1)}
                size="small"
              />
            )}
            {data.financial_statement && (
              <Table
                dataSource={data.financial_statement}
                bordered
                columns={taxColumns(2)}
                size="small"
              />
            )}
            {data.audit_report && (
              <Table
                dataSource={data.audit_report}
                bordered
                columns={taxColumns(3)}
                size="small"
              />
            )}
            {data.payroll_record && (
              <Table
                dataSource={data.payroll_record}
                bordered
                columns={taxColumns(4)}
                size="small"
              />
            )}
          </div>
        )}
      {data &&
        data.process_definition_key ===
          applyType.SUBMIT_SHAREHOLDER_INFO_APPROVAL && (
          <div
            style={{ backgroundColor: "#ffffff", padding: 16, marginTop: 20 }}
          >
            <Divider>提交内容（修改股东信息）</Divider>
            {data.shareholder_message.map((x) => (
              <ShareholderDisplayer item={x} key={x.shareholding_ratio} />
            ))}
          </div>
        )}
      {data &&
        data.process_definition_key ===
          applyType.SUBMIT_UPDATE_BASE_INFO_APPROVAL && (
          <div
            style={{ backgroundColor: "#ffffff", padding: 16, marginTop: 20 }}
          >
            <Divider>提交内容（修改企业基础信息）</Divider>
            <Descriptions title="">
              <Descriptions.Item label="企业名称">
                {data.company_name}
              </Descriptions.Item>
              <Descriptions.Item label="企业电话">
                {data.company_phone}
              </Descriptions.Item>
              <Descriptions.Item label="行业类型">
                {data.industry_type === " " ? "无" : data.industry_type}
              </Descriptions.Item>

              <Descriptions.Item label="注册地址" span={3}>
                {data.register_address}
              </Descriptions.Item>
              <Descriptions.Item label="联系地址" span={3}>
                {data.contact_address}
              </Descriptions.Item>
              <Descriptions.Item label="绑定时间">
                {data.create_time}
              </Descriptions.Item>

              <Descriptions.Item label="社会统一信用代码">
                {data.uniform_social_credit_code}
              </Descriptions.Item>
              <Descriptions.Item label="营业执照" span={2}>
                <FileViewer file={data.business_license_pic} />
              </Descriptions.Item>
              <Descriptions.Item label="企业征信">
                <FileViewer file={data.company_credit} />
              </Descriptions.Item>
              <Descriptions.Item label="企业简介">
                <FileViewer file={data.company_profile} />
              </Descriptions.Item>
            </Descriptions>
          </div>
        )}
      {data &&
        data.process_definition_key ===
          applyType.SUBMIT_CHARTER_INFO_APPROVAL && (
          <div
            style={{ backgroundColor: "#ffffff", padding: 16, marginTop: 20 }}
          >
            <Divider>提交内容（修改企业章程）</Divider>
            <Descriptions title="">
              <Descriptions.Item label="企业章程">
                <FileViewer file={data.charter} />
              </Descriptions.Item>
            </Descriptions>
          </div>
        )}
      {data &&
        data.process_definition_key ===
          applyType.SUBMIT_OPEN_PERMIT_INFO_APPROVAL && (
          <div
            style={{ backgroundColor: "#ffffff", padding: 16, marginTop: 20 }}
          >
            <Divider>提交内容（修改开户许可证）</Divider>
            <Descriptions title="">
              <Descriptions.Item label="开户许可证">
                <FileViewer file={data.opening_permit} />
              </Descriptions.Item>
            </Descriptions>
          </div>
        )}
    </div>
  );
};

export default CompanyApplyDetail;
