import * as React from "react";
import {
  Divider,
  Descriptions,
  message,
  Button,
  Steps,
  Result,
  List,
  Collapse,
  Tag,
  Table,
  Col,
  Modal,
} from "antd";
import { useLocation } from "react-router";
import { getCompanyList } from "@/requests/company";
import { getBusinessApplyDetail } from "@/requests/business";
import FileViewer from "@/ui-components/FileViewer";
import CompanyInfoDisplayer from "@/ui-components/CompanyInfoDIsplayer";
import { toThousands } from "@/utils/string";
import { getProductList } from "@/requests/product";
import SMApprovalModal from "../components/SMApprovalModal";
import BSApprovalModal from "../components/BSApprovalModal";
import RCPApprovalModal from "../components/RCPApprovalModal";
import RCSApprovalModal from "../components/RCSApprovalModal";
import CFOApprovalModal from "../components/CFOApprovalModal";
import GMApprovalModal from "../components/GMApprovalModal";
import ApprovalLogDisplayer from "../components/ApprovalLogDisplayer";
import { sendContract } from "@/requests/contractCommon";
import ContractPreviewer from "@/ui-components/ContractPreviewer";

enum AccountType {
  "同授信账户" = 1,
  "其他账户",
}
enum applyResDes {
  "等待审核中，请尽快完成客户资料补充，提交业务主管审核" = 0,
  "业务人员审核已完成，请结合业务人员审核结果，并完成业务合同发送，确认签署结果，提交复审意见" = 1,
  "业务员审核不通过，申请已关闭" = 2,
  "业务部门审核完成，请结合业务部门审核结果，提交风控审批意见" = 3,
  "业务主管审核不通过，请重新提交审核" = 4,
  "财务人员审核通过，请提交终审意见" = 5,
  "财务人员审核不通过，请重新提交审核" = 6,
  "风控成员审核通过，请结合风控人员审核结果，提交风控复审意见" = 7,
  "风控人员审核不通过，请重新提交审核" = 8,
  "风控部门审核通过，请提交财务审核意见" = 9,
  "风控主管审核不通过，请重新提交审核" = 10,
  "业务审批完成，业务生效，已进入放款流程" = 11,
  "总经理审核不通过，请重新提交审核意见" = 12,
}
enum applyRes {
  "待业务人员审核" = 1,
  "待业务主管审核" = 2,
  "待风控人员审核" = 3,
  "待风控主管审核" = 4,
  "待财务人员审核" = 5,
  "待总经理审核" = 6,
  "审核完成" = 7,
}
//保理服务内容
enum FactoringContent {
  "融资" = 1,
  "管理",
  "催收",
  "付款担保",
}
//公开隐蔽型保理
enum OpenType {
  "公开型" = 1,
  "隐蔽型",
}
//回款方式
enum ReturnedType {
  "直接回款" = 1,
  "间接回款",
  "其他",
}
//追索权
enum RecoveryCompensation {
  "有追索权" = 1,
  "无追索权",
}
//保理服务费支付方式
enum FactoringCostPayType {
  "期初一次性" = 1,
  "到期一次性",
  "其他",
}
//保理融资款收款账户类型
enum FanancingAccountType {
  "同授信合同" = 1,
  "其他账户",
}
//保理融资款支付方式
enum FanancingPayType {
  "同授信合同" = 1,
  "其他",
}

//保理融资款用途
enum FanacingPurpose {
  "不限定用途" = 1,
  "限定用途",
}

//宽限期类型
enum GraceType {
  "没有宽限期" = 1,
  "有宽限期",
}

//支付方式
enum PayType {
  "期初一次性" = 1,
  "分期支付",
  "到期一次性",
  "其他",
}

//增信措施
enum CreditVoucher {
  "无增信措施" = 0,
  "自身不动产抵押",
  "第三方不动产抵押",
  "自身动产质押",
  "第三方动产质押",
  "第三方担保",
}
//合同状态
enum ContractStatus {
  "未发送" = 1,
  "待签署",
  "签署完成",
  "拒绝签署",
}
// 涉诉情况
enum Litagation {
  "否" = 0,
  "是",
}
enum SatisFyRegulation {
  "否" = 0,
  "是" = 1,
}
enum HaveAdvance {
  "否" = 0,
  "是" = 1,
}

const BusinessApplyDetail = () => {
  const location = useLocation();
  const [data, setData] = React.useState<undefined | BusinessApplyItem>(
    undefined
  );
  const [loading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState<ProductItem | undefined>(
    undefined
  );

  const [approveVisible, setApproveVisible] = React.useState(false);

  const [companyInfo, setCompanyInfo] = React.useState<
    undefined | CompanyInfoItem
  >(undefined);

  const fetchData = async () => {
    let id: string = "";
    if (location.state) {
      const state = location.state as any;
      id = state.id;
    } else if (location.search) {
      id = location.search.split("=")[1];
    }
    if (!id) return;
    setLoading(true);
    const res = await getBusinessApplyDetail(Number(id));
    if (res.code === 0) {
      setData(res.data);
      fetchCompanyInfo(res.data.company_name);
      fetchPorduct(res.data.product_id);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const fetchPorduct = async (product_id: BusinessApplyItem["product_id"]) => {
    if (!product_id) return;
    const res = await getProductList({
      limit: 100,
      skip: 0,
    });
    if (res.code === 0) {
      let _product = res.data.find((x) => x.id === product_id);
      if (_product) {
        setProduct(_product);
      }
    } else {
    }
  };

  const fetchCompanyInfo = async (
    company_name: BusinessApplyItem["company_name"]
  ) => {
    const res = await getCompanyList({
      limit: 10000,
      skip: 0,
    });
    if (res.code === 0) {
      const company = res.data.find((x) => x.company_name === company_name);
      if (company) {
        setCompanyInfo(company);
      }
    } else {
    }
  };

  const getCurrent = (status: number) => {
    if (status === 0 || status === 4) {
      return 1;
    } else if (status === 1 || status === 8) {
      return 2;
    } else if (status === 3 || status === 10) {
      return 3;
    } else if (status === 7 || status === 6) {
      return 4;
    } else if (status === 9 || status === 12) {
      return 5;
    } else if (status === 5) {
      return 6;
    } else if (status === 11) {
      return 7;
    } else {
      return 7;
    }
  };

  const getStatus = (status: number) => {
    if (
      status === 0 ||
      status === 1 ||
      status === 3 ||
      status === 5 ||
      status === 7 ||
      status === 9
    ) {
      return "info";
    } else if (
      status === 2 ||
      status === 4 ||
      status === 6 ||
      status === 8 ||
      status === 10 ||
      status === 12
    ) {
      return "error";
    } else if (status === 11) {
      return "success";
    }
  };

  const getContractStatusColor = (status: number) => {
    switch (status) {
      case 1:
        return "orange";
      case 2:
        return "volcano";
      case 3:
        return "green";
      case 4:
        return "red";
      default:
        return "orange";
    }
  };

  const doSendContract = async (contract: BusinessContractListItem) => {
    if (!contract) return;
    if (!data) return;
    message.loading("正在发送合同");
    let map: any = {};
    JSON.parse(contract.recipient).map((x: any) => {
      map[x.role] = JSON.parse(
        JSON.stringify({ enterpriseName: x.name, userAccount: x.phone })
      );
    });
    console.log(map);
    const res = await sendContract({
      biz_type: 0,
      template_content: JSON.parse(contract.contract_content),
      template_id: contract.template_id,
      template_roles: map,
      template_type: contract.type,
      approval_id: data.id,
      uuid: contract.uuid,
    });

    if (res.code === 0) {
      message.destroy();
      message.success("发送成功，请提醒客户签署");
      fetchData();
    } else {
      message.error(res.message);
    }
  };

  React.useEffect(() => {
    if (!location.search) return;
    fetchData();
  }, [location]);
  return (
    <div className="credit-detail-page">
      {data && (
        <>
          <Steps
            current={getCurrent(data.status)}
            size="small"
            style={{ margin: "16px 0" }}
          >
            <Steps.Step title="提交" description="用户提交申请"></Steps.Step>
            <Steps.Step title="业务员审核" description={"业务员"}></Steps.Step>
            <Steps.Step
              title="业务主管审核"
              description="业务主管"
            ></Steps.Step>
            <Steps.Step
              title="风控人员审核"
              description="风控人员"
            ></Steps.Step>
            <Steps.Step
              title="风控主管审核"
              description="风控主管"
            ></Steps.Step>
            <Steps.Step title="财务审核" description="财务主管"></Steps.Step>
            <Steps.Step title="总经理审核" description="总经理"></Steps.Step>

            <Steps.Step
              title="审核完成"
              description="完成业务审批"
            ></Steps.Step>
          </Steps>
          <Result
            style={{ padding: 16 }}
            status={getStatus(data.status)}
            title={applyRes[getCurrent(data.status)]}
            subTitle={applyResDes[data.status]}
            extra={[
              data.status !== 2 && data.status !== 11 && (
                <Button
                  type="primary"
                  onClick={() => {
                    setApproveVisible(true);
                  }}
                >
                  开始审核
                </Button>
              ),
            ]}
          />
          <Divider>用户提交内容</Divider>
          <Descriptions>
            <Descriptions.Item label="企业名称">
              {data.company_name}
            </Descriptions.Item>
            {product && (
              <Descriptions.Item label="产品">
                {product.product_name}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="申请额度">
              {toThousands(data.quota)} 元
            </Descriptions.Item>
            <Descriptions.Item label="融资期限">
              {data.quota_deadline} 天
            </Descriptions.Item>
            <Descriptions.Item label="应收账款债务人">
              {data.debtor}
            </Descriptions.Item>
            <Descriptions.Item label="贸易合同">
              <FileViewer file={data.contract} />
            </Descriptions.Item>

            <Descriptions.Item label="贸易发票">
              <FileViewer file={data.invoice} />
            </Descriptions.Item>
            {data.invoice && (
              <Descriptions.Item label="发票数量">
                {data.invoice_number} 张
              </Descriptions.Item>
            )}
            {data.invoice && (
              <Descriptions.Item label="发票总金额">
                {toThousands(data.invoice_total)} 元
              </Descriptions.Item>
            )}
            <Descriptions.Item label="其他材料">
              <FileViewer file={data.other_information} />
            </Descriptions.Item>
            <Descriptions.Item label="融资收款账户">
              {AccountType[data.account_type]}
            </Descriptions.Item>
            {data.account_type === 2 && (
              <Descriptions.Item label="其他收款账户账户名">
                {JSON.parse(data.other_account)["账户名"]}
              </Descriptions.Item>
            )}
            {data.account_type === 2 && (
              <Descriptions.Item label="其他收款账户账号">
                {JSON.parse(data.other_account)["账号"]}
              </Descriptions.Item>
            )}
            {data.account_type === 2 && (
              <Descriptions.Item label="其他收款账户开户行">
                {JSON.parse(data.other_account)["开户行"]}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="申请时间">
              {data.create_time}
            </Descriptions.Item>
          </Descriptions>
          {companyInfo && (
            <Collapse>
              <Collapse.Panel key="1" header="企业基础资料(点击展开)">
                <CompanyInfoDisplayer data={companyInfo} />
              </Collapse.Panel>
            </Collapse>
          )}
          {data.status > 0 && data.status !== 2 && (
            <>
              <Divider>业务信息</Divider>
              {data.approval_config && (
                <>
                  <Descriptions>
                    <Descriptions.Item label="保理服务内容">
                      {data.approval_config.factoring_content
                        .split(",")
                        .map((x) => (
                          <Tag color={"geekblue"}>
                            {FactoringContent[Number(x)]}
                          </Tag>
                        ))}
                    </Descriptions.Item>
                    <Descriptions.Item label="公开/隐蔽型保理">
                      {OpenType[data.approval_config.open_type]}
                    </Descriptions.Item>
                    <Descriptions.Item label="追索权">
                      {
                        RecoveryCompensation[
                          data.approval_config.recovery_compensation
                        ]
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label="回款方式">
                      {ReturnedType[data.approval_config.returned_type]}
                    </Descriptions.Item>
                    <Descriptions.Item label="其他回款方式">
                      {data.approval_config.other_returned_type}
                    </Descriptions.Item>
                  </Descriptions>
                  <Divider>应收账款信息</Divider>
                  <Descriptions>
                    <Descriptions.Item label="应收账款总额">
                      {toThousands(data.approval_account.account_total)} 元
                    </Descriptions.Item>

                    <Descriptions.Item label="债务人名称">
                      {data.approval_account.obligor_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="债务人电话">
                      {data.approval_account.obligor_phone}
                    </Descriptions.Item>
                    {data.approval_account.varied_sign === 0 && (
                      <Descriptions.Item label="应收账款描述" span={2}>
                        {data.approval_account.described}
                      </Descriptions.Item>
                    )}

                    {data.approval_account.varied_sign === 1 && (
                      <Descriptions.Item label="应收账款详情" span={3}>
                        <Table
                          pagination={false}
                          style={{ width: "100%" }}
                          dataSource={
                            data.approval_account.approval_account_children
                          }
                          columns={[
                            {
                              title: "序号",
                              key: "order",
                              align: "center",
                              render: (text, record, index) => index + 1,
                            },
                            {
                              dataIndex: "contract_sn",
                              key: "contract_sn",
                              title: "基础交易合同及编号",
                              align: "center",
                            },
                            {
                              dataIndex: "bill_name",
                              key: "bill_name",
                              title: "单据名称",
                              align: "center",
                            },
                            {
                              dataIndex: "bill_sn",
                              key: "bill_sn",
                              title: "单据号码",
                              align: "center",
                            },
                            {
                              key: "account_total",
                              title: "应收账款总额",
                              align: "center",
                              render: (text, record, index) => (
                                <span>
                                  {toThousands(record.account_total)} 元
                                </span>
                              ),
                            },
                            {
                              dataIndex: "account_expire",
                              key: "account_expire",
                              title: "应收账款到期日",
                              align: "center",
                            },
                          ]}
                        ></Table>
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                </>
              )}

              <Divider>费用信息</Divider>
              {data.cost_config && (
                <>
                  <Descriptions>
                    <Descriptions.Item label="保理融资款金额">
                      {toThousands(data.cost_config.financing_total)} 元
                    </Descriptions.Item>
                    <Descriptions.Item label="保理融资款比例">
                      {data.cost_config.financing_proportion}%
                    </Descriptions.Item>
                    <Descriptions.Item label="保理融资款支付方式">
                      {FanancingPayType[data.cost_config.financing_pay_type]}
                    </Descriptions.Item>
                    {data.cost_config.financing_pay_type === 2 && (
                      <Descriptions.Item label="其他保理融资款支付方式">
                        {data.cost_config.other_pay_type}
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item label="保理融资款收款账户类型">
                      {
                        FanancingAccountType[
                          data.cost_config.financing_account_type
                        ]
                      }
                    </Descriptions.Item>
                    {data.cost_config.financing_account_type === 2 && (
                      <>
                        <Descriptions.Item label="其他保理融资款账户账号">
                          {
                            JSON.parse(
                              data.cost_config.other_financing_account
                            )["账号"]
                          }
                        </Descriptions.Item>
                        <Descriptions.Item label="其他保理融资款账户开户名">
                          {
                            JSON.parse(
                              data.cost_config.other_financing_account
                            )["户名"]
                          }
                        </Descriptions.Item>
                        <Descriptions.Item label="其他保理融资款账户开户行">
                          {
                            JSON.parse(
                              data.cost_config.other_financing_account
                            )["开户行"]
                          }
                        </Descriptions.Item>
                      </>
                    )}
                    <Descriptions.Item label="保理融资期限">
                      {data.cost_config.financing_expriy_date}
                    </Descriptions.Item>
                    <Descriptions.Item label="保理融资款发放日">
                      {data.cost_config.financing_issue_date}
                    </Descriptions.Item>
                    <Descriptions.Item label="保理融资款用途">
                      {FanacingPurpose[data.cost_config.financing_purpose]}
                    </Descriptions.Item>
                    {data.cost_config.financing_purpose === 2 && (
                      <Descriptions.Item label="限定保理融资款用途">
                        {data.cost_config.financing_purpose_explain}
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item label="保理服务费">
                      {toThousands(data.cost_config.factoring_cost)} 元
                    </Descriptions.Item>
                    <Descriptions.Item label="保理服务费支付方式">
                      {
                        FactoringCostPayType[
                          data.cost_config.factoring_cost_pay_type
                        ]
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label="逾期利率">
                      {data.cost_config.overdue_interest_rate}%
                    </Descriptions.Item>

                    <Descriptions.Item label="是否有宽限期">
                      {GraceType[data.cost_config.have_grace]}
                    </Descriptions.Item>
                    {data.cost_config.have_grace === 2 && (
                      <>
                        <Descriptions.Item label="宽限期天数">
                          {JSON.parse(data.cost_config.grace_info)["天数"]}
                        </Descriptions.Item>
                        <Descriptions.Item label="宽限期届满日">
                          {JSON.parse(data.cost_config.grace_info)["届满日"]}
                        </Descriptions.Item>

                        <Descriptions.Item label="宽限期利息支付方式">
                          {PayType[data.cost_config.grace_interest_pay_type]}
                        </Descriptions.Item>
                        <Descriptions.Item label="宽限期利率">
                          {data.cost_config.grace_interest_rate}%
                        </Descriptions.Item>
                        {data.cost_config.grace_interest_pay_type === 2 && (
                          <>
                            <Descriptions.Item label="分期支付宽限期利息周期">
                              {
                                JSON.parse(
                                  data.cost_config
                                    .periodization_grace_interest_rate
                                )["周期"]
                              }
                              天
                            </Descriptions.Item>
                            <Descriptions.Item label="分期支付宽限期利息支付日">
                              每月
                              {
                                JSON.parse(
                                  data.cost_config
                                    .periodization_grace_interest_rate
                                )["支付日"]
                              }
                              日
                            </Descriptions.Item>
                          </>
                        )}
                        {data.cost_config.grace_interest_pay_type === 4 && (
                          <Descriptions.Item label="其他宽限期利息支付方式">
                            {data.cost_config.periodization_interest_pay_type}
                          </Descriptions.Item>
                        )}
                      </>
                    )}
                    <Descriptions.Item label="正常期利率">
                      {data.cost_config.normal_interest_rate}%
                    </Descriptions.Item>
                    <Descriptions.Item label="正常期利息支付方式">
                      {PayType[data.cost_config.normal_interest_pay_type]}
                    </Descriptions.Item>

                    {data.cost_config.normal_interest_pay_type === 4 && (
                      <Descriptions.Item label="其他正常期利息支付方式">
                        {data.cost_config.other_normal_interest_pay_type}
                      </Descriptions.Item>
                    )}

                    {data.cost_config.normal_interest_pay_type === 2 && (
                      <>
                        <Descriptions.Item label="分期支付正常期利息周期">
                          {
                            JSON.parse(
                              data.cost_config.periodization_interest_rate
                            )["周期"]
                          }
                          天
                        </Descriptions.Item>
                        <Descriptions.Item label="分期支付正常期利息支付日">
                          每月
                          {
                            JSON.parse(
                              data.cost_config.periodization_interest_rate
                            )["支付日"]
                          }
                          日
                        </Descriptions.Item>
                      </>
                    )}
                  </Descriptions>
                </>
              )}

              <Divider>增信措施及合同</Divider>
              {data.credit_voucher && (
                <>
                  <Descriptions>
                    <Descriptions.Item label="增信措施">
                      {data.credit_voucher.split(",").map((x: string) => (
                        <Tag key={x}>{CreditVoucher[Number(x)]}</Tag>
                      ))}
                    </Descriptions.Item>
                  </Descriptions>
                </>
              )}
              {data.contract_list && (
                <>
                  <Descriptions>
                    <Descriptions.Item label="业务合同">
                      <Col>
                        {data.contract_list.length > 0
                          ? data.contract_list.map((x, index) => (
                              <Col key={x.template_id}>
                                {x.template_name ? x.template_name : ""}{" "}
                                <Tag color={getContractStatusColor(x.status)}>
                                  {ContractStatus[x.status]}
                                </Tag>
                                {x.status === 1 && (
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      Modal.confirm({
                                        title: "提示",
                                        content: "确认发送合同吗？",
                                        onOk: () => {
                                          doSendContract(x);
                                        },
                                        onCancel: () => {},
                                        okText: "确认",
                                        cancelText: "取消",
                                      });
                                    }}
                                  >
                                    发送合同
                                  </a>
                                )}
                                {x.status !== 3 && (
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      fetchData();
                                    }}
                                    style={{ paddingLeft: 8, paddingRight: 8 }}
                                  >
                                    刷新
                                  </a>
                                )}
                                {x.status === 3 && (
                                  <ContractPreviewer
                                    contract_id={data.contract_id[index]}
                                  />
                                )}
                              </Col>
                            ))
                          : "无"}
                      </Col>
                    </Descriptions.Item>
                  </Descriptions>
                </>
              )}
              {data.status > 4 && (
                <>
                  <Divider>风控合规管理信息</Divider>
                  <Descriptions>
                    <Descriptions.Item label="是否涉诉">
                      {Litagation[data.have_litigation]}
                    </Descriptions.Item>
                    <Descriptions.Item label="涉诉查询截图">
                      {data.litigation_img
                        ? data.litigation_img.map((img, index) => {
                            return <FileViewer file={img} key={index} />;
                          })
                        : "无"}
                    </Descriptions.Item>
                    <Descriptions.Item label="企业经营状况">
                      {data.management_stauts}
                    </Descriptions.Item>
                    <Descriptions.Item label="是否合规">
                      {SatisFyRegulation[data.satisfy_regulations]}
                    </Descriptions.Item>
                  </Descriptions>
                </>
              )}
              {(data.status === 5 || data.status === 11) && (
                <>
                  <Divider>财务审核信息</Divider>
                  <Descriptions>
                    <Descriptions.Item label="是否有预付款">
                      {HaveAdvance[data.have_advance]}
                    </Descriptions.Item>
                    {data.have_advance === 1 && (
                      <>
                        <Descriptions.Item label="预付款类目">
                          {data.advance_type}
                        </Descriptions.Item>
                        <Descriptions.Item label="预付款金额">
                          {toThousands(data.advance_money)} 元
                        </Descriptions.Item>
                        <Descriptions.Item label="是否到账">
                          {data.advance_arrival === 1 ? "是" : "否"}
                        </Descriptions.Item>
                      </>
                    )}
                  </Descriptions>
                </>
              )}
            </>
          )}

          <>
            <Divider>审批记录</Divider>
            <List
              dataSource={data.operation_logs}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  <ApprovalLogDisplayer data={item} />
                </List.Item>
              )}
            />
          </>
        </>
      )}
      {data && (data.status === 0 || data.status === 4) && (
        <SMApprovalModal
          visible={approveVisible}
          setVisible={setApproveVisible}
          fetchData={fetchData}
          data={data}
        />
      )}
      {data && (data.status === 1 || data.status === 8) && (
        <BSApprovalModal
          visible={approveVisible}
          setVisible={setApproveVisible}
          fetchData={fetchData}
          data={data}
        />
      )}
      {data && (data.status === 3 || data.status === 10) && (
        <RCPApprovalModal
          visible={approveVisible}
          setVisible={setApproveVisible}
          fetchData={fetchData}
          data={data}
        />
      )}
      {data && (data.status === 7 || data.status === 6) && (
        <RCSApprovalModal
          visible={approveVisible}
          setVisible={setApproveVisible}
          fetchData={fetchData}
          data={data}
        />
      )}
      {data && (data.status === 9 || data.status === 12) && (
        <CFOApprovalModal
          visible={approveVisible}
          setVisible={setApproveVisible}
          fetchData={fetchData}
          data={data}
        />
      )}
      {data && data.status === 5 && (
        <GMApprovalModal
          visible={approveVisible}
          setVisible={setApproveVisible}
          fetchData={fetchData}
          data={data}
        />
      )}
    </div>
  );
};

export default BusinessApplyDetail;
