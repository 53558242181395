import * as React from "react";
import { message, Table, Button, Modal, Input, Form, Select } from "antd";
import { getTemplateList, addTemplate } from "@/requests/template";
import { PlusOutlined } from "@ant-design/icons";
import "../styles/template.less";

enum TemplateType {
  "默认" = 0,
  "应收账款转让申请确认书" = 1,
  "应收账款转让通知书" = 2,
  "《应收账款转让通知书》回执" = 3,
  "提款申请暨确认书" = 4,
  "应收账款回款账号变更通知书" = 5,
  "《应收账款回款账号变更通知书》回执" = 6,
  "应收账款回购通知书" = 7,
  "《应收账款回购通知书》回执" = 8,
  "付款担保申请暨确认书" = 9,
  "质押合同（企业）" = 21,
  "最高额抵押合同" = 22,
  "最高额保证合同（个人）" = 23,
  "最高额保证合同（企业）" = 24,
  "质押合同（个人）" = 25,
}

const ContractTemplateList = () => {
  const [loading, setLoading] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [data, setData] = React.useState<ContractTemplateItem[]>([]);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [addVisible, setAddVisible] = React.useState(false);
  const [filterType, setFilterType] = React.useState(0);
  const [addForm] = Form.useForm();

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.page;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    setLoading(true);
    const res = await getTemplateList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
    });

    if (res.code === 0) {
      setData(res.data);
      setPagination({
        page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }

    setLoading(false);
  };

  const doAdd = async (values: AddContractTemplateReq) => {
    setFetching(true);
    const res = await addTemplate(values);
    if (res.code === 0) {
      message.success("添加成功");
      setFetching(false);
      fetchData();
      setAddVisible(false);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  const handleAddCancel = () => {
    setAddVisible(false);
  };

  const handleAddOk = (values: AddContractTemplateReq) => {
    doAdd(values);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const colunms = [
    {
      title: "序号",
      dataIndex: "order",
      key: "order",
      align: "center" as "center",
      render: (text: any, record: ContractTemplateItem, index: number) =>
        `${index + 1}`,
    },
    {
      title: "模板名称",
      dataIndex: "template_name",
      key: "template_name",
      align: "center" as "center",
      render: (text: any, record: ContractTemplateItem, index: number) =>
        `${record.template_name}`,
    },
    {
      title: "模板类型",
      dataIndex: "template_type",
      key: "template_type",
      align: "center" as "center",
      render: (text: any, record: ContractTemplateItem, index: number) =>
        `${TemplateType[record.template_type]}`,
    },
    {
      title: "模板编号",
      dataIndex: "template_id",
      key: "template_id",
      align: "center" as "center",
      render: (text: any, record: ContractTemplateItem, index: number) =>
        `${record.template_id}`,
    },

    {
      title: "创建时间",
      dataIndex: "create_time",
      key: "create_time",
      align: "center" as "center",
      render: (text: any, record: ContractTemplateItem, index: number) =>
        `${record.create_time}`,
    },
    {
      title: "操作",
      key: "actions",
      align: "center" as "center",
      render: (text: any, record: ContractTemplateItem, index: number) => {
        return (
          <span>
            <a
              key="edit"
              style={{ paddingRight: 8 }}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              编辑
            </a>
            <a
              key="delete"
              onClick={(e) => {
                e.preventDefault();
                Modal.confirm({
                  title: "提示",
                  content: "确认删除该角色吗？",
                  okText: "确认",
                  onOk: async () => {},
                  cancelText: "取消",
                  onCancel: () => {},
                });
              }}
            >
              删除
            </a>
          </span>
        );
      },
    },
  ];
  return (
    <div className="template-list-page">
      <div className="tools-wrapper">
        <div className="template-list-filter-input-container ">
          <Form layout="inline" colon={false}>
            <Form.Item label="模板类型" className="filter-item">
              <Select
                style={{ minWidth: 168 }}
                placeholder="全部"
                onChange={(value: number) => setFilterType(value)}
                value={filterType}
              >
                <Select.Option value={0}>全部</Select.Option>
                <Select>
                  <Select.Option value={1}>
                    应收账款转让申请确认书
                  </Select.Option>
                  <Select.Option value={2}>应收账款转让通知书</Select.Option>
                  <Select.Option value={3}>
                    《应收账款转让通知书》回执
                  </Select.Option>
                  <Select.Option value={4}>提款申请暨确认书</Select.Option>
                  <Select.Option value={5}>
                    应收账款回款账号变更通知书
                  </Select.Option>
                  <Select.Option value={6}>
                    《应收账款回款账号变更通知书》回执
                  </Select.Option>
                  <Select.Option value={7}>应收账款回购通知书</Select.Option>
                  <Select.Option value={8}>
                    《应收账款回购通知书》回执
                  </Select.Option>
                  <Select.Option value={9}>付款担保申请暨确认书</Select.Option>
                  <Select.Option value={21}>质押合同</Select.Option>
                  <Select.Option value={22}>最高额抵押合同</Select.Option>
                  <Select.Option value={23}>最高额保证合同</Select.Option>
                </Select>
              </Select>
            </Form.Item>
          </Form>
        </div>

        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setAddVisible(true)}
        >
          添加模板
        </Button>
      </div>
      <Table
        dataSource={data}
        columns={colunms}
        pagination={{
          ...pagination,
          showTotal: (total) => <span>共{total}条数据</span>,
        }}
        loading={loading}
      ></Table>
      <Modal
        visible={addVisible}
        title={
          <span>
            <div className="title-prefix"></div>添加模板
          </span>
        }
        onCancel={handleAddCancel}
        footer={null}
        destroyOnClose
      >
        <Form
          form={addForm}
          preserve={false}
          layout="vertical"
          onFinish={handleAddOk}
        >
          <Form.Item
            label="模板名称"
            name="template_name"
            rules={[
              {
                required: true,
                message: "请输入模板名称",
              },
            ]}
          >
            <Input placeholder="请输入模板名称" />
          </Form.Item>

          <Form.Item
            label="模板编号"
            name="template_id"
            rules={[{ required: true, message: "请输入模板编号" }]}
          >
            <Input placeholder="请输入模板编号" />
          </Form.Item>
          <Form.Item
            label="模板类型"
            name="template_type"
            rules={[{ required: true, message: "请选择模板类型" }]}
          >
            <Select placeholder="请选择模板类型">
              <Select.Option value={1}>应收账款转让申请确认书</Select.Option>
              <Select.Option value={2}>应收账款转让通知书</Select.Option>
              <Select.Option value={3}>
                《应收账款转让通知书》回执
              </Select.Option>
              <Select.Option value={4}>提款申请暨确认书</Select.Option>
              <Select.Option value={5}>
                应收账款回款账号变更通知书
              </Select.Option>
              <Select.Option value={6}>
                《应收账款回款账号变更通知书》回执
              </Select.Option>
              <Select.Option value={7}>应收账款回购通知书</Select.Option>
              <Select.Option value={8}>
                《应收账款回购通知书》回执
              </Select.Option>
              <Select.Option value={9}>付款担保申请暨确认书</Select.Option>
              <Select.Option value={21}>质押合同（企业）</Select.Option>
              <Select.Option value={25}>质押合同（个人）</Select.Option>
              <Select.Option value={22}>抵押合同</Select.Option>
              <Select.Option value={23}>最高额保证合同（个人）</Select.Option>
              <Select.Option value={24}>最高额保证合同（企业）</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: 36,
                padding: "0 100px",
              }}
            >
              <Button
                type="primary"
                htmlType={"submit"}
                style={{ padding: "0 36px" }}
                loading={fetching}
              >
                确认
              </Button>
              <Button onClick={handleAddCancel} style={{ padding: "0 36px" }}>
                取消
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ContractTemplateList;
