import * as React from "react";
import { Modal, Image } from "antd";
import { DOWNLOAD_ACTION } from "@/requests/company";
import { download } from "@/utils/file";

interface PropsType {
  file: string | undefined | null;
}

const FileViewer = (props: PropsType) => {
  const [previewVisible, setPreviewVisible] = React.useState(false);
  const { file } = props;
  const handlePrevieww = () => {
    setPreviewVisible(true);
  };
  if (file === undefined || file === null) return null;
  if (!file) {
    return <span>无</span>;
  }
  if (file.endsWith("png") || file.endsWith("jpg") || file.endsWith("jpeg")) {
    return (
      <span style={{ marginRight: 8 }}>
        <Image width={29} height={29} src={DOWNLOAD_ACTION + file} />
      </span>
    );
  }
  if (file.endsWith("pdf")) {
    return (
      <a href={DOWNLOAD_ACTION + file} target="_blank">
        点击查看
      </a>
    );
  }

  if (
    file.endsWith("word") ||
    file.endsWith("xlsx") ||
    file.endsWith("xls") ||
    file.endsWith("docx") ||
    file.endsWith("doc") ||
    file.endsWith("video") ||
    file.endsWith("zip")
  ) {
    return (
      <a
        onClick={(e) => {
          e.preventDefault();
          download(DOWNLOAD_ACTION + file, file);
        }}
      >
        点击下载
      </a>
    );
  }
  return <span>{file}</span>;
};

export default FileViewer;
