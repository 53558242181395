import * as React from "react";
import { getCompanyApprovalReacord } from "@/requests/company";
import { message, List, Avatar } from "antd";

const CompanyApprovalRecord = () => {
  const [data, setData] = React.useState<CompanyApprovalLogItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 8,
    total: 0,
  });

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.page;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    const res = await getCompanyApprovalReacord({
      limit: pageSize,
      skip: (page - 1) * pageSize,
    });
    if (res.code === 0) {
      setData(res.data);
      setPagination({
        page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);
  return (
    <div style={{ padding: 16, backgroundColor: "#ffffff" }}>
      <List
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar style={{ backgroundColor: "#c37878" }}>
                  {item.operation_user_name.slice(0, 1)}
                </Avatar>
              }
              title={
                <a onClick={(e) => e.preventDefault()}>{item.user_name}</a>
              }
              description={
                <>
                  <span style={{ paddingRight: 16 }}>
                    审批结果：{item.operation_result}
                  </span>
                  <span style={{ paddingRight: 16 }}>
                    审批意见：{item.comment}
                  </span>
                  <span style={{ paddingRight: 16 }}>
                    审批时间：{item.create_time}
                  </span>
                  <span style={{ paddingRight: 16 }}>
                    处理人：{item.operation_user_name}
                  </span>
                </>
              }
            />
          </List.Item>
        )}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => fetchData(page, pageSize),
          style: { marginRight: 16 },
          showTotal: (total) => <span>共{total}条数据</span>,
        }}
        loading={loading}
      />
    </div>
  );
};

export default CompanyApprovalRecord;
