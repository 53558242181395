import * as React from "react";
import {
  LockOutlined,
  CreditCardOutlined,
  BankOutlined,
  PayCircleOutlined,
  FileDoneOutlined,
  BlockOutlined,
  ProfileOutlined,
} from "@ant-design/icons";

import { Link, useHistory, withRouter, useLocation } from "react-router-dom";
import { useCtxState } from "@/store/App.store";
import { Layout, Menu } from "antd";
import "./index.less";

const { SubMenu } = Menu;

interface MenuChildItem {
  to: string;
  label: string;
  hideInSiderBar: boolean;
  key: string;
}
interface MenuItem {
  to?: string;
  key: string;
  label: string;
  icon: React.ReactNode;
  children?: MenuChildItem[];
  hideInSiderBar: boolean;
}

interface SysMenuConfig {
  defaultActive: string;
  defaultOpeneds: string[];
  menus: MenuItem[];
}

const rootSubmenuKeys = [
  "credit-management",
  "quota-management",
  "company-management",
  "auth-management",
];

const SiderBarMenuConfig: SysMenuConfig = {
  defaultActive: "company-apply-list",
  defaultOpeneds: ["company-management"],
  menus: [
    {
      label: "授信管理",
      key: "credit-management",
      icon: <CreditCardOutlined />,
      children: [
        {
          to: "/workbench/credit-management/credit-apply-list",
          label: "授信审批",
          hideInSiderBar: false,
          key: "credit-apply-list",
        },

        {
          to: "/workbench/credit-management/handle-record",
          label: "审核记录",
          hideInSiderBar: false,
          key: "handle-record",
        },
      ],
      hideInSiderBar: false,
    },
    {
      label: "额度管理",
      key: "quota-management",
      icon: <PayCircleOutlined />,
      children: [
        {
          to: "/workbench/quota-management/company-quota",
          label: "企业额度",
          hideInSiderBar: false,
          key: "company-quota",
        },
      ],
      hideInSiderBar: false,
    },
    {
      label: "企业管理",
      key: "company-management",
      icon: <BankOutlined />,
      children: [
        {
          to: "/workbench/company-management/company-apply-list",
          label: "资料审批",
          hideInSiderBar: false,
          key: "company-apply-list",
        },
        {
          to: "/workbench/company-management/list",
          label: "企业清单",
          hideInSiderBar: false,
          key: "list",
        },
        {
          to: "/workbench/company-management/approval-record",
          label: "审批记录",
          hideInSiderBar: false,
          key: "approval-record",
        },
      ],
      hideInSiderBar: false,
    },
    {
      label: "合同管理",
      key: "contract",
      icon: <FileDoneOutlined />,
      children: [
        {
          to: "/workbench/contract/contract-approval",
          label: "授信合同审批",
          hideInSiderBar: false,
          key: "contract-approval",
        },
        {
          to: "/workbench/contract/list",
          label: "合同列表",
          hideInSiderBar: false,
          key: "list",
        },
        {
          to: "/workbench/contract/template",
          label: "合同模板",
          hideInSiderBar: false,
          key: "template",
        },
      ],
      hideInSiderBar: false,
    },
    {
      label: "产品管理",
      key: "product",
      icon: <BlockOutlined />,
      children: [
        {
          to: "/workbench/product/list",
          label: "产品列表",
          hideInSiderBar: false,
          key: "list",
        },
      ],
      hideInSiderBar: false,
    },
    {
      label: "业务管理",
      key: "business",
      icon: <ProfileOutlined />,
      children: [
        {
          to: "/workbench/business/apply-list",
          label: "业务审批",
          hideInSiderBar: false,
          key: "apply-list",
        },
        {
          to: "/workbench/business/business-list",
          label: "业务台账",
          hideInSiderBar: false,
          key: "business-list",
        },
        {
          to: "/workbench/business/billing-list",
          label: "放款制单",
          hideInSiderBar: false,
          key: "billing-list",
        },
        {
          to: "/workbench/business/loan-list",
          label: "放款复核",
          hideInSiderBar: false,
          key: "loan-list",
        },
        {
          to: "/workbench/business/bill-record",
          label: "放款记录",
          hideInSiderBar: false,
          key: "bill-record",
        },
        {
          to: "/workbench/business/repayment/apply-list",
          label: "还款审核",
          hideInSiderBar: false,
          key: "repayment",
        },
      ],
      hideInSiderBar: false,
    },

    {
      label: "权限管理",
      key: "auth-management",
      icon: <LockOutlined />,

      children: [
        {
          to: "/workbench/auth-management/user",
          label: "成员列表",
          hideInSiderBar: false,
          key: "user",
        },
        {
          to: "/workbench/auth-management/role",
          label: "角色管理",
          hideInSiderBar: false,
          key: "role",
        },
        {
          to: "/workbench/auth-management/auth",
          label: "系统权限",
          hideInSiderBar: false,
          key: "auth",
        },
      ],
      hideInSiderBar: false,
    },
  ],
};

function getNavLinkNode(child_item: MenuChildItem, collapsed: boolean) {
  return (
    <span>
      <Link
        id={child_item.key}
        to={child_item.to}
        style={{
          color: "inherit",
          paddingRight: collapsed ? 0 : "100%",
        }}
      >
        {child_item.label}
      </Link>
    </span>
  );
}

const Sidebar = () => {
  const [{ userInfo, sidebarCollapsed }] = useCtxState();
  const [openKeys, setOpenKeys] = React.useState(["assets-management"]);
  const history = useHistory();
  const location = useLocation();
  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  React.useEffect(() => {
    const key = location.pathname.split("/")[2];
    if (key) {
      setOpenKeys([key]);
    } else {
      return;
    }
  }, [location]);

  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={sidebarCollapsed}
      className="workbench-page__sidebar"
    >
      <div className="logo">
        {sidebarCollapsed ? (
          <div style={{ display: "flex" }}>
            <div
              style={{
                fontSize: 24,
                color: "#1DA57A",
                fontWeight: 500,
              }}
            >
              LQ
            </div>
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <div
              style={{
                fontSize: 24,
                color: "#1DA57A",
                paddingRight: 36,
                fontWeight: 500,
              }}
            >
              管理后台
            </div>
          </div>
        )}
      </div>
      <Menu
        defaultSelectedKeys={[SiderBarMenuConfig.defaultActive]}
        defaultOpenKeys={SiderBarMenuConfig.defaultOpeneds}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        selectedKeys={[
          `${
            document.location.hash.slice(2)
              ? document.location.hash.slice(2).split("/")[2]
                ? document.location.hash.slice(2).split("/")[2].split("?")[0]
                : ""
              : ""
          }`,
        ]}
        mode="inline"
        theme="dark"
      >
        {SiderBarMenuConfig.menus.map((menu, _index) => {
          if (menu.children && menu.children.length > 0) {
            if (menu.key === "auth-management") {
              const hasAuth = !!userInfo.role_list.find(
                (x: RoleItem) => x.id === 16 || x.name === "系统管理员"
              );
              if (!hasAuth) {
                return null;
              }
            }
            return (
              <SubMenu icon={menu.icon} key={menu.key} title={menu.label}>
                {menu.children.map((child) => {
                  return (
                    <Menu.Item key={child.key}>
                      {getNavLinkNode(child, sidebarCollapsed)}
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );
          } else {
            return (
              <Menu.Item key={menu.key} icon={menu.icon}>
                {menu.label}
              </Menu.Item>
            );
          }
        })}
      </Menu>
    </Layout.Sider>
  );
};

export default withRouter(Sidebar);
