import * as React from "react";
import { Button, Modal, Form, Radio, Input, message } from "antd";

import { businessRCPApproval } from "@/requests/business";
import PicWalL from "@/ui-components/PicWall";

interface PropsType {
  visible: boolean;
  setVisible: Function;
  fetchData: Function;
  data: BusinessApplyItem;
}

const RCPApprovalModal: React.FC<PropsType> = (props: PropsType) => {
  const { visible, setVisible, fetchData, data } = props;

  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [isPass, setIsPass] = React.useState(1);

  const handleCancel = () => {
    setVisible(false);
  };

  const doApproval = async (input: BusinessRCPApprovalReq) => {
    setLoading(true);
    const res = await businessRCPApproval(input);
    if (res.code === 0) {
      message.success("提交成功");
      fetchData();
      setVisible(false);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const handleOK = (values: any) => {
    console.log(values);
    doApproval({
      ...values,
      litigation_img: values["litigation_img"].map((x: any) => x.response.data),
      task_id: data.task_id,
    });
  };

  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>业务审核
        </span>
      }
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      maskClosable={false}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            justifyContent: "center",
          }}
        >
          <Form form={form} preserve={false} onFinish={handleOK}>
            <Form.Item
              label="审批结果"
              name="approval"
              rules={[{ required: true, message: "请选择审批结果" }]}
              initialValue={isPass}
            >
              <Radio.Group onChange={(e) => setIsPass(e.target.value)}>
                <Radio value={1}>通过</Radio>
                <Radio value={0}>不通过</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="审批意见"
              name="comment"
              rules={[{ required: true, message: "请输入审批意见" }]}
            >
              <Input.TextArea placeholder="请输入审批意见"></Input.TextArea>
            </Form.Item>
            {isPass === 1 && (
              <>
                <Form.Item
                  label="是否涉诉"
                  name="have_litigation"
                  rules={[{ required: true, message: "请选择涉诉情况" }]}
                  initialValue={0}
                >
                  <Radio.Group>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="涉诉截图"
                  name="litigation_img"
                  rules={[{ required: true, message: "请上传涉诉查询截图" }]}
                >
                  <PicWalL action="/api/file/upload" />
                </Form.Item>
                <Form.Item
                  label="经营状况"
                  name="management_stauts"
                  rules={[
                    { required: true, message: "请输入企业经营状况描述" },
                  ]}
                  initialValue={0}
                >
                  <Input.TextArea placeholder="企业经营状况描述" />
                </Form.Item>
                <Form.Item
                  label="是否合规"
                  name="satisfy_regulations"
                  rules={[{ required: true, message: "请选择企业合股情况" }]}
                  initialValue={1}
                >
                  <Radio.Group>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            )}

            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 36,
                  padding: "0 100px",
                }}
              >
                <Button
                  style={{ padding: "0 36px" }}
                  loading={loading}
                  onClick={handleCancel}
                >
                  取消
                </Button>
                <Button
                  type="primary"
                  htmlType={"submit"}
                  style={{ padding: "0 36px" }}
                >
                  完成
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default RCPApprovalModal;
