import * as React from "react";
import { Layout, Form, Input, Button, Checkbox, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import MyFooter from "../../ui-layout/Footer";
import "./index.less";
import { RouteComponentProps, withRouter } from "react-router";
import * as userRequests from "@/requests/user";

const { Content, Footer } = Layout;
interface FormValues extends LoginReq {
  remember: boolean;
}

const Login = (props: RouteComponentProps) => {
  const [fetching, setFetching] = React.useState(false);
  const [form] = Form.useForm();

  const doLogin = async (input: LoginReq) => {
    setFetching(true);
    const res = await userRequests.login(input);
    if (res.code === 0) {
      message.success(`欢迎您`);
    } else if (res.code === 12001) {
      message.error("密码错误");
    } else if (res.code === 12002) {
      message.error("登录信息过期，请登录！");
    } else if (res.code === 12003) {
      message.error("账户不可用");
    } else if (res.code === 12004) {
      message.error("用户名不存在");
    } else if (res.code === 12005) {
      message.success(`欢迎您！${res.data.user_name}`);
      props.history.replace("/");
    }
    // else if (res.code === 12006) {
    //   message.error("密码错误");
    // }
    // else if (res.code === 12007) {
    //   message.error("密码错误");
    // }
    // else if (res.code === 12008) {
    //   message.error("密码错误");
    // }
    // else if (res.code === 12009) {
    //   message.error("密码错误");
    // }

    // else if (res.code === 12010) {
    //   message.error("密码错误");
    // }
    else {
      message.error(res.message);
    }
    setFetching(false);
  };
  const onFinish = (values: FormValues) => {
    console.log("Received values of form: ", values);
    const { remember } = values;
    if (remember) {
      const account = form.getFieldsValue(["user_name", "password"]);
      localStorage.setItem("account", JSON.stringify(account));
    } else {
      localStorage.removeItem("account");
    }
    doLogin({
      user_name: values.user_name,
      password: values.password,
    });
  };

  React.useEffect(() => {
    const accountStr = localStorage.getItem("account");
    if (accountStr) {
      const account = JSON.parse(accountStr);
      form.setFieldsValue({
        user_name: account.user_name,
        password: account.password,
      });
    }
  });

  return (
    <div className="login-container">
      <Layout>
        <Content>
          <div className="login-content">
            <div className="form-container">
              <h2
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: 24,
                  lineHeight: 1.5,
                  color: "#1DA57A",
                  marginBottom: 24,
                }}
              >
                兰青e保理管理后台
              </h2>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                form={form}
              >
                <Form.Item
                  name="user_name"
                  rules={[
                    { required: true, message: "请输入您的用户名或手机号" },
                  ]}
                >
                  <Input
                    prefix={
                      <UserOutlined
                        className="site-form-item-icon"
                        style={{ color: "#1DA57A" }}
                      />
                    }
                    placeholder="用户名/手机号"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: "请输入您的密码" }]}
                  style={{ marginBottom: 8 }}
                >
                  <Input
                    prefix={
                      <LockOutlined
                        className="site-form-item-icon"
                        style={{ color: "#1DA57A" }}
                      />
                    }
                    type="password"
                    placeholder="密码"
                    size="large"
                  />
                </Form.Item>
                <Form.Item style={{ marginBottom: 8 }}>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox style={{ color: "#646464" }}>记住密码</Checkbox>
                  </Form.Item>
                  <a
                    className="login-form-forgot"
                    href="/"
                    style={{ float: "right" }}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    忘记密码
                  </a>
                </Form.Item>

                <Form.Item style={{ marginBottom: 8 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    style={{ width: "100%" }}
                    size="large"
                    loading={fetching}
                  >
                    {fetching ? "正在登录" : "登录"}
                  </Button>
                </Form.Item>
                <div className="form-row-wrapper">
                  <span>
                    还没有账号？
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        props.history.push("/register");
                      }}
                    >
                      {"立即注册>"}
                    </a>
                  </span>
                </div>
              </Form>
            </div>
          </div>
        </Content>
        <Footer className="login-footer">
          <MyFooter />
        </Footer>
      </Layout>
    </div>
  );
};

export default withRouter(Login);
