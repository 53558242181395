import * as React from "react";
import {
  Button,
  Modal,
  Form,
  Radio,
  Input,
  message,
  Select,
  InputNumber,
} from "antd";

import { repaymentSalesmanApproval } from "@/requests/business";

interface PropsType {
  visible: boolean;
  setVisible: Function;
  fetchData: Function;
  data: RepaymentApplyListDetail;
  id: number;
}

const RepaymentSMApprovalModal: React.FC<PropsType> = (props: PropsType) => {
  const { visible, setVisible, fetchData, data, id } = props;

  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [isPass, setIsPass] = React.useState(1);

  const handleCancel = () => {
    setVisible(false);
  };

  const doApproval = async (input: RepaymentSalesmanApprovalReq) => {
    setLoading(true);
    const res = await repaymentSalesmanApproval(input);
    if (res.code === 0) {
      message.success("提交成功");
      fetchData(id);
      setVisible(false);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const handleOK = (values: any) => {
    console.log(values);
    doApproval({
      ...values,
      approval: values.approval === 1 ? true : false,
      task_id: data.task_id,
    });
  };

  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>业务审核
        </span>
      }
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      maskClosable={false}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            justifyContent: "center",
          }}
        >
          <Form form={form} preserve={false} onFinish={handleOK}>
            <Form.Item
              label="审批结果"
              name="approval"
              rules={[{ required: true, message: "请选择审批结果" }]}
              initialValue={isPass}
            >
              <Radio.Group onChange={(e) => setIsPass(e.target.value)}>
                <Radio value={1}>通过</Radio>
                <Radio value={0}>不通过</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="审批意见"
              name="comment"
              rules={[{ required: true, message: "请输入审批意见" }]}
            >
              <Input.TextArea placeholder="请输入审批意见"></Input.TextArea>
            </Form.Item>
            {isPass === 1 && (
              <>
                <Form.Item
                  label="还款类型"
                  name="type"
                  rules={[{ required: true, message: "请选择还款类型" }]}
                  initialValue={1}
                >
                  <Select>
                    <Select.Option value={1}>正常还款</Select.Option>
                    <Select.Option value={2}>逾期还款</Select.Option>
                    <Select.Option value={3}>提前还款</Select.Option>
                    <Select.Option value={4}>提前回购</Select.Option>
                    <Select.Option value={5}>其他</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item label="逾期天数" required>
                  <Form.Item
                    noStyle
                    name="overdue_day"
                    rules={[{ required: true, message: "请输入逾期天数" }]}
                    initialValue={0}
                  >
                    <InputNumber />
                  </Form.Item>
                  <span style={{ marginLeft: 8 }}>天</span>
                </Form.Item>
                <Form.Item label="逾期利率" required>
                  <Form.Item
                    noStyle
                    name="overdue_rate"
                    rules={[{ required: true, message: "请输入逾期利率" }]}
                    initialValue={24}
                  >
                    <InputNumber />
                  </Form.Item>
                  <span style={{ marginLeft: 8 }}>%</span>
                </Form.Item>
                <Form.Item label="正常期利率" required>
                  <Form.Item
                    noStyle
                    name="normal_rate"
                    rules={[{ required: true, message: "请输入逾期利率" }]}
                    initialValue={0}
                  >
                    <InputNumber />
                  </Form.Item>
                  <span style={{ marginLeft: 8 }}>%</span>
                </Form.Item>
                <Form.Item label="宽限期利率" required>
                  <Form.Item
                    noStyle
                    name="grace_rate"
                    rules={[{ required: true, message: "请输入逾期利率" }]}
                    initialValue={0}
                  >
                    <InputNumber />
                  </Form.Item>
                  <span style={{ marginLeft: 8 }}>%</span>
                </Form.Item>
                <Form.Item label="计息天数" required>
                  <Form.Item
                    noStyle
                    name="interest_day"
                    rules={[{ required: true, message: "请输入逾期利率" }]}
                    initialValue={0}
                  >
                    <InputNumber />
                  </Form.Item>
                  <span style={{ marginLeft: 8 }}>天</span>
                </Form.Item>
                <Form.Item label="还款总金额" required>
                  <Form.Item
                    noStyle
                    name="total"
                    rules={[{ required: true, message: "请输入逾期利率" }]}
                    initialValue={0}
                  >
                    <InputNumber />
                  </Form.Item>
                  <span style={{ marginLeft: 8 }}>元</span>
                </Form.Item>
                <Form.Item
                  label="还款备注"
                  name="memo"
                  // rules={[{ required: true, message: "请输入还款备注" }]}
                >
                  <Input.TextArea placeholder="如有还款补充说明，请填在这里" />
                </Form.Item>
              </>
            )}

            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 36,
                  padding: "0 100px",
                }}
              >
                <Button
                  style={{ padding: "0 36px" }}
                  loading={loading}
                  onClick={handleCancel}
                >
                  取消
                </Button>
                <Button
                  type="primary"
                  htmlType={"submit"}
                  style={{ padding: "0 36px" }}
                >
                  完成
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default RepaymentSMApprovalModal;
