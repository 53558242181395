import * as React from "react";
import { message, Form, Input, DatePicker, Button, List, Card } from "antd";
import { getBillingList, billing } from "@/requests/loan";
import { useLocation } from "react-router";
import { SearchOutlined, RedoOutlined } from "@ant-design/icons";
import { toThousands } from "@/utils/string";
import "../styles/index.less";
import BillingModal from "../components/BillingModal";
import FileViewer from "@/ui-components/FileViewer";

const BillingList = () => {
  const [data, setData] = React.useState<BillingListItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [billVisible, setBillVisible] = React.useState(false);
  const [billingData, setBillingData] = React.useState<BillingListItem>();

  const location = useLocation();

  const fetchData = async (page?: number, pageSize?: number) => {
    setLoading(true);
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    if (!page) {
      page = pagination.page;
    }

    const res = await getBillingList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
    });

    if (res.code === 0) {
      setData(res.data.records);
      setPagination({
        page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (location.pathname === "/workbench/business/billing-list") {
      fetchData();
    }
  }, [location]);

  return (
    <div className="billing-list-page">
      {billingData && (
        <BillingModal
          visible={billVisible}
          setVisible={setBillVisible}
          data={billingData}
          fetchData={fetchData}
        />
      )}
      <div className="tools-wrapper">
        <Form colon={false} layout="inline">
          <Form.Item label="企业名称" className="filter-item">
            <Input style={{ maxWidth: 200 }} placeholder="全部"></Input>
          </Form.Item>

          <Form.Item label="申请时间" className="filter-item">
            <DatePicker.RangePicker style={{ minWidth: 200 }} showTime />
          </Form.Item>
        </Form>

        <div style={{ display: "flex" }}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            style={{ marginRight: 10 }}
            onClick={() => fetchData()}
          >
            搜索
          </Button>
          <Button icon={<RedoOutlined />}>重置</Button>
        </div>
      </div>
      <div style={{ backgroundColor: "#ffffff", padding: 16, marginTop: 16 }}>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
            xxl: 4,
          }}
          dataSource={data}
          loading={loading}
          renderItem={(item) => {
            if (item === null) return null;
            return (
              <List.Item key={item.id}>
                <Card
                  title={`企业名称：${item.company_name} `}
                  actions={[
                    <Button
                      type="primary"
                      onClick={() => {
                        setBillingData(item);
                        setBillVisible(true);
                      }}
                    >
                      制单提交
                    </Button>,
                  ]}
                >
                  <Form labelCol={{ md: 8, lg: 8 }} labelAlign={"left"}>
                    <Form.Item label="放款金额" style={{ marginBottom: 0 }}>
                      {`${toThousands(item.amount)} 元`}
                    </Form.Item>
                    {item.account && (
                      <>
                        <Form.Item
                          label="收款账户开户名"
                          style={{ marginBottom: 0 }}
                        >
                          <span>
                            {JSON.parse(item.account)["户名"] ||
                              JSON.parse(item.account)["开户名"]}
                          </span>
                        </Form.Item>
                        <Form.Item
                          label="收款账户账号"
                          style={{ marginBottom: 0 }}
                        >
                          <span>{JSON.parse(item.account)["账号"]}</span>
                        </Form.Item>
                        <Form.Item
                          label="收款账户开户行"
                          style={{ marginBottom: 0 }}
                        >
                          <span>{JSON.parse(item.account)["开户行"]}</span>
                        </Form.Item>
                      </>
                    )}

                    <Form.Item label="申请时间" style={{ marginBottom: 0 }}>
                      <span>{item.create_time}</span>
                    </Form.Item>
                    <Form.Item label="最迟放款时间" style={{ marginBottom: 0 }}>
                      <span>{item.deadline}</span>
                    </Form.Item>
                  </Form>
                </Card>
              </List.Item>
            );
          }}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
            style: { marginRight: 16 },
            showTotal: (total) => <span>共{total}条数据</span>,
          }}
        />
      </div>
    </div>
  );
};

export default BillingList;
