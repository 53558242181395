/// <reference path="router.d.ts" />
import { withRouter } from "react-router-dom";
import CreditApplyList from "@/pages/CreditManagement/CreditApplyList";
import HandleRecord from "@/pages/CreditManagement/HandleRecord";
import CreditDetail from "@/pages/CreditManagement/CreditDetail";
import SubmitCredit from "@/pages/CreditManagement/SubmitCredit";
import RoleList from "@/pages/AuthManagement/pages/RoleList";
import UserList from "@/pages/AuthManagement/pages/UserList";
import AuthManage from "@/pages/AuthManagement/pages/AuthManage";
import CompanyList from "@/pages/CompanyManagement/pages/CompanyList";
import CompanyApplyList from "@/pages/CompanyManagement/pages/CompanyApplyList";
import CompanyApplyDetail from "@/pages/CompanyManagement/pages/CompanyApplyDetail";
import CompanyApprovalRecord from "@/pages/CompanyManagement/pages/CompanyApprovalRecord";
import CompanyInfoDetail from "@/pages/CompanyManagement/pages/CompanyInfoDetail";
import CompanyQuota from "@/pages/QuotaManangement/CompanyQuota";
import ContractApplyList from "@/pages/ContractManagement/pages/ContractApplyList";
import ContractApplyDetail from "@/pages/ContractManagement/pages/ContractApplyDetail";
import ProductList from "@/pages/ProductManagement/ProductList";
import EditProduct from "@/pages/ProductManagement/ProductList/pages/EditProduct";
import BusinessApplyList from "@/pages/BusinessManagement/pages/BusinessApplyList";
import BusinessApplyDetail from "@/pages/BusinessManagement/pages/BusinessApplyDetail";
import ContractTemplateList from "@/pages/ContractManagement/pages/ContractTemplateList";
import BillingList from "@/pages/BusinessManagement/pages/BillingList";
import RecheckList from "@/pages/BusinessManagement/pages/ReCheckList";
import AllBillList from "@/pages/BusinessManagement/pages/AllBillList";
import BillDetail from "@/pages/BusinessManagement/pages/BillDetail";
import ContractList from "@/pages/ContractManagement/pages/ContractList";
import BusinessList from "@/pages/BusinessManagement/pages/BusinessList";
import BusinessDetail from "@/pages/BusinessManagement/pages/BusinessDetail";

import NoMatched from "@/pages/404";
import RepaymentApplyList from "@/pages/BusinessManagement/pages/RepaymentApplyList";
import RepaymentApplyDetail from "@/pages/BusinessManagement/pages/RepaymentApplyDetail";

export const ROUTE_CONFIG: BaseRouteConfigItem[] = [
  {
    exact: true,
    path: "/workbench/credit-management/credit-apply-list",
    component: withRouter(CreditApplyList),
  },
  {
    exact: true,
    path: "/workbench/credit-management/handle-record",
    component: withRouter(HandleRecord),
  },
  {
    exact: true,
    path: "/workbench/credit-management/credit-apply-list/credit-detail",
    component: withRouter(CreditDetail),
  },
  {
    exact: true,
    path: "/workbench/credit-management/credit-apply-list/submit-credit",
    component: withRouter(SubmitCredit),
  },
  {
    exact: true,
    path: "/workbench/quota-management/company-quota/",
    component: withRouter(CompanyQuota),
  },
  {
    exact: true,
    path: "/workbench/auth-management/user",
    component: withRouter(UserList),
  },
  {
    exact: true,
    path: "/workbench/auth-management/role",
    component: RoleList,
  },
  {
    exact: true,
    path: "/workbench/auth-management/auth",
    component: withRouter(AuthManage),
  },
  {
    exact: true,
    path: "/workbench/company-management/list",
    component: withRouter(CompanyList),
  },

  {
    exact: true,
    path: "/workbench/company-management/company-apply-list",
    component: withRouter(CompanyApplyList),
  },
  {
    exact: true,
    path: "/workbench/company-management/company-apply-detail",
    component: withRouter(CompanyApplyDetail),
  },
  {
    exact: true,
    path: "/workbench/company-management/approval-record",
    component: withRouter(CompanyApprovalRecord),
  },
  {
    exact: true,
    path: "/workbench/company-management/company-info-detail",
    component: withRouter(CompanyInfoDetail),
  },
  {
    exact: true,
    path: "/workbench/contract/contract-approval",
    component: withRouter(ContractApplyList),
  },
  {
    exact: true,
    path: "/workbench/contract/template",
    component: withRouter(ContractTemplateList),
  },
  {
    exact: true,
    path: "/workbench/contract/list",
    component: withRouter(ContractList),
  },
  {
    exact: true,
    path: "/workbench/contract/contract-approval/detail",
    component: withRouter(ContractApplyDetail),
  },
  {
    exact: true,
    path: "/workbench/product/list",
    component: withRouter(ProductList),
  },
  {
    exact: true,
    path: "/workbench/product/detail",
    component: withRouter(EditProduct),
  },
  {
    exact: true,
    path: "/workbench/business/apply-list",
    component: withRouter(BusinessApplyList),
  },
  {
    exact: true,
    path: "/workbench/business/business-list",
    component: withRouter(BusinessList),
  },
  {
    exact: true,
    path: "/workbench/business/business-list/detail",
    component: withRouter(BusinessDetail),
  },
  {
    exact: true,
    path: "/workbench/business/apply-list/detail",
    component: withRouter(BusinessApplyDetail),
  },
  {
    exact: true,
    path: "/workbench/business/billing-list",
    component: withRouter(BillingList),
  },
  {
    exact: true,
    path: "/workbench/business/loan-list",
    component: withRouter(RecheckList),
  },
  {
    exact: true,
    path: "/workbench/business/bill-record",
    component: withRouter(AllBillList),
  },
  {
    exact: true,
    path: "/workbench/business/bill-record/detail",
    component: withRouter(BillDetail),
  },
  {
    exact: true,
    path: "/workbench/business/repayment/apply-list",
    component: withRouter(RepaymentApplyList),
  },
  {
    exact: true,
    path: "/workbench/business/repayment/apply-list/detail",
    component: withRouter(RepaymentApplyDetail),
  },

  { exact: true, path: "*", component: NoMatched },
];
