/// <reference path="type.d.ts" />
/// <reference path="quota.d.ts" />

import request from "./_base";

/**
 * 获取额度列表
 * @param  {GetQuotaListReq} input
 */
export async function getQuotaList(
  input: GetQuotaListReq
): Promise<ResponseProto<{ data: QuotaItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/quota/quota/list`,
    data: input,
  }) as any;
}
