/// <reference path="type.d.ts" />
/// <reference path="loan.d.ts" />

import request from "./_base";

/**
 * 制单列表
 * @param  {GetBillingListReq} input
 */
export async function getBillingList(
  input: GetBillingListReq
): Promise<ResponseProto<{ data: BillingList; total: number }>> {
  return request({
    method: "POST",
    url: `/billing-loan/billing/list`,
    data: input,
  }) as any;
}

/**
 * 制单
 * @param  {BillingReq} input
 */
export async function billing(
  input: BillingReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/billing-loan/billing/make`,
    data: input,
  }) as any;
}

/**
 * 复核列表
 * @param  {GetBillingListReq} input
 */
export async function getLoanList(
  input: GetBillingListReq
): Promise<ResponseProto<{ data: BillingList; total: number }>> {
  return request({
    method: "POST",
    url: `/billing-loan/loan/list`,
    data: input,
  }) as any;
}

/**
 * 复核
 * @param  {LoanReq} input
 */
export async function loan(
  input: LoanReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/billing-loan/loan/make`,
    data: input,
  }) as any;
}

/**
 * 全部放款记录列表
 * @param  {GetBillingListReq} input
 */
export async function getAllBillList(
  input: GetBillingListReq
): Promise<ResponseProto<{ data: BillingList; total: number }>> {
  return request({
    method: "POST",
    url: `/billing-loan/list`,
    data: input,
  }) as any;
}
