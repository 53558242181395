import * as React from "react";
import {
  Button,
  message,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Steps,
  Divider,
  Select,
} from "antd";
import { ContractList } from "./SMApprovalModal";
import { getTemplateList } from "@/requests/template";
import moment from "moment";
import { toThousands } from "@/utils/string";

interface PropsType {
  contracts: ContractList[];
  setContracts: Function;
  visible: boolean;
  setVisible: Function;
  data: BusinessApplyItem;
  accountInfo: BusinessApprovalAccount | undefined;
  businessConfig: BusinessApprovalConfig | undefined;
  costConfig: BusinessCostConfig | undefined;
}

//保理服务内容
enum FactoringContent {
  "应收账款融资" = 1,
  "应收账款管理",
  "催收",
  "付款担保",
}
//公开隐蔽型保理
enum OpenType {
  "公开型" = 1,
  "隐蔽型",
}
//回款方式
enum ReturnedType {
  "直接回款" = 1,
  "间接回款",
  "其他",
}
//追索权
enum RecoveryCompensation {
  "有追索权" = 1,
  "无追索权",
}
//保理服务费支付方式
enum FactoringCostPayType {
  "期初一次性" = 1,
  "到期一次性",
  "其他",
}
//保理融资款收款账户类型
enum FanancingAccountType {
  "同授信合同" = 1,
  "其他账户",
}
//保理融资款支付方式
enum FanancingPayType {
  "同授信合同" = 1,
  "其他",
}

//保理融资款用途
enum FanacingPurpose {
  "不限定用途" = 1,
  "限定用途",
}

//宽限期类型
enum GraceType {
  "没有宽限期" = 1,
  "有宽限期",
}

//支付方式
enum PayType {
  "期初一次性" = 1,
  "分期支付",
  "到期一次性",
  "其他",
}

//增信措施
enum CreditVoucher {
  "无增信措施" = 0,
  "自身不动产抵押",
  "第三方不动产抵押",
  "自身动产质押",
  "第三方动产质押",
  "第三方担保",
}
//合同状态
enum ContractStatus {
  "未发送" = 1,
  "待签署",
  "签署完成",
  "拒绝签署",
}
// 涉诉情况
enum Litagation {
  "否" = 0,
  "是",
}
enum SatisFyRegulation {
  "否" = 0,
  "是" = 1,
}
enum HaveAdvance {
  "否" = 0,
  "是" = 1,
}

const AddContractModal: React.FC<PropsType> = (props: PropsType) => {
  const {
    visible,
    contracts,
    setContracts,
    setVisible,
    data,
    costConfig,
    businessConfig,
    accountInfo,
  } = props;
  const [template, setTemplate] = React.useState<
    ContractTemplateItem | undefined
  >();
  const [step, setStep] = React.useState(0);
  const [form0] = Form.useForm();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [contract, setContract] = React.useState<ContractList>({
    type: 1,
    contract_content: "",
    recipient: "",
    status: 0,

    template_id: "",
    template_name: "",
    uuid: data.uuid,
    id: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
  });
  const transformContent = (data: string) => {
    return data
      .slice(2, -2)
      .split(",")
      .filter((x) => {
        return x.startsWith(" DescribeField") || x.startsWith("DescribeField");
      })
      .map((x) => {
        return x.split("=")[1];
      });
  };
  React.useEffect(() => {
    if (visible) {
      fetchTemplate(1);
    }
    if (!visible) {
      setTemplate(undefined);
    }
  }, [visible]);

  const fetchTemplate = async (type: number) => {
    if (!contract) return;
    message.loading("正在加载模板");
    const res = await getTemplateList({
      template_type: type,
    });
    if (res.code === 0) {
      message.destroy();
      if (res.total === 1) {
        setTemplate(res.data[0]);
      } else {
        message.error("加载模板失败");
        setTemplate(undefined);
      }
    } else {
      message.error(res.message);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };
  const handlepreStep = () => {
    setStep(step - 1);
  };

  const handleNextStep = (values: any) => {
    if (!template) return;
    if (step === 0) {
      setContract({
        ...contract,
        template_name: template.template_name,
        type: template.template_type,
        template_id: template.template_id,
      });

      setStep(1);
    }
    if (step === 1) {
      setContract({
        ...contract,

        recipient: JSON.stringify(
          Object.keys(JSON.parse(template.template_role)).map((key) => {
            return {
              role: key,
              name: values[`name-${key}`],
              phone: values[`phone-${key}`],
            };
          })
        ),
      });
      setStep(2);
    }
    if (step === 2) {
      Modal.confirm({
        title: "温馨提示",
        content: "确认保存合同内容吗？",
        onOk: () => {
          const list = contracts.map((x) => x);
          list.push({
            ...contract,
            contract_content: JSON.stringify(values),
            status: 1,
          });
          setContracts(list);

          setStep(0);
          setVisible(false);
        },
        onCancel: () => {},
        okText: "确认",
        cancelText: "取消",
      });
    }
  };

  const initContractFieldValue = (filedName: string) => {
    if (!accountInfo || !businessConfig || !accountInfo || !data || !costConfig)
      return "";
    switch (filedName) {
      case "商业保理人":
        return "深圳前海兰青商业保理有限公司";
      case "应收账款债权人":
        return data.company_name;
      // case "综合授信合同签订日期":
      //   return `${moment(new Date()).format("YYYY-MM-DD")}`;
      // case "综合授信合同编号":
      //   return `lq-${moment(new Date()).format("YYYYMMDD")}-000001`;
      case "应收账款陈列方式":
        return accountInfo.varied_sign === 0 ? "1" : "2";
      case "概括性陈列描述":
        if (accountInfo.varied_sign === 0) {
          return accountInfo.described;
        } else {
          return "/";
        }
      case "应收账款债务人":
        return accountInfo.obligor_name;
      case "基础交易合同编号1":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[0]
            ? accountInfo.approval_account_children[0].contract_sn
            : "/";
        }
      case "基础交易合同编号2":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[1]
            ? accountInfo.approval_account_children[1].contract_sn
            : "/";
        }
      case "基础交易合同编号3":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[2]
            ? accountInfo.approval_account_children[2].contract_sn
            : "/";
        }
      case "基础交易合同编号4":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[3]
            ? accountInfo.approval_account_children[3].contract_sn
            : "/";
        }
      case "单据名称1":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[0]
            ? accountInfo.approval_account_children[0].bill_name
            : "/";
        }
      case "单据名称2":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[1]
            ? accountInfo.approval_account_children[1].bill_name
            : "/";
        }
      case "单据名称3":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[2]
            ? accountInfo.approval_account_children[2].bill_name
            : "/";
        }
      case "单据名称4":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[3]
            ? accountInfo.approval_account_children[3].bill_name
            : "/";
        }
      case "单据号码1":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[0]
            ? accountInfo.approval_account_children[0].bill_sn
            : "/";
        }
      case "单据号码2":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[1]
            ? accountInfo.approval_account_children[1].bill_sn
            : "/";
        }
      case "单据号码3":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[2]
            ? accountInfo.approval_account_children[2].bill_sn
            : "/";
        }
      case "单据号码4":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[3]
            ? accountInfo.approval_account_children[3].bill_sn
            : "/";
        }
      case "应收账款金额1":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[0]
            ? accountInfo.approval_account_children[0].account_total
            : "/";
        }
      case "应收账款金额2":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[1]
            ? accountInfo.approval_account_children[1].account_total
            : "/";
        }
      case "应收账款金额3":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[2]
            ? accountInfo.approval_account_children[2].account_total
            : "/";
        }
      case "应收账款金额4":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[3]
            ? accountInfo.approval_account_children[3].account_total
            : "/";
        }
      case "应收账款到期日1":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[0]
            ? accountInfo.approval_account_children[0].account_expire
            : "/";
        }
      case "应收账款到期日2":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[1]
            ? accountInfo.approval_account_children[1].account_expire
            : "/";
        }
      case "应收账款到期日3":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[2]
            ? accountInfo.approval_account_children[2].account_expire
            : "/";
        }
      case "应收账款到期日4":
        if (accountInfo.varied_sign === 0) {
          return "/";
        } else {
          return accountInfo.approval_account_children[3]
            ? accountInfo.approval_account_children[3].account_expire
            : "/";
        }
      case "应收账款金额合计":
        // if (accountInfo.varied_sign === 0) {
        //   return "/";
        // } else {
        //   return accountInfo.account_total;
        // }
        return accountInfo.account_total;
      case "公开/隐蔽型保理":
        return businessConfig.open_type === 1 ? "公开型" : "隐蔽型";
      case "有无追索权":
        return businessConfig.recovery_compensation === 1
          ? "有追索权"
          : "无追索权";
      case "无追信用额度确认方式":
        if (businessConfig.recovery_compensation === 1) {
          return "/";
        } else {
          return "";
        }
      case "保理服务内容":
        return businessConfig.factoring_content
          .split(",")
          .map((x: string) => FactoringContent[Number(x)])
          .toString();
      case "回款方式":
        return ReturnedType[businessConfig.returned_type];
      case "本期保理服务费":
        return `${toThousands(costConfig.factoring_cost)}元`;
      case "本期保理服务费支付时间":
        return moment(new Date()).format("YYYY-MM-DD");
      case "本期保理服务费支付方式":
        return "网银转账";
      case "保理专户开户名称":
        return "/";
      case "保理专户银行账号":
        return "/";
      case "保理专户开户银行":
        return "/";
      case "保理专户监管方式":
        return "/";
      case "应收账款收款账户开户名":
        return "";
      case "应收账款收款账户账号":
        return "";
      case "应收账款收款账户开户行":
        return "";
      case "争议解决机构":
        return contract.type === 2 ? "成都市高新技术产业开发区人民法院" : "/";
      case "签章期限":
        return "3";
      case "商业保理人地址":
        return "四川省成都市武侯区领事馆路7号保利中心南塔2103";
      case "商业保理人邮编":
        return "610000";
      case "商业保理人收件人":
        return "雷代雨";
      case "商业保理人收件人电话":
        return "13880381025";
      case "通知书编号":
        return JSON.parse(
          contracts.filter((x) => x.type === 2)[0].contract_content
        )["编号"]
          ? JSON.parse(
              contracts.filter((x) => x.type === 2)[0].contract_content
            )["编号"]
          : "";
      case "应收账款转让申请确认书签订日期":
        return moment(new Date()).format("YYYY-MM-DD");
      case "应收账款转让申请确认书编号":
        return contracts.filter((x) => x.type === 1).length > 0
          ? JSON.parse(
              contracts.filter((x) => x.type === 1)[0].contract_content
            )["编号"]
          : "";
      case "保理服务费金额":
        return `${toThousands(costConfig.factoring_cost)}元`;
      case "保理服务费支付方式":
        return `${FactoringCostPayType[costConfig.factoring_cost_pay_type]}`;
      case "应收账款金额合计":
        return `${toThousands(accountInfo.account_total)}元`;
      case "保理融资款金额":
        return `${toThousands(costConfig.financing_total)}元`;
      case "保理融资款比例":
        return `${costConfig.financing_proportion}`;
      case "保理融资款支付方式":
        return `${FanancingPayType[costConfig.financing_pay_type]}`;
      case "保理融资款收款账户开户名":
        return data.account_type === 1
          ? ""
          : JSON.parse(data.other_account)["开户名"];
      case "保理融资款收款账户户名":
        return data.account_type === 1
          ? ""
          : JSON.parse(data.other_account)["开户名"];
      case "保理融资款收款账户账号":
        return data.account_type === 1
          ? ""
          : JSON.parse(data.other_account)["账号"];
      case "保理融资款收款账户开户行":
        return data.account_type === 1
          ? ""
          : JSON.parse(data.other_account)["开户行"];
      case "保理融资款用途":
        return costConfig.financing_purpose === 1
          ? FanacingPurpose[costConfig.financing_purpose]
          : costConfig.financing_purpose_explain;
      case "保理融资款发放日":
        return costConfig.financing_issue_date;
      case "应收账款到期日":
        return costConfig.account_expiry_date;
      case "宽限期":
        return costConfig.have_grace === 1
          ? GraceType[costConfig.have_grace]
          : `宽限期${JSON.parse(costConfig.grace_info)["天数"]}，届满日${
              JSON.parse(costConfig.grace_info)["届满日"]
            }`;
      case "保理融资期限":
        return costConfig.financing_expriy_date;
      case "正常期利率":
        return costConfig.normal_interest_rate;
      case "正常期利息支付方式":
        return costConfig.normal_interest_pay_type === 2
          ? `分期支付，支付周期为${
              JSON.parse(costConfig.periodization_interest_rate)["周期"]
            }天，每月${
              JSON.parse(costConfig.periodization_interest_rate)["周期"]
            }日支付`
          : PayType[costConfig.normal_interest_pay_type];
      case "宽限期利率":
        return costConfig.grace_interest_rate;
      case "宽限期利息支付方式":
        return costConfig.grace_interest_pay_type === 2
          ? `分期支付，支付周期为${
              JSON.parse(costConfig.periodization_grace_interest_rate)["周期"]
            }天，每月${
              JSON.parse(costConfig.periodization_grace_interest_rate)["周期"]
            }日支付`
          : PayType[costConfig.grace_interest_pay_type];
      case "逾期利率":
        return costConfig.overdue_interest_rate;
      case "质权人":
        return "深圳前海兰青商业保理有限公司";
      case "质权人住所":
        return "四川省成都市武侯区领事馆路7号保利中心南塔2103";
      case "质权人法人":
        return "雷代雨";
      case "质权人联系方式":
        return "13880381025";
      case "合同期签订地点":
        return "成都市高新区";
      case "天月年":
        return "/";
      case "保管费金额":
        return "/";
      case "一次/分次支付管理费":
        return "/";
      case "登记限期":
        return "3";
      case "附加险名称":
        return "/";
      case "公证期限":
        return "/";
      case "公证机构":
        return "/";
      case "争议解决":
        return "1";
      case "质权人地址":
        return "四川省成都市武侯区领事馆路7号保利中心南塔2013";
      case "质权人联系电话":
        return "13880381025";
      case "质权人联系人":
        return "雷代雨";
      case "债权人":
        return "深圳前海兰青商业保理有限公司";
      case "债权人注册地":
        return "深圳市前海深港合作区南山街道桂湾片区二单元前海卓越金融中心（一期）8号楼2007B";
      case "债权人法人":
        return "雷代雨";
      case "保证人":
        return "";
      case "保证人住所":
        return "";
      case "保证人身份证号":
        return "";
      case "保证人联系方式":
        return "";
      case "合同签订地点":
        return "成都市高新区";
      case "合同签订日期":
        return moment(new Date()).format("YYYY-MM-DD");
      case "债权人地址":
        return "四川省成都市武侯区领事馆路7号保利中心南塔2103";
      case "债权人联系电话":
        return "13880381025";
      case "债权人传真":
        return "/";
      case "债权人联系人":
        return "雷代雨";
      case "保证人传真":
        return "/";
      case "商业保理人指定银行账户户名":
        return "深圳前海兰青商业保理有限公司";
      case "商业保理人指定银行账户开户行":
        return "浙江民泰商业银行成都金牛支行";
      case "商业保理人指定银行账户账号":
        return "584244223700015";
      default:
        return "";
    }
  };

  const getName = (role: any) => {
    switch (role) {
      case "应收账款债权人":
        return data.company_name;
      case "商业保理人":
        return "深圳前海兰青商业保理有限公司";
      case "质权人":
        return "深圳前海兰青商业保理有限公司";
      case "债权人":
        return "深圳前海兰青商业保理有限公司";
      default:
        return "";
    }
  };

  const getPhone = (role: any) => {
    switch (role) {
      case "商业保理人":
        return "13880381025";
      case "质权人":
        return "13880381025";
      case "债权人":
        return "13880381025";
    }
  };
  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>添加合同
        </span>
      }
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      maskClosable={false}
      width={1200}
    >
      <div>
        <Steps style={{ padding: "0 24px", marginBottom: 16 }} current={step}>
          <Steps.Step
            key={0}
            title="合同类型"
            description="选择合同类型"
          ></Steps.Step>
          <Steps.Step
            key={1}
            title="签约方信息"
            description="填写签约方信息"
          ></Steps.Step>
          <Steps.Step
            key={2}
            title="合同字段"
            description="填写合同字段"
          ></Steps.Step>
        </Steps>
        {
          <div style={{ display: step === 0 ? "block" : "none" }}>
            <Form
              layout="horizontal"
              preserve={false}
              onFinish={handleNextStep}
              form={form0}
              onValuesChange={(c_values, values) => {
                fetchTemplate(c_values["template_name"]);
              }}
            >
              {" "}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginTop: 36,
                  padding: "0 100px",
                }}
              >
                <Form.Item
                  name={"template_name"}
                  initialValue={1}
                  style={{ width: "100%" }}
                >
                  <Select style={{ width: "100%" }}>
                    <Select.Option value={1}>
                      《应收账款转让申请确认书》
                    </Select.Option>
                    <Select.Option value={2}>
                      《应收账款转让通知书》
                    </Select.Option>
                    <Select.Option value={3}>
                      《应收账款转让通知书》回执
                    </Select.Option>
                    <Select.Option value={4}>
                      《提款申请暨确认书》
                    </Select.Option>
                    <Select.Option value={5}>
                      《应收账款回款账号变更通知书》
                    </Select.Option>
                    <Select.Option value={6}>
                      《应收账款回款账号变更通知书》回执
                    </Select.Option>
                    <Select.Option value={7}>
                      《应收账款回购通知书》
                    </Select.Option>
                    <Select.Option value={8}>
                      《应收账款回购通知书》回执
                    </Select.Option>
                    <Select.Option value={9}>
                      《付款担保申请暨确认书》
                    </Select.Option>
                    <Select.Option value={21}>
                      《质押合同》（企业）
                    </Select.Option>
                    <Select.Option value={25}>
                      《质押合同》（个人）
                    </Select.Option>
                    <Select.Option value={22}>《抵押合同》</Select.Option>
                    <Select.Option value={23}>
                      《最高额保证合同》（个人）
                    </Select.Option>
                    <Select.Option value={24}>
                      《最高额保证合同》（企业）
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: 36,
                    padding: "0 100px",
                  }}
                >
                  <Button style={{ padding: "0 36px" }} onClick={handleCancel}>
                    取消
                  </Button>
                  <Button
                    type="primary"
                    htmlType={"submit"}
                    style={{ padding: "0 36px" }}
                    disabled={!template}
                  >
                    下一步
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        }
        {template && (
          <>
            {
              <div style={{ display: step === 1 ? "block" : "none" }}>
                <Form preserve={false} onFinish={handleNextStep} form={form1}>
                  {Object.keys(JSON.parse(template.template_role)).map(
                    (x, index) => {
                      return (
                        <div>
                          <Divider>
                            {JSON.parse(template.template_role)[x]}
                          </Divider>

                          <Row gutter={14}>
                            <Col span={12}>
                              <Form.Item
                                label="企业名称/姓名"
                                name={`name-${x}`}
                                rules={[
                                  {
                                    required: true,
                                    message: "请输入企业名称或姓名",
                                  },
                                ]}
                                initialValue={getName(
                                  JSON.parse(template.template_role)[x]
                                )}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label="电话"
                                name={`phone-${x}`}
                                initialValue={getPhone(
                                  JSON.parse(template.template_role)[x]
                                )}
                                rules={[
                                  {
                                    required: true,
                                    message: "请输入电话号码",
                                  },
                                  {
                                    len: 11,
                                    message: "请输入正确格式电话号码",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                  )}
                  <Form.Item>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        marginTop: 36,
                        padding: "0 100px",
                      }}
                    >
                      <Button
                        style={{ padding: "0 36px" }}
                        onClick={handlepreStep}
                      >
                        上一步
                      </Button>
                      <Button
                        type="primary"
                        htmlType={"submit"}
                        style={{ padding: "0 36px" }}
                      >
                        下一步
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            }
            {
              <div style={{ display: step === 2 ? "block" : "none" }}>
                <Form
                  layout="horizontal"
                  preserve={false}
                  onFinish={handleNextStep}
                  form={form2}
                >
                  <Row gutter={24}>
                    {transformContent(template.template_content).map((x) => (
                      <Col span={8}>
                        <Form.Item
                          label={x}
                          name={x}
                          key={x}
                          rules={[
                            {
                              required: true,
                              message: `请输入${x}`,
                            },
                          ]}
                          initialValue={initContractFieldValue(x)}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    ))}
                  </Row>
                  <Form.Item>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        marginTop: 36,
                        padding: "0 100px",
                      }}
                    >
                      <Button
                        style={{ padding: "0 36px" }}
                        onClick={() => {
                          console.log(contract);
                        }}
                      >
                        上一步
                      </Button>
                      <Button
                        type="primary"
                        htmlType={"submit"}
                        style={{ padding: "0 36px" }}
                      >
                        完成
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            }
          </>
        )}
      </div>
    </Modal>
  );
};

export default AddContractModal;
