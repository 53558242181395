import * as React from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Checkbox,
  Radio,
  InputNumber,
  message,
} from "antd";
import { CreateProduct } from "@/requests/product";
import { ServiceTypeOptions, formItemLayout } from "../configs";
interface Props {
  visible: boolean;
  setVisible: Function;
  reload: Function;
}

const CreateProductModal = (props: Props) => {
  const [fetching, setFetching] = React.useState(false);
  const { visible, setVisible, reload } = props;
  const [form] = Form.useForm();

  const doCreate = async (values: CreateProductReq) => {
    setFetching(true);
    const res = await CreateProduct({
      ...values,
      service_type: values.service_type ? values.service_type.toString() : "",
    });
    if (res.code === 0) {
      message.success("创建成功");
      reload();
      setVisible(false);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = (values: CreateProductReq) => {
    console.log(values);
    doCreate(values);
  };
  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>创建产品
        </span>
      }
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
    >
      <Form
        onFinish={handleOk}
        {...formItemLayout}
        preserve={false}
        form={form}
      >
        <Form.Item
          label="产品名称"
          name="product_name"
          rules={[{ required: true, message: "请输入产品名称" }]}
        >
          <Input placeholder="请输入产品名称" />
        </Form.Item>
        <Form.Item label="产品描述" name="product_describe">
          <Input.TextArea placeholder="请输入产品描述" />
        </Form.Item>

        <Form.Item
          label="服务类型"
          name="service_type"
          rules={[{ required: true, message: "请选择服务类型" }]}
          initialValue={[1, 2]}
        >
          <Checkbox.Group options={ServiceTypeOptions}></Checkbox.Group>
        </Form.Item>
        {/* <Form.Item label="单笔融资最小限额">
          <Form.Item name="min_quota" noStyle initialValue={0}>
            <InputNumber min={0}></InputNumber>
          </Form.Item>
          <span style={{ paddingLeft: 10 }}>元</span>
        </Form.Item>
        <Form.Item label="单笔融资最大限额">
          <Form.Item name="max_quota" noStyle initialValue={0}>
            <InputNumber min={0}></InputNumber>
          </Form.Item>
          <span style={{ paddingLeft: 10 }}>元</span>
        </Form.Item>
        <Form.Item label="最低综合利率">
          <Form.Item name="min_interest_rate" noStyle initialValue={0}>
            <InputNumber min={0} max={100}></InputNumber>
          </Form.Item>
          <span style={{ paddingLeft: 10 }}>%</span>
        </Form.Item>
        <Form.Item label="最高综合利率">
          <Form.Item name="max_interest_rate" noStyle initialValue={0}>
            <InputNumber min={0} max={100}></InputNumber>
          </Form.Item>
          <span style={{ paddingLeft: 10 }}>%</span>
        </Form.Item>
        <Form.Item label="授信总额">
          <Form.Item name="total_quota" noStyle initialValue={0}>
            <InputNumber min={0}></InputNumber>
          </Form.Item>
          <span style={{ paddingLeft: 10 }}>元</span>
        </Form.Item>
        <Form.Item label="融资期限">
          <Form.Item name="deadline" noStyle initialValue={0}>
            <InputNumber min={0}></InputNumber>
          </Form.Item>
          <span style={{ paddingLeft: 10 }}>天</span>
        </Form.Item> */}

        <Form.Item
          label="是否有追索权"
          name="right_of_recourse"
          initialValue={2}
        >
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
            <Radio value={2}>不限</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="保理类型" name="open_type" initialValue={2}>
          <Radio.Group>
            <Radio value={1}>公开</Radio>
            <Radio value={0}>隐蔽</Radio>
            <Radio value={2}>不限</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="是否需要质押" name="pledge_need" initialValue={2}>
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
            <Radio value={2}>不限</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="是否需要抵押" name="mortgage_need" initialValue={2}>
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
            <Radio value={2}>不限</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="是否需要保证"
          name="guarantee_contract_sign"
          initialValue={2}
        >
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
            <Radio value={2}>不限</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="是否启用" name="public_sign" initialValue={1}>
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item noStyle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              margin: "36px 0 24px 0",
              padding: "0 100px",
            }}
          >
            <Button
              type="primary"
              htmlType={"submit"}
              style={{ padding: "0 36px" }}
              loading={fetching}
            >
              确认
            </Button>
            <Button onClick={handleCancel} style={{ padding: "0 36px" }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateProductModal;
