import * as React from "react";
import { getContractApplyList } from "@/requests/contract";
import { Input, Button, Form, List, DatePicker } from "antd";
import { RedoOutlined, SearchOutlined } from "@ant-design/icons";
import "../styles/list.less";
import { useHistory } from "react-router";
import moment from "moment";
enum ContractApprovalStatus {
  "待业务员审核" = 0,
  "待业务主管审核" = 1,
  "业务员审核失败，申请已关闭" = 2,
  "待风控人员审核" = 3,
  "待业务员重新审核" = 4,
  "待总经理审核" = 5,
  "待风控主管重新审核" = 6,
  "待风控主管审核" = 7,
  "待业务主管重新审核" = 8,
  "待财务人员审核" = 9,
  "待风控人员重新审核" = 10,
  "审批通过" = 11,
  "待财务人员重新审核" = 12,
}

const ContractTypes = {
  submit_contract_approval: "综合授信合同",
};

const ContractApplyList = () => {
  const [data, setData] = React.useState<ContractApplyListItem[]>([]);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [filterName, setFilterName] = React.useState("");
  const [filterStatus, setFilterStatus] = React.useState(11);
  const [filterTime, setFilterTime] = React.useState<string[]>([]);
  const history = useHistory();

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    const res = await getContractApplyList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
      ...(filterName && {
        company_name: filterName,
      }),
      ...(filterStatus &&
        filterStatus !== 1 && {
          status: filterStatus,
        }),
      ...(filterTime && {
        begin_time: filterTime[0],
        end_time: filterTime[1],
      }),
    });
    if (res.code === 0) {
      setData(res.data);
      setPagination({
        ...pagination,
        page: page,
        pageSize: pageSize,
        total: res.total,
      });
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="contract-list-page">
      <div className="tools-wrapper">
        <Form colon={false} layout="inline">
          <Form.Item label="企业名称" className="filter-item">
            <Input
              style={{ maxWidth: 200 }}
              placeholder="全部"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
            ></Input>
          </Form.Item>

          <Form.Item label="申请时间" className="filter-item">
            <DatePicker.RangePicker
              onChange={(value, dateString) => setFilterTime(dateString)}
              style={{ minWidth: 200 }}
              showTime
              value={
                filterTime.length > 1
                  ? [moment(filterTime[0]), moment(filterTime[1])]
                  : undefined
              }
            />
          </Form.Item>
        </Form>

        <div style={{ display: "flex" }}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            style={{ marginRight: 10 }}
            onClick={() => fetchData()}
          >
            搜索
          </Button>
          <Button
            icon={<RedoOutlined />}
            onClick={() => {
              setFilterName("");
              setFilterTime([]);
              setFilterStatus(11);
            }}
          >
            重置
          </Button>
        </div>
      </div>
      <div style={{ backgroundColor: "#ffffff", padding: 16, marginTop: 16 }}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => {
            return (
              <List.Item
                actions={[
                  <a
                    key="view"
                    onClick={() =>
                      history.push({
                        pathname: `/workbench/contract/contract-approval/detail`,
                        state: {
                          id: item.id,
                        },
                        search: `id=${item.id}`,
                      })
                    }
                  >
                    查看详情
                  </a>,
                ]}
              >
                <List.Item.Meta
                  title={
                    item.process_instance_name
                      ? item.process_instance_name.split(":")[2].split(" ")[0]
                      : "未知"
                  }
                  description={
                    <div style={{ display: "flex" }}>
                      <span>{`审核状态：${
                        ContractApprovalStatus[item.approval_status]
                      }`}</span>
                      <span
                        style={{ padding: "0 16px" }}
                      >{`申请时间：${item.create_time}`}</span>
                    </div>
                  }
                ></List.Item.Meta>
              </List.Item>
            );
          }}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
          }}
        ></List>
      </div>
    </div>
  );
};

export default ContractApplyList;
