import * as React from "react";
import { List, Avatar } from "antd";

interface PropsType {
  data: OperationLog;
}

const ApprovalLogDisplayer = (props: PropsType) => {
  const { data } = props;

  if (!data) return null;
  return (
    <>
      <List.Item.Meta
        avatar={
          <Avatar style={{ backgroundColor: "#c37878" }}>
            {data.operation_user_name.slice(0, 1)}
          </Avatar>
        }
        title={
          <a onClick={(e) => e.preventDefault()}>{data.operation_user_name}</a>
        }
        description={
          <>
            <span style={{ paddingRight: 16 }}>
              审批结果：{data.operation_result}
            </span>
            <span style={{ paddingRight: 16 }}>审批意见：{data.comment}</span>
            <span style={{ paddingRight: 16 }}>
              审批时间：{data.create_time}
            </span>
          </>
        }
      ></List.Item.Meta>
    </>
  );
};

export default ApprovalLogDisplayer;
