import * as React from "react";
import {
  Button,
  Modal,
  Steps,
  Form,
  Radio,
  Input,
  InputNumber,
  message,
  Select,
  Row,
  Col,
  DatePicker,
  Divider,
  Checkbox,
  Table,
  Menu,
  Dropdown,
  Empty,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { businessSalesmanApproval } from "@/requests/business";
import moment from "moment";
import ContractContentWriteModal from "./ContractContentWriteModal";
import AddContractModal from "./AddContractModal";

enum TemplateType {
  "默认" = 0,
  "应收账款转让申请确认书" = 1,
  "应收账款转让通知书" = 2,
  "《应收账款转让通知书》回执" = 3,
  "提款申请暨确认书" = 4,
  "应收账款回款账号变更通知书" = 5,
  "《应收账款回款账号变更通知书》回执" = 6,
  "应收账款回购通知书" = 7,
  "《应收账款回购通知书》回执" = 8,
  "付款担保申请暨确认书" = 9,
  "质押合同（企业）" = 21,
  "最高额抵押合同" = 22,
  "最高额保证合同（个人）" = 23,
  "最高额保证合同（企业）" = 24,
  "质押合同（个人）" = 25,
}

interface PropsType {
  visible: boolean;
  setVisible: Function;
  fetchData: Function;
  data: BusinessApplyItem;
}

export interface ContractList extends BusinessContractListItem {
  type: number;
  id: string;
}

const SMApprovalModal: React.FC<PropsType> = (props: PropsType) => {
  const { visible, setVisible, fetchData, data } = props;
  const [current, setCurrent] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();
  const [form5] = Form.useForm();

  //审核结果
  const [approvalRes, setApprovalRes] = React.useState<any>();
  //应收账款信息
  const [accountInfo, setAccountInfo] =
    React.useState<BusinessApprovalAccount>();
  //业务配置信息
  const [businessConfig, setBusinessConfig] =
    React.useState<BusinessApprovalConfig>();
  //费用配置信息
  const [costConfig, setCostConfig] = React.useState<BusinessCostConfig>();
  //合同列表
  const [contractList, setContractlist] = React.useState<ContractList[]>([]);
  const [isPass, setIsPass] = React.useState(0);
  const [isMuti, setIsMuti] = React.useState(0);
  const [mutiNum, setMutiNum] = React.useState(2);
  const [writeVisible, setWriteVisible] = React.useState(false);
  const [writingContract, setWritingContract] = React.useState<
    ContractList | undefined
  >();
  const [addContractVisible, setAddContractVisible] = React.useState(false);

  const handleCancel = () => {
    setCurrent(0);
    setIsMuti(0);
    setMutiNum(2);
    setVisible(false);
  };

  const doApproval = async (input: BusinessSalesmanApprovlReq) => {
    setLoading(true);
    const res = await businessSalesmanApproval(input);
    if (res.code === 0) {
      message.success("提交成功");
      fetchData();
      setVisible(false);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const handleNextStep = (values: any) => {
    if (current === 4) {
      if (!costConfig) return;
      if (!approvalRes) return;
      if (!businessConfig) return;
      if (!accountInfo) return;
      if (!contractList) return;
      if (contractList.find((x) => !x.contract_content || !x.recipient)) {
        message.error("还有未填写的合同字段，请完成填写");
        return;
      }
      doApproval({
        comment: approvalRes.comment,
        approval: approvalRes.approval,
        cost_config: costConfig,
        approval_material: approvalRes,
        approval_config: businessConfig,
        approval_account: accountInfo,
        credit_voucher: values["credit_voucher"].toString(),
        contract_list: contractList,
        task_id: data.task_id,
        apply_id: data.id,
      });
      console.log(values);
    } else if (current === 0) {
      //暂存第一步表单状态
      if (values.approval === 0) {
        doApproval({
          ...values,
          task_id: data.task_id,
        });
      } else {
        setApprovalRes(values);
        setCurrent(current + 1);
      }
    } else if (current === 1) {
      //暂存第二步表单状态
      setAccountInfo({
        account_total: values["account_total"],
        described:
          values["varied_sign"] === 1
            ? ""
            : `应收账款债务人：${values["obligor_name"]}，合同编号：${
                values["contract_sn"]
              }，合同金额：${values["contract_amount"]}，到期日：${moment(
                values[`account_expire`]
              ).format("YYYY-MM-DD")}`,
        obligor_name: values["obligor_name"],
        obligor_phone: values["obligor_phone"],
        varied_sign: values["varied_sign"],
        approval_account_children: values["varied_sign"]
          ? Array(mutiNum)
              .fill(undefined)
              .map((x, index) => {
                return {
                  contract_sn: values[`contract_sn${index + 1}`],
                  bill_name: values[`bill_name${index + 1}`],
                  bill_sn: values[`bill_sn${index + 1}`],
                  account_total: values[`account_total${index + 1}`],
                  account_expire: moment(
                    values[`account_expire${index + 1}`]
                  ).format("YYYY-MM-DD"),
                };
              })
          : [],
      });

      setCurrent(current + 1);
    } else if (current === 2) {
      //暂存第三步表单状态
      setBusinessConfig({
        ...values,
        factoring_content: values["factoring_content"].toString(),
      });
      setCurrent(current + 1);
    } else if (current === 3) {
      //暂存第四步表单状态
      setCostConfig({
        factoring_cost: values["factoring_cost"],
        factoring_cost_pay_type: values["factoring_cost_pay_type"],
        financing_account_type: values["financing_account_type"],
        financing_pay_type: values["financing_pay_type"],
        financing_proportion: values["financing_proportion"],
        financing_purpose: values["financing_purpose"],
        financing_purpose_explain: values["financing_purpose_explain"],
        financing_total: values["financing_total"],
        grace_interest_pay_type: values["grace_interest_pay_type"],
        grace_interest_rate: values["grace_interest_rate"],
        have_grace: values["have_grace"],
        normal_interest_pay_type: values["normal_interest_pay_type"],
        normal_interest_rate: values["normal_interest_rate"],
        other_financing_pay_type: values["other_financing_pay_type"],
        other_normal_interest_pay_type:
          values["other_normal_interest_pay_type"],
        other_pay_type: values["other_pay_type"],
        overdue_interest_rate: values["overdue_interest_rate"],
        periodization_interest_pay_type:
          values["periodization_interest_pay_type"],

        account_expiry_date: moment(values["account_expiry_date"]).format(
          "YYYY-MM-DD"
        ),
        financing_expriy_date: moment(values["financing_expriy_date"]).format(
          "YYYY-MM-DD"
        ),
        financing_issue_date: moment(values["financing_issue_date"]).format(
          "YYYY-MM-DD"
        ),
        grace_info: JSON.stringify({
          天数: values["grace_info"],
          届满日: moment(values["宽限期届满日"]).format("YYYY-MM-DD"),
        }),
        other_financing_account: JSON.stringify({
          户名: values["其他收款账户开户名"],
          账号: values["其他收款账户账号"],
          开户行: values["其他收款账户开户行"],
        }),
        periodization_grace_interest_rate: JSON.stringify({
          周期: values["分期支付宽限期利息周期"],
          支付日: values["分期支付宽限期利息支付日"],
        }),
        periodization_interest_rate: JSON.stringify({
          周期: values["分期支付正常期利息周期"],
          支付日: values["分期支付正常期利息支付日"],
        }),
      });
      setCurrent(current + 1);
    } else {
    }
  };

  const handlePreStep = () => {
    if (current === 0) {
      return;
    } else {
      setCurrent(current - 1);
    }
  };

  const handleAdd = () => {
    if (mutiNum > 3) {
      message.error("最多添加四项");
      return;
    }
    setMutiNum(mutiNum + 1);
  };

  const handleRemove = (contract: ContractList) => {
    Modal.confirm({
      title: "提示",
      content: "确认删除该合同吗？",
      okText: "确认",
      onOk: () =>
        setContractlist(contractList.filter((x) => x.id !== contract.id)),
      cancelText: "取消",
      onCancel: () => {},
    });
  };

  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>业务审核
        </span>
      }
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      width={1200}
      maskClosable={false}
    >
      <div style={{ width: "100%" }}>
        <Steps current={current} size="small" style={{ margin: "16px 0" }}>
          <Steps.Step title="审核结果"></Steps.Step>
          <Steps.Step title="应收账款信息"></Steps.Step>
          <Steps.Step title="业务配置"></Steps.Step>
          <Steps.Step title="费用配置"></Steps.Step>
          <Steps.Step title="合同配置"></Steps.Step>
        </Steps>
        <div
          style={{
            display: current === 0 ? "flex" : "none",
            justifyContent: "center",
          }}
        >
          <Form
            form={form1}
            preserve={false}
            onFinish={handleNextStep}
            style={{ width: 600 }}
          >
            <Form.Item
              label="审批结果"
              name="approval"
              rules={[{ required: true, message: "请选择审批结果" }]}
              initialValue={isPass}
            >
              <Radio.Group onChange={(e) => setIsPass(e.target.value)}>
                <Radio value={1}>通过</Radio>
                <Radio value={0}>不通过</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="审批意见"
              name="comment"
              rules={[{ required: true, message: "请输入审批意见" }]}
            >
              <Input.TextArea placeholder="请输入审批意见"></Input.TextArea>
            </Form.Item>
            {isPass === 1 && (
              <>
                <Form.Item
                  label="合同审核结果"
                  name="contract_result"
                  rules={[{ required: true, message: "请选择合同审核结果" }]}
                  initialValue={1}
                >
                  <Radio.Group>
                    <Radio value={1}>通过</Radio>
                    <Radio value={0}>不通过</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="发票审核结果"
                  name="invoice_result"
                  rules={[{ required: true, message: "请选择发票审核结果" }]}
                  initialValue={1}
                >
                  <Radio.Group>
                    <Radio value={1}>通过</Radio>
                    <Radio value={0}>不通过</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label="其他资料审核结果"
                  name="other_result"
                  rules={[
                    { required: true, message: "请选择其他资料审核结果" },
                  ]}
                  initialValue={1}
                >
                  <Radio.Group>
                    <Radio value={1}>通过</Radio>
                    <Radio value={0}>不通过</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item label="发票数量">
                  <Form.Item
                    noStyle
                    name="invoice_number"
                    rules={[{ required: true, message: "请输入发票数量" }]}
                    initialValue={data.invoice_number}
                  >
                    <InputNumber />
                  </Form.Item>
                  <span style={{ paddingLeft: 8 }}>张</span>
                </Form.Item>
                <Form.Item label="发票金额">
                  <Form.Item
                    noStyle
                    name="invoice_total"
                    rules={[{ required: true, message: "请输入发票总金额" }]}
                    initialValue={data.invoice_total}
                  >
                    <InputNumber />
                  </Form.Item>
                  <span style={{ paddingLeft: 8 }}>元</span>
                </Form.Item>

                <Form.Item
                  label="备注"
                  name="memo"
                  rules={[
                    { required: true, message: "请选择其他资料审核结果" },
                  ]}
                  initialValue={"无"}
                >
                  <Input.TextArea />
                </Form.Item>
              </>
            )}
            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 36,
                  padding: "0 100px",
                }}
              >
                <Button
                  style={{ padding: "0 36px" }}
                  loading={loading}
                  onClick={handleCancel}
                >
                  取消
                </Button>
                <Button
                  type="primary"
                  htmlType={"submit"}
                  style={{ padding: "0 36px" }}
                >
                  下一步
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <div
          style={{
            display: current === 1 ? "flex" : "none",
            justifyContent: "center",
          }}
        >
          <Form
            form={form2}
            preserve={false}
            onFinish={handleNextStep}
            style={{ width: 600 }}
            onValuesChange={(changeValues, values) => {
              if (Object.keys(changeValues)[0] === "account_expire") {
                form4.setFieldsValue({
                  account_expiry_date: changeValues["account_expire"],
                });
                form4.setFieldsValue({
                  宽限期届满日: changeValues["account_expire"],
                });
              }
              if (Object.keys(changeValues)[0] === "account_total") {
                form4.setFieldsValue({
                  financing_proportion: Number(
                    (
                      (data.quota / changeValues["account_total"]) *
                      100
                    ).toFixed(2)
                  ),
                });
              }
            }}
          >
            <Form.Item
              label="应收账款总额"
              name="account_total"
              rules={[{ required: true, message: "请输入应收账款总额" }]}
            >
              <InputNumber
                placeholder="单位：元"
                style={{ width: "100%" }}
              ></InputNumber>
            </Form.Item>
            <Form.Item
              label="应收账款债务人"
              name="obligor_name"
              rules={[{ required: true, message: "请输入应收账款债务人" }]}
              initialValue={data.debtor}
            >
              <Input placeholder="债务人名称"></Input>
            </Form.Item>
            <Form.Item
              label="应收账款债务人电话"
              name="obligor_phone"
              rules={[{ required: true, message: "请输入应收账款债务人电话" }]}
            >
              <Input placeholder="债务人电话"></Input>
            </Form.Item>
            <Form.Item
              label="是否多项"
              name="varied_sign"
              rules={[{ required: true, message: "请选择应收账款数量" }]}
              initialValue={0}
            >
              <Radio.Group onChange={(e) => setIsMuti(e.target.value)}>
                <Radio value={1}>是</Radio>
                <Radio value={0}>否</Radio>
              </Radio.Group>
            </Form.Item>
            {isMuti === 0 && (
              <>
                <Form.Item
                  name={"contract_sn"}
                  label="合同编号"
                  rules={[
                    {
                      required: true,
                      message: "请输入基础交易合同编号",
                    },
                  ]}
                >
                  <Input placeholder="基础交易合同编号" />
                </Form.Item>

                <Form.Item
                  name={"contract_amount"}
                  label="合同金额"
                  rules={[{ required: true, message: "请输入合同金额" }]}
                >
                  <Input placeholder="合同金额" />
                </Form.Item>

                <Form.Item
                  name={"account_expire"}
                  label="到期日"
                  rules={[{ required: true, message: "请输入到期日" }]}
                >
                  <DatePicker placeholder="到期日" style={{ width: "100%" }} />
                </Form.Item>
              </>
            )}
            {isMuti === 1 && (
              <>
                {Array(mutiNum)
                  .fill(undefined)
                  .map((x, index) => (
                    <Form.Item
                      label={`应收账款${index + 1}`}
                      key={`应收账款${index + 1}`}
                    >
                      <Form.Item
                        name={"contract_sn" + (index + 1)}
                        rules={[
                          {
                            required: true,
                            message: "请输入基础交易合同及编号",
                          },
                        ]}
                      >
                        <Input placeholder="基础交易合同及编号" />
                      </Form.Item>
                      <Form.Item
                        name={"bill_name" + (index + 1)}
                        rules={[{ required: true, message: "请输入单据名称" }]}
                      >
                        <Input placeholder="单据名称" />
                      </Form.Item>
                      <Form.Item
                        name={"bill_sn" + (index + 1)}
                        rules={[{ required: true, message: "请输入单据号码" }]}
                      >
                        <Input placeholder="单据号码" />
                      </Form.Item>
                      <Form.Item
                        name={"account_total" + (index + 1)}
                        rules={[{ required: true, message: "请输入金额" }]}
                      >
                        <Input placeholder="金额" />
                      </Form.Item>
                      <Form.Item
                        name={"account_expire" + (index + 1)}
                        rules={[{ required: true, message: "请输入到期日" }]}
                      >
                        <DatePicker
                          placeholder="到期日"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Form.Item>
                  ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={handleAdd}
                    style={{ width: "100%" }}
                    icon={<PlusOutlined />}
                  >
                    添加
                  </Button>
                </Form.Item>
              </>
            )}

            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 36,
                  padding: "0 100px",
                }}
              >
                <Button
                  style={{ padding: "0 36px" }}
                  loading={loading}
                  onClick={handlePreStep}
                >
                  上一步
                </Button>
                <Button
                  type="primary"
                  htmlType={"submit"}
                  style={{ padding: "0 36px" }}
                >
                  下一步
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <div
          style={{
            display: current === 2 ? "flex" : "none",
            justifyContent: "center",
          }}
        >
          <Form
            form={form3}
            preserve={false}
            onFinish={handleNextStep}
            style={{ width: 600 }}
          >
            <Form.Item
              label="保理服务内容"
              name="factoring_content"
              initialValue={[1, 2]}
              rules={[{ required: true, message: "请选择保理服务内容" }]}
            >
              <Select mode="multiple" placeholder="保理服务内容">
                <Select.Option value={1}>融资</Select.Option>
                <Select.Option value={2}>管理</Select.Option>
                <Select.Option value={3}>催收</Select.Option>
                <Select.Option value={4}>付款担保</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="公开/隐蔽型保理"
              name="open_type"
              rules={[{ required: true, message: "请选择公开类型" }]}
              initialValue={1}
            >
              <Radio.Group>
                <Radio value={1}>公开型</Radio>
                <Radio value={2}>隐蔽型</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="是否有追索权"
              name="recovery_compensation"
              rules={[{ required: true, message: "请选择追索权类型" }]}
              initialValue={1}
            >
              <Radio.Group>
                <Radio value={1}>是</Radio>
                <Radio value={2}>否</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="回款方式"
              name="returned_type"
              initialValue={2}
              rules={[{ required: true, message: "请选择回款方式" }]}
            >
              <Select placeholder="回款方式">
                <Select.Option value={1}>直接回款</Select.Option>
                <Select.Option value={2}>间接回款</Select.Option>
                <Select.Option value={3}>其他</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="其他回款方式"
              name="other_returned_type"
              initialValue={"无"}
            >
              <Input placeholder="其他回款方式" />
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 36,
                  padding: "0 100px",
                }}
              >
                <Button
                  style={{ padding: "0 36px" }}
                  loading={loading}
                  onClick={handlePreStep}
                >
                  上一步
                </Button>
                <Button
                  type="primary"
                  htmlType={"submit"}
                  style={{ padding: "0 36px" }}
                >
                  下一步
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <div style={{ display: current === 3 ? "block" : "none" }}>
          <Form
            form={form4}
            preserve={false}
            onFinish={handleNextStep}
            onValuesChange={(changeValues) => {
              if (Object.keys(changeValues)[0] === "financing_total") {
                if (!accountInfo) return;

                form4.setFieldsValue({
                  financing_proportion: Number(
                    (
                      (changeValues["financing_total"] /
                        accountInfo.account_total) *
                      100
                    ).toFixed(2)
                  ),
                });
              }
            }}
          >
            <Divider>保理服务费</Divider>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label="保理服务费金额"
                  name="factoring_cost"
                  rules={[{ required: true, message: "请输入保理服务费金额" }]}
                >
                  <InputNumber
                    placeholder="单位：元"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="保理服务费支付方式"
                  name="factoring_cost_pay_type"
                  rules={[
                    { required: true, message: "请选择保理服务费支付方式" },
                  ]}
                  initialValue={1}
                >
                  <Select placeholder="保理服务费支付方式">
                    <Select.Option value={1}>期初一次性</Select.Option>
                    <Select.Option value={2}>到期一次性</Select.Option>
                    <Select.Option value={3}>其他</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="其他保理服务费支付方式"
                  name="other_pay_type"
                  rules={[
                    { required: true, message: "请输入其他保理服务费支付方式" },
                  ]}
                  initialValue={"无"}
                >
                  <Input placeholder="其他保理服务费支付方式" />
                </Form.Item>
              </Col>
            </Row>
            <Divider>保理融资款</Divider>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label="保理融资款金额"
                  name="financing_total"
                  rules={[{ required: true, message: "请输入保理融资款金额" }]}
                  initialValue={data.quota}
                >
                  <InputNumber
                    placeholder="单位：元"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="financing_proportion"
                  label="保理融资贷款比例"
                  rules={[
                    { required: true, message: "请输入保理融资款贷款比例" },
                  ]}
                >
                  <InputNumber
                    placeholder="单位：%"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="保理融资款支付方式"
                  name="financing_pay_type"
                  rules={[
                    { required: true, message: "请选择保理融资款支付方式" },
                  ]}
                  initialValue={1}
                >
                  <Select placeholder="保理融资款支付方式">
                    <Select.Option value={1}>同授信合同</Select.Option>
                    <Select.Option value={2}>其他</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label="其他保理融资款支付方式"
                  name="other_financing_pay_type"
                  rules={[
                    { required: true, message: "请输入其他保理融资款支付方式" },
                  ]}
                  initialValue={"无"}
                >
                  <Input placeholder="其他支付方式" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="保理融资款收款账户"
                  name="financing_account_type"
                  rules={[
                    { required: true, message: "请选择保理融资款收款账户" },
                  ]}
                  initialValue={data.account_type === 1 ? 1 : 2}
                >
                  <Select placeholder="保理融资款收款账户">
                    <Select.Option value={1}>同授信合同</Select.Option>
                    <Select.Option value={2}>其他</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="其他收款账户账号"
                  name="其他收款账户账号"
                  rules={[
                    { required: true, message: "请输入其他保理融资款支付方式" },
                  ]}
                  initialValue={
                    data.other_account
                      ? JSON.parse(data.other_account)["账号"]
                      : "无"
                  }
                >
                  <Input placeholder="账号" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label="其他收款账户开户名"
                  name="其他收款账户开户名"
                  rules={[
                    { required: true, message: "请选择保理融资款收款账户" },
                  ]}
                  initialValue={
                    data.other_account
                      ? JSON.parse(data.other_account)["账户名"]
                      : "无"
                  }
                >
                  <Input placeholder="开户名" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="其他收款账户开户行"
                  name="其他收款账户开户行"
                  rules={[
                    { required: true, message: "请输入其他保理融资款收款账户" },
                  ]}
                  initialValue={
                    data.other_account
                      ? JSON.parse(data.other_account)["开户行"]
                      : "无"
                  }
                >
                  <Input placeholder="开户行" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="保理融资款用途"
                  name="financing_purpose"
                  rules={[{ required: true, message: "请选择保理融资款用途" }]}
                  initialValue={1}
                >
                  <Select placeholder="保理服务费支付方式">
                    <Select.Option value={1}>不限定用途</Select.Option>
                    <Select.Option value={2}>限定用途</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={16}>
                <Form.Item
                  label="限定保理融资款贷款用途"
                  name="financing_purpose_explain"
                  rules={[
                    { required: true, message: "请输入限定保理融资款抵款用途" },
                  ]}
                  initialValue={"无"}
                >
                  <Input placeholder="限定用途" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="保理融资款发放日"
                  name="financing_issue_date"
                  rules={[
                    { required: true, message: "请选择保理融资款发放日" },
                  ]}
                  initialValue={moment(new Date())}
                >
                  <DatePicker
                    placeholder="选择日期"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider>利息及其他</Divider>
            <Row gutter={24}>
              {accountInfo && (
                <Col span={8}>
                  <Form.Item
                    label="应收账款到期日"
                    name="account_expiry_date"
                    rules={[
                      { required: true, message: "请选择应收账款到期日" },
                    ]}
                  >
                    <DatePicker
                      placeholder="选择日期"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col span={8}>
                <Form.Item
                  label="宽限期"
                  name="have_grace"
                  rules={[{ required: true, message: "请选择宽限期" }]}
                  initialValue={2}
                >
                  <Select placeholder="请选择宽限期">
                    <Select.Option value={1}>没有宽限期</Select.Option>
                    <Select.Option value={2}>有宽限期</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="宽限期天数"
                  name="grace_info"
                  rules={[{ required: true, message: "请输入宽限期信息" }]}
                  initialValue={30}
                >
                  <InputNumber
                    placeholder="单位：天"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label="宽限期届满日"
                  name="宽限期届满日"
                  rules={[{ required: true, message: "请选择宽限期届满日" }]}
                >
                  <DatePicker
                    placeholder="选择日期"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="保理融资期限"
                  name="financing_expriy_date"
                  rules={[{ required: true, message: "请选择保理融资期限" }]}
                >
                  <DatePicker
                    placeholder="选择日期"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="正常期利率"
                  name="normal_interest_rate"
                  rules={[{ required: true, message: "请输入正常期利率" }]}
                  initialValue={6}
                >
                  <InputNumber
                    placeholder="单位：%"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label="正常期利息支付方式"
                  name="normal_interest_pay_type"
                  rules={[
                    { required: true, message: "请选择正常期利息支付方式" },
                  ]}
                  initialValue={1}
                >
                  <Select placeholder="正常期利息支付方式">
                    <Select.Option value={1}>期初一次性支付</Select.Option>
                    <Select.Option value={2}>分期支付</Select.Option>
                    <Select.Option value={3}>到期一次性支付</Select.Option>
                    <Select.Option value={4}>其他</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="分期支付正常期利息周期"
                  name="分期支付正常期利息周期"
                  rules={[
                    { required: true, message: "请输入分期支付正常期利息周期" },
                  ]}
                  initialValue={30}
                >
                  <InputNumber
                    placeholder="单位：天"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="分期支付正常期利息支付日(每月)"
                  name="分期支付正常期利息支付日"
                  rules={[
                    {
                      required: true,
                      message: "请输入分期支付正常期利息支付日",
                    },
                  ]}
                  initialValue={1}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label="其他正常期利息支付方式"
                  name="other_normal_interest_pay_type"
                  rules={[
                    { required: true, message: "请输入其他正常期利息支付方式" },
                  ]}
                  initialValue="无"
                >
                  <Input placeholder="其他支付方式" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="宽限期利率"
                  name="grace_interest_rate"
                  rules={[{ required: true, message: "请输入宽限期利率" }]}
                  initialValue={6}
                >
                  <InputNumber
                    placeholder="单位：%"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="宽限期利息支付方式"
                  name="grace_interest_pay_type"
                  rules={[
                    { required: true, message: "请选择宽限期利息支付方式" },
                  ]}
                  initialValue={3}
                >
                  <Select placeholder="宽限期利息支付方式">
                    <Select.Option value={1}>期初一次性支付</Select.Option>
                    <Select.Option value={2}>分期支付</Select.Option>
                    <Select.Option value={3}>到期一次性支付</Select.Option>
                    <Select.Option value={4}>其他</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label="分期支付宽限期利息周期"
                  name="分期支付宽限期利息周期"
                  rules={[
                    { required: true, message: "请输入分期支付宽限期利息周期" },
                  ]}
                  initialValue={30}
                >
                  <InputNumber
                    placeholder="单位：天"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="分期支付宽限期利息支付日(每月)"
                  name="分期支付宽限期利息支付日"
                  rules={[
                    {
                      required: true,
                      message: "请输入分期支付宽限期利息支付日",
                    },
                  ]}
                  initialValue={1}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="其他宽限期利息支付方式"
                  name="periodization_interest_pay_type"
                  rules={[
                    { required: true, message: "请输入其他宽限期利息支付方式" },
                  ]}
                  initialValue="无"
                >
                  <Input placeholder="其他支付方式" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label="逾期利率"
                  name="overdue_interest_rate"
                  rules={[{ required: true, message: "请输入逾期利率" }]}
                  initialValue={24}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="单位：%"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 36,
                  padding: "0 100px",
                }}
              >
                <Button
                  style={{ padding: "0 36px" }}
                  loading={loading}
                  onClick={handlePreStep}
                >
                  上一步
                </Button>
                <Button
                  type="primary"
                  htmlType={"submit"}
                  style={{ padding: "0 36px" }}
                >
                  下一步
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <div style={{ display: current === 4 ? "block" : "none" }}>
          {writingContract && (
            <ContractContentWriteModal
              visible={writeVisible}
              setVisible={setWriteVisible}
              contracts={contractList}
              setContracts={setContractlist}
              contract={writingContract}
              data={data}
              costConfig={costConfig}
              businessConfig={businessConfig}
              accountInfo={accountInfo}
            />
          )}
          {addContractVisible && (
            <AddContractModal
              visible={addContractVisible}
              setVisible={setAddContractVisible}
              contracts={contractList}
              setContracts={setContractlist}
              data={data}
              costConfig={costConfig}
              businessConfig={businessConfig}
              accountInfo={accountInfo}
            />
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 16,
            }}
          >
            <Button
              icon={<PlusOutlined />}
              onClick={() => setAddContractVisible(true)}
            >
              添加合同
            </Button>
          </div>
          <Table
            dataSource={contractList}
            pagination={false}
            locale={{
              emptyText: <Empty description="点击上方添加合同"></Empty>,
            }}
            columns={[
              {
                title: "合同名称",
                key: "contract_name",
                render: (value, record, index) =>
                  `《${TemplateType[record.type]}》`,
                align: "center",
              },
              {
                title: "签署方",
                key: "reciept",
                render: (value, record, index) =>
                  JSON.parse(record.recipient)
                    .map((x: any) => x.name)
                    .toString(),
                align: "center",
              },
              {
                title: "操作",
                key: "actions",
                align: "center",
                render: (value, record, index) => (
                  <>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        setWritingContract(record);
                        setWriteVisible(true);
                      }}
                    >
                      修改
                    </a>
                    <Divider type="vertical"></Divider>
                    <a
                      style={{ color: "red" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleRemove(record);
                      }}
                    >
                      删除
                    </a>
                  </>
                ),
              },
            ]}
          />
          <Form form={form5} preserve={false} onFinish={handleNextStep}>
            <Divider>增信措施</Divider>
            <Form.Item
              name="credit_voucher"
              label="增信措施"
              rules={[{ required: true, message: "请选择增信措施" }]}
            >
              <Select mode="multiple">
                <Select.Option value={0}>无增信措施</Select.Option>
                <Select.Option value={1}>自身不动产抵押</Select.Option>
                <Select.Option value={2}>第三方不动产抵押</Select.Option>
                <Select.Option value={3}>自身动产质押</Select.Option>
                <Select.Option value={4}>第三方动产质押</Select.Option>
                <Select.Option value={5}>第三方担保（个人）</Select.Option>
                <Select.Option value={6}>第三方担保（企业）</Select.Option>
              </Select>
            </Form.Item>
            {/* <Form.Item label="增信合同" name="extra_contracts">
              <Checkbox.Group style={{ width: "100%" }} disabled>
                <Row>
                  <Col span={6}>
                    <Checkbox value={21}>质押合同</Checkbox>
                    <ContractContentWirteWidget
                      contract={contractList.find((x, index) => x.type === 21)}
                    />
                  </Col>
                  <Col span={6}>
                    <Checkbox value={22}>最高额抵押合同</Checkbox>
                    <ContractContentWirteWidget
                      contract={contractList.find((x, index) => x.type === 22)}
                    />
                  </Col>
                  <Col span={6}>
                    <Checkbox value={23}>最高额保证合同（个人）</Checkbox>
                    <ContractContentWirteWidget
                      contract={contractList.find((x, index) => x.type === 23)}
                    />
                  </Col>
                  <Col span={6}>
                    <Checkbox value={24}>最高额保证合同（企业）</Checkbox>
                    <ContractContentWirteWidget
                      contract={contractList.find((x, index) => x.type === 24)}
                    />
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item> */}
            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 36,
                  padding: "0 100px",
                }}
              >
                <Button
                  style={{ padding: "0 36px" }}
                  loading={loading}
                  onClick={handlePreStep}
                >
                  上一步
                </Button>
                <Button
                  type="primary"
                  htmlType={"submit"}
                  style={{ padding: "0 36px" }}
                >
                  完成
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default SMApprovalModal;
