import * as React from "react";
import { List, Avatar, Descriptions } from "antd";
import { getApprovalContentById } from "@/requests/credit";
import Modal from "antd/lib/modal/Modal";
import FileViewer from "../FileViewer";
interface PropsType {
  data: OperationLog;
}
enum ProductType {
  "全部" = 0,
}

enum OthersFactoring {
  "无" = 0,
}

const CreditApprovalLogDisplayer = (props: PropsType) => {
  const { data } = props;
  const [visible, setVisible] = React.useState(false);
  const [approvalContent, setApprovalContent] = React.useState<
    undefined | ApprovalContentItem
  >(undefined);

  const fetchApprovalContent = async () => {
    if (data.operation_type !== 1 && data.operation_type !== 7) return;
    const res = await getApprovalContentById(data.apply_approval_id);
    if (res.code === 0) {
      setApprovalContent(res.data);
    } else {
    }
  };
  React.useEffect(() => {
    fetchApprovalContent();
  }, []);
  if (!data) return null;
  return (
    <>
      <List.Item.Meta
        avatar={
          <Avatar style={{ backgroundColor: "#c37878" }}>
            {data.operation_user_name.slice(0, 1)}
          </Avatar>
        }
        title={
          <a onClick={(e) => e.preventDefault()}>{data.operation_user_name}</a>
        }
        description={
          <>
            <span style={{ paddingRight: 16 }}>
              审批结果：{data.operation_result}
            </span>
            <span style={{ paddingRight: 16 }}>审批意见：{data.comment}</span>
            <span style={{ paddingRight: 16 }}>
              审批时间：{data.create_time}
            </span>
          </>
        }
      ></List.Item.Meta>
      {(data.operation_type === 1 || data.operation_type === 7) && (
        <a
          onClick={(e) => {
            e.preventDefault();
            setVisible(true);
          }}
        >
          查看提交内容
        </a>
      )}
      {approvalContent && (
        <Modal
          title="提交内容"
          visible={visible}
          okText={"关闭"}
          onCancel={() => setVisible(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          onOk={() => setVisible(false)}
        >
          {data.operation_type === 1 && (
            <div>
              <Descriptions>
                <Descriptions.Item label="提交额度">
                  {approvalContent.submit_credit_line}
                </Descriptions.Item>
                <Descriptions.Item label="产品类型">
                  {ProductType[approvalContent.product_type]}
                </Descriptions.Item>
                <Descriptions.Item label="正/反向保理">
                  {approvalContent.is_reverse_factoring ? "反向" : "正向"}
                </Descriptions.Item>
                <Descriptions.Item label="有/无追索">
                  {approvalContent.is_non_recourse_factoring ? "无" : "有"}
                </Descriptions.Item>
                <Descriptions.Item label="明/暗保理">
                  {approvalContent.is_undisclosed_factoring
                    ? "暗保理"
                    : "明保理"}
                </Descriptions.Item>
                <Descriptions.Item label="其他保理类型">
                  {OthersFactoring[approvalContent.others_factoring]}
                </Descriptions.Item>
                <Descriptions.Item label="现场尽调报告">
                  <FileViewer file={approvalContent.report} />
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}
          {data.operation_type === 7 && (
            <div>
              <Descriptions column={2}>
                <Descriptions.Item label="涉诉查询结果" span={2}>
                  {approvalContent.litigation_result ? "有涉诉" : "无涉诉"}
                </Descriptions.Item>
                <Descriptions.Item label="网站查询截图">
                  <FileViewer file={approvalContent.query_picture} />
                </Descriptions.Item>
                <Descriptions.Item label="涉诉情况说明">
                  <FileViewer file={approvalContent.litigation_illustration} />
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default CreditApprovalLogDisplayer;
