import * as React from "react";
import {
  Button,
  message,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Steps,
  Divider,
} from "antd";
import { ContractList } from "./SMApprovalModal";
import { getTemplateList } from "@/requests/template";

interface PropsType {
  contract: ContractList;
  contracts: ContractList[];
  setContracts: Function;
  visible: boolean;
  setVisible: Function;
  data: BusinessApplyItem;
  accountInfo: BusinessApprovalAccount | undefined;
  businessConfig: BusinessApprovalConfig | undefined;
  costConfig: BusinessCostConfig | undefined;
}

const ConctractContentWriteModal: React.FC<PropsType> = (props: PropsType) => {
  const { visible, contract, contracts, setContracts, setVisible } = props;
  const [template, setTemplate] = React.useState<
    ContractTemplateItem | undefined
  >();
  const [step, setStep] = React.useState(0);

  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const transformContent = (data: string) => {
    return data
      .slice(2, -2)
      .split(",")
      .filter((x) => {
        return x.startsWith(" DescribeField") || x.startsWith("DescribeField");
      })
      .map((x) => {
        return x.split("=")[1];
      });
  };

  const fetchTemplate = async () => {
    if (!contract) return;
    message.loading("正在加载模板");
    const res = await getTemplateList({
      template_type: contract.type,
    });
    if (res.code === 0) {
      message.destroy();
      if (res.total === 1) {
        setTemplate(res.data[0]);
      } else {
        message.error("加载模板失败");
      }
    } else {
      message.error(res.message);
    }
  };

  React.useEffect(() => {
    if (visible) {
      fetchTemplate();
    }
    if (!visible) {
      setTemplate(undefined);
    }
  }, [visible]);

  const handleCancel = () => {
    setVisible(false);
  };
  const handlepreStep = () => {
    setStep(step - 1);
  };

  const handleNextStep = (values: any) => {
    if (!template) return;
    if (step === 0) {
      console.log(values);
      setContracts(
        contracts.map((x) => {
          if (x.id === contract.id) {
            return {
              ...x,
              recipient: JSON.stringify(
                Object.keys(JSON.parse(template.template_role)).map((key) => {
                  return {
                    role: key,
                    name: values[`name-${key}`],
                    phone: values[`phone-${key}`],
                  };
                })
              ),
            };
          } else {
            return x;
          }
        })
      );
      setStep(1);
    }
    if (step === 1) {
      console.log(values);
      Modal.confirm({
        title: "温馨提示",
        content: "确认保存合同内容吗？",
        onOk: () => {
          setContracts(
            contracts.map((x) => {
              if (x.id === contract.id) {
                return {
                  ...x,
                  contract_content: JSON.stringify(values),
                };
              } else {
                return x;
              }
            })
          );
          setStep(0);
          setVisible(false);
        },
        onCancel: () => {},
        okText: "确认",
        cancelText: "取消",
      });
    }
  };

  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>编辑合同
        </span>
      }
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      maskClosable={false}
      width={1200}
    >
      {template && (
        <div>
          <Steps style={{ padding: "0 24px", marginBottom: 16 }} current={step}>
            <Steps.Step
              key={1}
              title="签约方信息"
              description="填写签约方信息"
            ></Steps.Step>
            <Steps.Step
              key={2}
              title="合同字段"
              description="填写合同字段"
            ></Steps.Step>
          </Steps>
          {
            <div style={{ display: step === 0 ? "block" : "none" }}>
              <Form preserve={false} onFinish={handleNextStep} form={form1}>
                {Object.keys(JSON.parse(template.template_role)).map(
                  (x, index) => {
                    return (
                      <div>
                        <Divider>
                          {JSON.parse(template.template_role)[x]}
                        </Divider>

                        <Row gutter={14}>
                          <Col span={12}>
                            <Form.Item
                              label="企业名称/姓名"
                              name={`name-${x}`}
                              rules={[
                                {
                                  required: true,
                                  message: "请输入企业名称或姓名",
                                },
                              ]}
                              initialValue={
                                JSON.parse(contract.recipient).filter(
                                  (y: any) => y.role === x
                                )[0].name
                              }
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="电话"
                              name={`phone-${x}`}
                              initialValue={
                                JSON.parse(contract.recipient).filter(
                                  (y: any) => y.role === x
                                )[0].phone
                              }
                              rules={[
                                {
                                  required: true,
                                  message: "请输入电话号码",
                                },
                                {
                                  len: 11,
                                  message: "请输入正确格式电话号码",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    );
                  }
                )}
                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: 36,
                      padding: "0 100px",
                    }}
                  >
                    <Button
                      style={{ padding: "0 36px" }}
                      onClick={handleCancel}
                    >
                      取消
                    </Button>
                    <Button
                      type="primary"
                      htmlType={"submit"}
                      style={{ padding: "0 36px" }}
                    >
                      下一步
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          }
          {
            <div style={{ display: step === 1 ? "block" : "none" }}>
              <Form
                layout="horizontal"
                preserve={false}
                onFinish={handleNextStep}
                form={form2}
              >
                <Row gutter={24}>
                  {transformContent(template.template_content).map((x) => (
                    <Col span={8}>
                      <Form.Item
                        label={x}
                        name={x}
                        key={x}
                        rules={[
                          {
                            required: true,
                            message: `请输入${x}`,
                          },
                        ]}
                        initialValue={JSON.parse(contract.contract_content)[x]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: 36,
                      padding: "0 100px",
                    }}
                  >
                    <Button
                      style={{ padding: "0 36px" }}
                      onClick={handlepreStep}
                    >
                      上一步
                    </Button>
                    <Button
                      type="primary"
                      htmlType={"submit"}
                      style={{ padding: "0 36px" }}
                    >
                      完成
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          }
        </div>
      )}
    </Modal>
  );
};

export default ConctractContentWriteModal;
