/// <reference path="type.d.ts" />
/// <reference path="business.d.ts" />

import request from "./_base";

/**
 * 获取用户业务提交申请列表
 * @param  {BaseListReq} input
 */
export async function getBusinessApplyList(
  input: BaseListReq
): Promise<ResponseProto<{ data: BusinessApplyItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/product_apply_approval/candidate/list`,
    data: input,
  }) as any;
}

/**
 * 获取用户业务提交申请详情
 * @param  {BusinessApplyItem['id']} id
 */
export async function getBusinessApplyDetail(
  id: BusinessApplyItem["id"]
): Promise<ResponseProto<{ data: BusinessApplyItem }>> {
  return request({
    method: "GET",
    url: `/product_apply_approval/info/${id}`,
  }) as any;
}

/**
 * 业务申请业务员审核
 * @param  {BusinessSalesmanApprovlReq} input
 */
export async function businessSalesmanApproval(
  input: BusinessSalesmanApprovlReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/product_apply_approval/salesman/approval`,
    data: input,
  }) as any;
}

/**
 * 业务申请业务主管审核
 * @param  {BusinessSupervisorApprovalReq} input
 */
export async function businessSupervisorApproval(
  input: BusinessSupervisorApprovalReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/product_apply_approval/business_supervisor/approval`,
    data: input,
  }) as any;
}

/**
 * 业务申请风控审核
 * @param  {BusinessRCPApprovalReq} input
 */
export async function businessRCPApproval(
  input: BusinessRCPApprovalReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/product_apply_approval/risk_control_person/approval`,
    data: input,
  }) as any;
}

/**
 * 业务申请风控主管审核
 * @param  {BusinessRCSApprovalReq} input
 */
export async function businessRCSApproval(
  input: BusinessRCSApprovalReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/product_apply_approval/risk_control_supervisor/approval`,
    data: input,
  }) as any;
}

/**
 * 业务申请财务主管审核
 * @param  {BusinessCFOApprovalReq} input
 */
export async function businessCFOApproval(
  input: BusinessCFOApprovalReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/product_apply_approval/taxation_staff/approval`,
    data: input,
  }) as any;
}

/**
 * 业务申请总经理审核
 * @param  {BusinessGMApprovalReq} input
 */
export async function businessGMApproval(
  input: BusinessGMApprovalReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/product_apply_approval/general_manager/approval`,
    data: input,
  }) as any;
}

/**
 * 业务台账清单
 * @param  {GetBusinessListReq} input
 */
export async function getBusinessList(
  input: GetBusinessListReq
): Promise<ResponseProto<{ data: BusinessListItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/product-apply/list`,
    data: input,
  }) as any;
}

/**
 * 获取业务详情
 * @param  {BusinessListItem['id']} id
 */
export async function getBusinessDetail(
  id: BusinessListItem["id"]
): Promise<ResponseProto<{ data: BusinessItem }>> {
  return request({
    method: "get",
    url: `/product-apply/info/${id}`,
  }) as any;
}

//还款相关///

/**
 * 获取用户还款申请列表
 * @param  {GetRepaymentApplyListReq} input
 */
export async function getRepaymentApplyList(
  input: GetRepaymentApplyListReq
): Promise<ResponseProto<{ data: RepaymentApplyListItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/repayment-apply-approval/list`,
    data: input,
  }) as any;
}

/**
 * 还款申请业务员审核
 * @param  {RepaymentSalesmanApprovalReq} input
 */
export async function repaymentSalesmanApproval(
  input: RepaymentSalesmanApprovalReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/repayment-apply-approval/salesman/approval`,
    data: input,
  }) as any;
}

/**
 * 还款申请业务主管审核
 * @param  {RepaymentSupervisorApprovalReq} input
 */
export async function repaymentSupervisorApproval(
  input: RepaymentSupervisorApprovalReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/repayment-apply-approval/business_supervisor/approval`,
    data: input,
  }) as any;
}

/**
 * 还款申请财务人员审核
 * @param  {RepaymentTaxationApprovalReq} input
 */
export async function repaymentTaxationApproval(
  input: RepaymentTaxationApprovalReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/repayment-apply-approval/taxation_staff/approval`,
    data: input,
  }) as any;
}

/**
 *  根据还款申请id查询还款申请详情
 *  @param  {RepaymentApplyListItem['repayment_id']} id
 */
export async function getRepaymentApplyDetail(
  id: RepaymentApplyListItem["repayment_id"]
): Promise<ResponseProto<{ data: RepaymentApplyListDetail }>> {
  return request({
    method: "POST",
    url: `/repayment-apply-approval/detail/${id}`,
  }) as any;
}
