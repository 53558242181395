import * as React from "react";
import { getCompanyList } from "@/requests/company";
import { message, Input, Table, Button, Modal } from "antd";
import "../styles/index.less";
import { useHistory } from "react-router";
import { ExportOutlined } from "@ant-design/icons";
import { download } from "@/utils/file";

const CompanyList = () => {
  const [data, setData] = React.useState<CompanyInfoItem[]>([]);
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [filterName, setFilterName] = React.useState("");

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    const res = await getCompanyList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
      ...(filterName && {
        company_name: filterName,
      }),
    });
    if (res.code === 0) {
      setData(res.data);
      setPagination({
        ...pagination,
        page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const doExport = () => {
    Modal.confirm({
      title: "提示",
      content: "确认导出所有企业清单到本地吗？",
      okText: "确认导出",
      onOk: async () => download("/api/company_info/list/export", "企业清单"),
      cancelText: "取消",
      onCancel: () => {},
    });
  };
  React.useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const colunms = [
    {
      title: "序号",
      dataIndex: "order",
      key: "order",
      align: "center" as "left",
      render: (text: any, record: CompanyInfoItem, index: number) =>
        `${index + 1}`,
    },
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
      align: "center" as "center",
      render: (text: any, record: CompanyInfoItem, index: number) =>
        `${record.company_name}`,
    },
    {
      title: "法人",
      dataIndex: "legal_person",
      key: "legal_person",
      align: "center" as "center",
      render: (text: any, record: CompanyInfoItem, index: number) =>
        `${record.legal_person}`,
    },

    {
      title: "注册地址",
      dataIndex: "register_address",
      key: "register_address",
      align: "center" as "center",
      render: (text: any, record: CompanyInfoItem, index: number) =>
        `${record.register_address}`,
    },
    {
      title: "联系电话",
      dataIndex: "company_phone",
      key: "company_phone",
      align: "center" as "center",
      render: (text: any, record: CompanyInfoItem, index: number) =>
        `${record.company_phone ? record.company_phone : "-"}`,
    },
    {
      title: "注册时间",
      dataIndex: "create_time",
      key: "create_time",
      align: "center" as "center",
      render: (text: any, record: CompanyInfoItem, index: number) =>
        `${record.create_time}`,
    },
    {
      title: "操作",
      key: "actions",
      align: "center" as "center",
      render: (text: any, record: CompanyInfoItem, index: number) => {
        return (
          <span>
            <a
              key="edit"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/workbench/company-management/company-info-detail`,
                  search: `id=${record.id}`,
                  state: {
                    company: record,
                  },
                });
              }}
            >
              查看详情
            </a>
          </span>
        );
      },
    },
  ];
  return (
    <div className="company-list-page">
      <div className="tools-wrapper">
        <div className="filter-item">
          <Input.Search
            onSearch={() => fetchData()}
            style={{ maxWidth: 200 }}
            placeholder="搜索公司"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          ></Input.Search>
        </div>
        <div>
          <Button type="primary" icon={<ExportOutlined />} onClick={doExport}>
            导出企业清单
          </Button>
        </div>
      </div>
      <div className="table-container">
        <Table
          dataSource={data}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
            style: {
              marginRight: 16,
            },
          }}
          loading={loading}
          columns={[...colunms]}
        />
      </div>
    </div>
  );
};

export default CompanyList;
