import * as React from "react";
import "./index.less";
import {
  Divider,
  Descriptions,
  message,
  Button,
  Modal,
  Form,
  Radio,
  Input,
  Steps,
  Result,
  List,
  Select,
  InputNumber,
  Upload,
  Collapse,
} from "antd";
import { useHistory, useLocation } from "react-router";
import {
  getCreditApplyDetail,
  getLogById,
  salesmanApproval,
  businessSupervisorApproval,
  riskControlPersonApproval,
  riskControlSupervisorApproval,
  taxationStaffApproval,
  generalManagerApproval,
  getCompanyInfoByUserId,
} from "@/requests/credit";
import FileViewer from "@/ui-components/FileViewer";
import { UPLOAD_ACTION } from "@/requests/company";
import { UploadOutlined } from "@ant-design/icons";
import { UploadPicWidget } from "@/ui-components";
import CompanyInfoDisplayer from "@/ui-components/CompanyInfoDIsplayer";
import CreditApprovalLogDisplayer from "@/ui-components/CreditApprovalLogDisplayer";
import { toThousands } from "@/utils/string";

enum applyResDes {
  "等待审核中，请尽快完成客户资料补充，提交业务主管审核" = 0,
  "业务人员审核已完成,请结合业务人员审核结果，提交复审意见" = 1,
  "业务员审核失败，申请已关闭" = 2,
  "业务部门审核完成，请结合业务部门审核结果，提交风控审批意见" = 3,
  "业务主管审核不通过，请重新提交审核" = 4,
  "财务人员审核通过，请提交终审意见" = 5,
  "财务人员审核不通过，请重新提交审核" = 6,
  "风控成员审核通过，请结合风控人员审核结果，提交风控复审意见" = 7,
  "风控人员审核不通过，请重新提交审核" = 8,
  "风控部门审核通过，请提交财务审核意见" = 9,
  "风控主管审核不通过，请重新提交审核" = 10,
  "综合授信审批完成，用户授信信息已生效" = 11,
  "总经理审核不通过，请重新提交审核意见" = 12,
}
enum applyRes {
  "待业务人员审核" = 1,
  "待业务主管审核" = 2,
  "待风控人员审核" = 3,
  "待风控主管审核" = 4,
  "待财务人员审核" = 5,
  "待总经理审核" = 6,
  "审核完成" = 7,
}

const UploadButton = ({ disabled = false }) => (
  <Button icon={<UploadOutlined />} disabled={disabled}>
    点击上传
  </Button>
);

const CreditDetail = () => {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = React.useState<undefined | CreditApplyItem>(
    undefined
  );
  const [loading, setLoading] = React.useState(false);

  const [logs, setLogs] = React.useState<OperationLog[]>([]);
  const [approveVisible, setApproveVisible] = React.useState(false);
  const [form] = Form.useForm();
  const [isPass, setIsPass] = React.useState(0);
  const [companyInfo, setCompanyInfo] = React.useState<
    undefined | CompanyInfoItem
  >(undefined);

  const handleApproveCancel = () => {
    setApproveVisible(false);
  };

  const doApproval = async (values: any) => {
    if (!data) return;
    setLoading(true);
    if (data.approval_status === 0 || data.approval_status === 4) {
      const res = await salesmanApproval({
        ...(isPass === 1 && {
          submit_credit_line: values.submit_credit_line,
          product_type: values.product_type,
          is_reverse_factoring: values.is_reverse_factoring,
          is_non_recourse_factoring: values.is_non_recourse_factoring,
          is_undisclosed_factoring: values.is_undisclosed_factoring,
          others_factoring: values.others_factoring,
        }),
        ...(values.report && {
          report: values.report.file.response.data,
        }),
        task_id: data.task_id,

        approval: values.approval,
        comment: values.comment,
      });
      if (res.code === 0) {
        message.success("提交成功");
        fetchData();
        setApproveVisible(false);
      } else {
        message.error(res.message);
      }
    }
    if (data.approval_status === 1 || data.approval_status === 8) {
      const res = await businessSupervisorApproval({
        task_id: data.task_id,
        approval: values.approval,
        comment: values.comment,
      });
      if (res.code === 0) {
        message.success("提交成功");
        fetchData();
        setApproveVisible(false);
      } else {
        message.error(res.message);
      }
    }
    if (data.approval_status === 3 || data.approval_status === 10) {
      const res = await riskControlPersonApproval({
        task_id: data.task_id,
        ...(isPass === 1 && {
          litigation_illustration: values.litigation_illustration
            ? values.litigation_illustration.file.response.data
            : " ",
          litigation_result: values.litigation_result,
          query_picture: values.query_picture[0].response.data,
        }),
        approval: values.approval,
        comment: values.comment,
      });
      if (res.code === 0) {
        message.success("提交成功");
        fetchData();
        setApproveVisible(false);
      } else {
        message.error(res.message);
      }
    }
    if (data.approval_status === 7 || data.approval_status === 6) {
      const res = await riskControlSupervisorApproval({
        task_id: data.task_id,
        approval: values.approval,
        comment: values.comment,
      });
      if (res.code === 0) {
        message.success("提交成功");
        fetchData();
        setApproveVisible(false);
      } else {
        message.error(res.message);
      }
    }
    if (data.approval_status === 9 || data.approval_status === 12) {
      const res = await taxationStaffApproval({
        task_id: data.task_id,
        approval: values.approval,
        comment: values.comment,
      });
      if (res.code === 0) {
        message.success("提交成功");
        fetchData();
        setApproveVisible(false);
      } else {
        message.error(res.message);
      }
    }
    if (data.approval_status === 5) {
      const res = await generalManagerApproval({
        task_id: data.task_id,
        approval: values.approval,
        comment: values.comment,
      });
      if (res.code === 0) {
        message.success("提交成功");
        fetchData();
        setApproveVisible(false);
      } else {
        message.error(res.message);
      }
    }
    setLoading(false);
  };
  const handleApproveOk = (values: any) => {
    if (!data) return;
    console.log("xxxxxxxx", values);
    doApproval(values);
  };
  const fetchData = async () => {
    let id: string = "";
    if (location.state) {
      const state = location.state as any;
      id = state.id;
    } else if (location.search) {
      id = location.search.split("=")[1];
    }
    if (!id) return;
    const res = await getCreditApplyDetail(id);
    if (res.code === 0) {
      setData(res.data);
      fetchApprovalLog();

      fetchCompanyInfo(res.data.user_id);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };
  const fetchApprovalLog = async () => {
    let id: string = "";
    if (location.state) {
      const state = location.state as any;
      id = state.id;
    } else if (location.search) {
      id = location.search.split("=")[1];
    }
    if (!id) return;
    const res = await getLogById(id);
    if (res.code === 0) {
      if (res.data) {
        setLogs(res.data);
      }
    } else {
    }
  };

  const fetchCompanyInfo = async (user_id: CreditApplyItem["user_id"]) => {
    const res = await getCompanyInfoByUserId(user_id);
    if (res.code === 0) {
      setCompanyInfo(res.data);
    } else {
    }
  };

  const getCurrent = (status: number) => {
    if (status === 0 || status === 4) {
      return 1;
    } else if (status === 1 || status === 8) {
      return 2;
    } else if (status === 3 || status === 10) {
      return 3;
    } else if (status === 7 || status === 6) {
      return 4;
    } else if (status === 9 || status === 12) {
      return 5;
    } else if (status === 5) {
      return 6;
    } else if (status === 11) {
      return 7;
    } else {
      return 7;
    }
  };

  const getStatus = (status: number) => {
    if (
      status === 0 ||
      status === 1 ||
      status === 3 ||
      status === 5 ||
      status === 7 ||
      status === 9
    ) {
      return "info";
    } else if (
      status === 2 ||
      status === 4 ||
      status === 6 ||
      status === 8 ||
      status === 10 ||
      status === 12
    ) {
      return "error";
    } else if (status === 11) {
      return "success";
    }
  };

  React.useEffect(() => {
    if (!location.search) return;
    fetchData();
  }, [location]);
  return (
    <div className="credit-detail-page">
      {data && (
        <>
          <Steps
            current={getCurrent(data.approval_status)}
            size="small"
            style={{ margin: "16px 0" }}
          >
            <Steps.Step title="提交" description="用户提交申请"></Steps.Step>
            <Steps.Step title="业务员审核" description={"业务员"}></Steps.Step>
            <Steps.Step
              title="业务主管审核"
              description="业务主管"
            ></Steps.Step>
            <Steps.Step
              title="风控人员审核"
              description="风控人员"
            ></Steps.Step>
            <Steps.Step
              title="风控主管审核"
              description="风控主管"
            ></Steps.Step>
            <Steps.Step title="财务审核" description="财务主管"></Steps.Step>
            <Steps.Step title="总经理审核" description="总经理"></Steps.Step>

            <Steps.Step
              title="审核完成"
              description="完成综合授信审批"
            ></Steps.Step>
          </Steps>
          <Result
            style={{ padding: 16 }}
            status={getStatus(data.approval_status)}
            title={applyRes[getCurrent(data.approval_status)]}
            subTitle={applyResDes[data.approval_status]}
            extra={[
              data.approval_status !== 2 && data.approval_status !== 11 && (
                <Button
                  type="primary"
                  onClick={() => {
                    setApproveVisible(true);
                  }}
                >
                  提交审核结果
                </Button>
              ),
            ]}
          />
          <Divider>用户提交内容</Divider>
          <Descriptions>
            <Descriptions.Item label="企业名称">
              {data.company_name}
            </Descriptions.Item>
            <Descriptions.Item label="申请额度">
              {toThousands(data.credit_line)} 元
            </Descriptions.Item>
            <Descriptions.Item label="申请授信期限">
              {data.credit_term} 个月
            </Descriptions.Item>
            <Descriptions.Item label="股东会决议">
              <FileViewer file={data.resolution} />
            </Descriptions.Item>
            <Descriptions.Item label="备注">
              {data.comment ? data.comment : "无"}
            </Descriptions.Item>
            <Descriptions.Item label="申请时间">
              {data.apply_time}
            </Descriptions.Item>
          </Descriptions>
          {companyInfo && (
            <Collapse>
              <Collapse.Panel key="1" header="企业基础资料(点击展开)">
                <CompanyInfoDisplayer data={companyInfo} />
              </Collapse.Panel>
            </Collapse>
          )}
          {logs && (
            <>
              <Divider>审批记录</Divider>
              <List
                dataSource={logs}
                renderItem={(item) => (
                  <List.Item key={item.id}>
                    <CreditApprovalLogDisplayer data={item} />
                  </List.Item>
                )}
              />
              <Divider></Divider>
            </>
          )}
        </>
      )}
      {data && (
        <Modal
          title="授信审批"
          footer={null}
          onCancel={handleApproveCancel}
          visible={approveVisible}
          destroyOnClose
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleApproveOk}
            preserve={false}
          >
            {(data.approval_status === 0 || data.approval_status === 4) &&
              isPass === 1 && (
                <>
                  <Form.Item
                    label="产品类型"
                    name="product_type"
                    rules={[{ required: true, message: "请选择产品类型" }]}
                    initialValue={0}
                  >
                    <Select>
                      <Select.Option value={0}>全部</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="明/暗保理"
                    name="is_undisclosed_factoring"
                    rules={[{ required: true, message: "请选择明暗保理类型" }]}
                    initialValue={0}
                  >
                    <Select>
                      <Select.Option value={0}>明保理</Select.Option>
                      <Select.Option value={1}>暗保理</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="正向/反向保理"
                    name="is_reverse_factoring"
                    rules={[
                      { required: true, message: "请选择正反向保理类型" },
                    ]}
                    initialValue={0}
                  >
                    <Select>
                      <Select.Option value={0}>正向保理</Select.Option>
                      <Select.Option value={1}>反向保理</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="有/无追索"
                    name="is_non_recourse_factoring"
                    rules={[
                      { required: true, message: "请选择是否有追索权保理类型" },
                    ]}
                    initialValue={0}
                  >
                    <Select>
                      <Select.Option value={0}>有追索</Select.Option>
                      <Select.Option value={1}>无追索</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="其他保理类型"
                    name="others_factoring"
                    rules={[{ required: true, message: "请选择其他保理类型" }]}
                    initialValue={0}
                  >
                    <Select>
                      <Select.Option value={0}>无</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="提交额度">
                    <Form.Item
                      name="submit_credit_line"
                      noStyle
                      rules={[{ required: true, message: "请输入提交额度" }]}
                      initialValue={data.credit_line}
                    >
                      <InputNumber style={{ width: 150 }} />
                    </Form.Item>
                    <span style={{ paddingLeft: 8 }}>元</span>
                  </Form.Item>
                  <Form.Item label="现场尽调报告" name="report">
                    <Upload
                      action={UPLOAD_ACTION}
                      onChange={({ file, fileList }) => {
                        if (file.status === "success") {
                          console.log(file.response);
                        }
                      }}
                    >
                      <UploadButton />
                    </Upload>
                  </Form.Item>
                </>
              )}
            {(data.approval_status === 3 || data.approval_status === 10) &&
              isPass === 1 && (
                <>
                  <Form.Item
                    label="涉诉查询结果"
                    name="litigation_result"
                    rules={[{ required: true, message: "请选择涉诉查询结果" }]}
                  >
                    <Radio.Group>
                      <Radio value={0}>无涉诉</Radio>
                      <Radio value={1}>有涉诉</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label="涉诉查询截图"
                    name="query_picture"
                    rules={[{ required: true, message: "请上传涉诉查询截图" }]}
                  >
                    <UploadPicWidget action={UPLOAD_ACTION} />
                  </Form.Item>

                  <Form.Item
                    label="涉诉情况说明"
                    name="litigation_illustration"
                    rules={[
                      {
                        required:
                          form.getFieldValue("litigation_result") === 1
                            ? true
                            : false,
                        message: "请上传涉诉情况说明",
                      },
                    ]}
                  >
                    <Upload
                      action={UPLOAD_ACTION}
                      onChange={({ file, fileList }) => {
                        if (file.status === "success") {
                          console.log(file.response);
                        }
                      }}
                    >
                      <UploadButton />
                    </Upload>
                  </Form.Item>
                </>
              )}
            <Form.Item
              label="审批结果"
              name="approval"
              rules={[{ required: true, message: "请选择审批结果" }]}
              initialValue={isPass}
            >
              <Radio.Group onChange={(e) => setIsPass(e.target.value)}>
                <Radio value={0}>不通过</Radio>
                <Radio value={1}>通过</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="审批意见"
              name="comment"
              rules={[{ required: true, message: "请输入审批意见" }]}
            >
              <Input.TextArea placeholder="请输入审批意见"></Input.TextArea>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 36,
                  padding: "0 100px",
                }}
              >
                <Button
                  type="primary"
                  htmlType={"submit"}
                  style={{ padding: "0 36px" }}
                  loading={loading}
                >
                  确认
                </Button>
                <Button
                  onClick={handleApproveCancel}
                  style={{ padding: "0 36px" }}
                >
                  取消
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default CreditDetail;
