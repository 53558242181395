import * as React from "react";
import { message, Form, Input, DatePicker, Button, List, Tag } from "antd";
import { getAllBillList } from "@/requests/loan";
import { useHistory, useLocation } from "react-router";
import { SearchOutlined, RedoOutlined } from "@ant-design/icons";
import { toThousands } from "@/utils/string";
import "../styles/index.less";

enum Status {
  "待制单" = 0,
  "待复核" = 1,
  "已放款" = 2,
}

const StatusTag = ({ status }: { status: number }) => {
  if (status === 0) {
    return <Tag color={"red"}>{Status[status]}</Tag>;
  }
  if (status === 1) {
    return <Tag color={"volcano"}> {Status[status]}</Tag>;
  }
  if (status === 2) {
    return <Tag color={"green"}>{Status[status]}</Tag>;
  }
  return null;
};

const AllBillList = () => {
  const [data, setData] = React.useState<BillingListItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });

  const location = useLocation();
  const history = useHistory();

  const fetchData = async (page?: number, pageSize?: number) => {
    setLoading(true);
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    if (!page) {
      page = pagination.page;
    }

    const res = await getAllBillList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
    });

    if (res.code === 0) {
      setData(res.data.records);
      setPagination({
        page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (location.pathname === "/workbench/business/bill-record") {
      fetchData();
    }
  }, [location]);

  return (
    <div className="billing-list-page">
      <div className="tools-wrapper">
        <Form colon={false} layout="inline">
          <Form.Item label="企业名称" className="filter-item">
            <Input style={{ maxWidth: 200 }} placeholder="全部"></Input>
          </Form.Item>

          <Form.Item label="申请时间" className="filter-item">
            <DatePicker.RangePicker style={{ minWidth: 200 }} showTime />
          </Form.Item>
        </Form>

        <div style={{ display: "flex" }}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            style={{ marginRight: 10 }}
            onClick={() => fetchData()}
          >
            搜索
          </Button>
          <Button icon={<RedoOutlined />}>重置</Button>
        </div>
      </div>
      <div style={{ backgroundColor: "#ffffff", padding: 16, marginTop: 16 }}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          loading={loading}
          renderItem={(item) => {
            return (
              <List.Item
                actions={[
                  <StatusTag status={item.status} />,
                  <a
                    key="view"
                    onClick={() => {
                      history.push({
                        pathname: `/workbench/business/bill-record/detail`,
                        state: {
                          record: item,
                        },
                        search: `id=${item.id}`,
                      });
                    }}
                  >
                    查看详情
                  </a>,
                ]}
              >
                <List.Item.Meta
                  title={
                    <span style={{ color: "inherit" }}>
                      企业名称：
                      <span>
                        {item.company_name ? item.company_name : "未知"}
                      </span>
                    </span>
                  }
                  description={
                    <div style={{ display: "flex" }}>
                      <span>{`金额：${toThousands(item.amount)} 元`}</span>
                      <span
                        style={{ padding: "0 16px" }}
                      >{`申请时间：${item.create_time}`}</span>
                    </div>
                  }
                ></List.Item.Meta>
              </List.Item>
            );
          }}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
          }}
        ></List>
      </div>
    </div>
  );
};

export default AllBillList;
