/// <reference path="type.d.ts" />
/// <reference path="product.d.ts" />

import request from "./_base";

/**
 * 新增产品
 * @param  {CreateProductReq} input
 */
export async function CreateProduct(
  input: CreateProductReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/product/add`,
    data: input,
  }) as any;
}

/**
 * 删除产品
 * @param  {DeleteProductReq} input
 */
export async function deleteProduct(
  input: DeleteProductReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "put",
    url: `/product/delete`,
    data: input,
  }) as any;
}

/**
 * 获取产品列表
 * @param  {GetProductListReq} input
 */
export async function getProductList(
  input: GetProductListReq
): Promise<ResponseProto<{ data: ProductItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/product/list`,
    data: input,
  }) as any;
}

/**
 * 修改产品
 * @param  {ModifyProductReq} input
 */
export async function modifyProduct(
  input: ModifyProductReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/product/update`,
    data: input,
  }) as any;
}
