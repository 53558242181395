import * as React from "react";
import {
  message,
  Form,
  Input,
  DatePicker,
  Button,
  List,
  Card,
  Tooltip,
} from "antd";
import { getLoanList, billing } from "@/requests/loan";
import { useLocation } from "react-router";
import { SearchOutlined, RedoOutlined } from "@ant-design/icons";
import { toThousands } from "@/utils/string";
import "../styles/index.less";
import LoanModal from "../components/LoanModal";
import FileViewer from "@/ui-components/FileViewer";
import { getUserList } from "@/requests/user";

const LoanList = () => {
  const [data, setData] = React.useState<BillingListItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [users, setUsers] = React.useState<UserInfoItem[]>([]);
  const [loanVisible, setLoanVisible] = React.useState(false);
  const [LoanData, setLoanData] = React.useState<BillingListItem>();

  const location = useLocation();
  const fetchUsers = async () => {
    const res = await getUserList({ limit: 100, skip: 0 });
    if (res.code === 0) {
      setUsers(res.data);
    } else {
      message.error("获取用户列表失败");
    }
  };

  const fetchData = async (page?: number, pageSize?: number) => {
    setLoading(true);
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    if (!page) {
      page = pagination.page;
    }

    const res = await getLoanList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
    });

    if (res.code === 0) {
      setData(res.data.records);
      setPagination({
        page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (location.pathname === "/workbench/business/loan-list") {
      fetchData();
      fetchUsers();
    }
  }, [location]);
  if (users.length < 1) return null;
  return (
    <div className="billing-list-page">
      {LoanData && (
        <LoanModal
          visible={loanVisible}
          setVisible={setLoanVisible}
          data={LoanData}
          fetchData={fetchData}
        />
      )}
      <div className="tools-wrapper">
        <Form colon={false} layout="inline">
          <Form.Item label="企业名称" className="filter-item">
            <Input style={{ maxWidth: 200 }} placeholder="全部"></Input>
          </Form.Item>

          <Form.Item label="申请时间" className="filter-item">
            <DatePicker.RangePicker style={{ minWidth: 200 }} showTime />
          </Form.Item>
        </Form>

        <div style={{ display: "flex" }}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            style={{ marginRight: 10 }}
            onClick={() => fetchData()}
          >
            搜索
          </Button>
          <Button icon={<RedoOutlined />}>重置</Button>
        </div>
      </div>
      <div style={{ backgroundColor: "#ffffff", padding: 16, marginTop: 16 }}>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
            xxl: 4,
          }}
          dataSource={data}
          loading={loading}
          renderItem={(item) => {
            if (item === null) return null;
            return (
              <List.Item key={item.id}>
                <Card
                  title={`企业名称：${item.company_name} `}
                  actions={[
                    <Button
                      type="primary"
                      onClick={() => {
                        setLoanData(item);
                        setLoanVisible(true);
                      }}
                    >
                      复核提交
                    </Button>,
                  ]}
                >
                  <Form labelCol={{ md: 8, lg: 8 }} labelAlign={"left"}>
                    <Form.Item label="放款金额" style={{ marginBottom: 0 }}>
                      {`${toThousands(item.amount)} 元`}
                    </Form.Item>
                    <Form.Item label="制单金额" style={{ marginBottom: 0 }}>
                      {toThousands(item.billing_amount)} 元
                    </Form.Item>
                    <Form.Item label="制单截图" style={{ marginBottom: 0 }}>
                      {item.billing_img.split(",").map((img: string) => (
                        <FileViewer file={img} key={img} />
                      ))}
                    </Form.Item>
                    <Form.Item label="制单时间" style={{ marginBottom: 0 }}>
                      <span>{item.billing_time}</span>
                    </Form.Item>
                    <Form.Item label="制单人" style={{ marginBottom: 0 }}>
                      <span>
                        {item.billing_user_id
                          ? users.filter(
                              (x) => x.id === item.billing_user_id
                            )[0].user_name
                          : "无"}
                      </span>
                    </Form.Item>
                    <Form.Item label="最迟放款时间" style={{ marginBottom: 0 }}>
                      <span>{item.deadline}</span>
                    </Form.Item>
                  </Form>
                </Card>
              </List.Item>
            );
          }}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
            style: { marginRight: 16 },
            showTotal: (total) => <span>共{total}条数据</span>,
          }}
        />
      </div>
    </div>
  );
};

export default LoanList;
