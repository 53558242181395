import * as React from "react";
import {
  Button,
  Modal,
  Form,
  Radio,
  Input,
  message,
  Select,
  InputNumber,
} from "antd";

import { repaymentSupervisorApproval } from "@/requests/business";

interface PropsType {
  visible: boolean;
  setVisible: Function;
  fetchData: Function;
  data: RepaymentApplyListDetail;
  id: number;
}

const RepaymentBSApprovalModal: React.FC<PropsType> = (props: PropsType) => {
  const { visible, setVisible, fetchData, data, id } = props;

  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [isPass, setIsPass] = React.useState(1);

  const handleCancel = () => {
    setVisible(false);
  };

  const doApproval = async (input: RepaymentSupervisorApprovalReq) => {
    setLoading(true);
    const res = await repaymentSupervisorApproval(input);
    if (res.code === 0) {
      message.success("提交成功");
      fetchData(id);
      setVisible(false);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const handleOK = (values: any) => {
    console.log(values);
    doApproval({
      ...values,
      approval: values.approval === 1 ? true : false,
      task_id: data.task_id,
    });
  };

  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>业务审核
        </span>
      }
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      maskClosable={false}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            justifyContent: "center",
          }}
        >
          <Form form={form} preserve={false} onFinish={handleOK}>
            <Form.Item
              label="审批结果"
              name="approval"
              rules={[{ required: true, message: "请选择审批结果" }]}
              initialValue={isPass}
            >
              <Radio.Group onChange={(e) => setIsPass(e.target.value)}>
                <Radio value={1}>通过</Radio>
                <Radio value={0}>不通过</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="审批意见"
              name="comment"
              rules={[{ required: true, message: "请输入审批意见" }]}
            >
              <Input.TextArea placeholder="请输入审批意见"></Input.TextArea>
            </Form.Item>

            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 36,
                  padding: "0 100px",
                }}
              >
                <Button
                  style={{ padding: "0 36px" }}
                  loading={loading}
                  onClick={handleCancel}
                >
                  取消
                </Button>
                <Button
                  type="primary"
                  htmlType={"submit"}
                  style={{ padding: "0 36px" }}
                >
                  完成
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default RepaymentBSApprovalModal;
