import * as React from "react";
import * as CreditRequests from "@/requests/credit";
import { Input, Button, Form, List, DatePicker } from "antd";
import { RedoOutlined, SearchOutlined } from "@ant-design/icons";
import { toThousands } from "@/utils/string";
import "./index.less";
import { useHistory } from "react-router";
import moment from "moment";

const CreditApplyList = () => {
  const [data, setData] = React.useState<CreditApplyItem[]>([]);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [filterName, setFilterName] = React.useState("");
  const [filterStatus, setFilterStatus] = React.useState(11);
  const [filterTime, setFilterTime] = React.useState<string[]>([]);
  const history = useHistory();

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    const res = await CreditRequests.getCreditApplyList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
      ...(filterName && {
        company_name: filterName,
      }),
      ...(filterStatus &&
        filterStatus !== 1 && {
          status: filterStatus,
        }),
      ...(filterTime && {
        begin_time: filterTime[0],
        end_time: filterTime[1],
      }),
    });
    if (res.code === 0) {
      setData(res.data);
      setPagination({
        ...pagination,
        page: page,
        pageSize: pageSize,
        total: res.total,
      });
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="credit-list-page">
      <div className="tools-wrapper">
        <Form colon={false} layout="inline">
          <Form.Item label="企业名称" className="filter-item">
            <Input
              style={{ maxWidth: 200 }}
              placeholder="全部"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
            ></Input>
          </Form.Item>

          <Form.Item label="申请时间" className="filter-item">
            <DatePicker.RangePicker
              onChange={(value, dateString) => setFilterTime(dateString)}
              style={{ minWidth: 200 }}
              showTime
              value={
                filterTime.length > 1
                  ? [moment(filterTime[0]), moment(filterTime[1])]
                  : undefined
              }
            />
          </Form.Item>
        </Form>

        <div style={{ display: "flex" }}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            style={{ marginRight: 10 }}
            onClick={() => fetchData()}
          >
            搜索
          </Button>
          <Button
            icon={<RedoOutlined />}
            onClick={() => {
              setFilterName("");
              setFilterTime([]);
              setFilterStatus(11);
            }}
          >
            重置
          </Button>
        </div>
      </div>
      <div style={{ backgroundColor: "#ffffff", padding: 16, marginTop: 16 }}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => {
            return (
              <List.Item
                actions={[
                  <a
                    key="view"
                    onClick={() =>
                      history.push({
                        pathname: `/workbench/credit-management/credit-apply-list/credit-detail`,
                        state: {
                          id: item.comprehensive_credit_uid,
                        },
                        search: `id=${item.comprehensive_credit_uid}`,
                      })
                    }
                  >
                    查看详情
                  </a>,
                ]}
              >
                <List.Item.Meta
                  title={
                    item.process_instance_name
                      ? item.process_instance_name.split(":")[2].split(" ")[0]
                      : "未知"
                  }
                  description={
                    <div style={{ display: "flex" }}>
                      <span>{`申请额度：${toThousands(
                        item.credit_line
                      )} 元`}</span>
                      <span
                        style={{ padding: "0 16px" }}
                      >{`申请时间：${item.create_time}`}</span>
                    </div>
                  }
                ></List.Item.Meta>
              </List.Item>
            );
          }}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
          }}
        ></List>
      </div>
    </div>
  );
};

export default CreditApplyList;
