import * as React from "react";
import { useLocation } from "react-router";
import { Divider, Result, Descriptions, message } from "antd";
import { toThousands } from "@/utils/string";
import FileViewer from "@/ui-components/FileViewer";
import { getUserList } from "@/requests/user";
enum AccountType {
  "同授信账户" = 1,
  "其他账户",
}

const BillDetail = () => {
  const [data, setData] = React.useState<BillingListItem>();
  const [users, setUsers] = React.useState<UserInfoItem[]>([]);
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname === "/workbench/business/bill-record/detail") {
      if (location.state) {
        const { record } = location.state as any;
        setData(record);
        fetchUsers();
      }
    }
  }, [location]);

  const fetchUsers = async () => {
    const res = await getUserList({ limit: 100, skip: 0 });
    if (res.code === 0) {
      setUsers(res.data);
    } else {
      message.error("获取用户列表失败");
    }
  };

  const getTitle = () => {
    if (!data) return;
    if (data.status === 0) {
      return "待制单";
    }
    if (data.status === 1) {
      return "待复核";
    }
    return "已放款";
  };

  const getStatus = () => {
    if (!data) return;

    if (data.status === 2) {
      return "success";
    }
    return "info";
  };

  const getSubTitle = () => {
    if (!data) return;
    if (data.status === 0) {
      return "请在最迟放款日期前完成制单并提交复核";
    }
    if (data.status === 1) {
      return `请在最迟放款日期前完成复核`;
    }
    return "放款流程完成，已放款";
  };
  if (!data || users.length < 1) return null;

  return (
    <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
      <Result
        title={getTitle()}
        status={getStatus()}
        subTitle={getSubTitle()}
      ></Result>
      <Divider>放款信息</Divider>
      <Descriptions>
        <Descriptions.Item label="金额">
          {toThousands(data.amount)} 元
        </Descriptions.Item>
        <Descriptions.Item label="申请时间">
          {data.create_time}
        </Descriptions.Item>
        <Descriptions.Item label="最迟放款日期">
          {data.deadline}
        </Descriptions.Item>
        {data.account && (
          <>
            <Descriptions.Item label="收款账户户名">
              {JSON.parse(data.account)["户名"] ||
                JSON.parse(data.account)["开户名"]}
            </Descriptions.Item>
            <Descriptions.Item label="收款账户账号">
              {JSON.parse(data.account)["账号"]}
            </Descriptions.Item>
            <Descriptions.Item label="收款账户开户行">
              {JSON.parse(data.account)["开户行"]}
            </Descriptions.Item>
          </>
        )}
        <Descriptions.Item label="制单金额">
          {data.billing_amount
            ? `${toThousands(data.billing_amount)} 元`
            : "无"}
        </Descriptions.Item>
        <Descriptions.Item label="制单截图">
          {data.billing_img
            ? data.billing_img
                .split(",")
                .map((img: string) => <FileViewer file={img} key={img} />)
            : "无"}
        </Descriptions.Item>
        <Descriptions.Item label="制单人">
          {data.billing_user_id
            ? users.filter((x) => x.id === data.billing_user_id)[0].user_name
            : "无"}
        </Descriptions.Item>
        <Descriptions.Item label="制单时间">
          {data.billing_time ? data.billing_time : "无"}
        </Descriptions.Item>

        <Descriptions.Item label="复核金额">
          {data.audit_amount ? `${toThousands(data.audit_amount)} 元` : "无"}
        </Descriptions.Item>
        <Descriptions.Item label="复核截图">
          {data.audit_img
            ? data.audit_img
                .split(",")
                .map((img: string) => <FileViewer file={img} key={img} />)
            : "无"}
        </Descriptions.Item>
        <Descriptions.Item label="回单">
          {data.loan_receipt
            ? data.loan_receipt
                .split(",")
                .map((img: string) => <FileViewer file={img} key={img} />)
            : "无"}
        </Descriptions.Item>
        <Descriptions.Item label="复核人">
          {data.audit_user_id
            ? users.filter((x) => x.id === data.audit_user_id)[0].user_name
            : "无"}
        </Descriptions.Item>
        <Descriptions.Item label="复核时间">
          {data.audit_time ? data.audit_time : "无"}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default BillDetail;
