import * as React from "react";
import {
  RouteComponentProps,
  withRouter,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { Layout } from "antd";

import * as UserRequests from "@/requests/user";
import { useCtxState } from "@/store/App.store";
import MyHeader from "@/ui-layout/Header";
import MyFooter from "@/ui-layout/Footer";
import MySider from "@/ui-layout/Sider";
import { ROUTE_CONFIG } from "@/router";
import "./index.less";
import CacheRoute, { CacheSwitch, clearCache } from "react-router-cache-route";
import { useWindowSize } from "@/react-helpers/hooks/xom";

const { Content, Footer } = Layout;

const WorkBench = (props: RouteComponentProps) => {
  const [{ userInfo, sidebarCollapsed }, dispatch] = useCtxState();
  const location = useLocation();

  const getUserInfo = async () => {
    const res = await UserRequests.userInfo();
    if (res.code === 0) {
      // UserRequests.getUserSysMenuInfo(res.data.id).then((res) => {
      //   if (res.code === 0) {
      //     dispatch({
      //       type: 'SAVE_USER_SYSMENU',
      //       info: res.data,
      //     })
      //   }
      // })
      dispatch({
        type: "SAVE_INFO",
        info: res.data,
      });
    } else {
    }
  };
  React.useEffect(() => {
    getUserInfo();
    clearCache();
  }, []);
  React.useEffect(() => {}, [location]);
  const { width: wWidth = Infinity } = useWindowSize();
  React.useLayoutEffect(() => {
    const nextCollapsed = wWidth <= 960;
    if (nextCollapsed === sidebarCollapsed) return;
    dispatch({
      type: "toggle:sidebarCollapsed",
      sidebarCollapsed: nextCollapsed,
    });
  }, [wWidth]);

  if (!userInfo) return null;
  return (
    <Layout className="home">
      <MySider></MySider>
      <Layout className="page_container">
        <MyHeader {...props} />
        <Content className="page_content">
          <CacheSwitch>
            {ROUTE_CONFIG.map((route) => {
              return (
                <CacheRoute
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                  cacheKey={route.path}
                />
              );
            })}
          </CacheSwitch>
        </Content>

        <Route path="/workbench" exact>
          <Redirect to="/workbench/company-management/company-apply-list" />
        </Route>
        <Footer className="home_footer">
          <MyFooter />
        </Footer>
      </Layout>
    </Layout>
  );
};

export default withRouter(WorkBench);
