import * as React from "react";
import { List, Avatar, Descriptions, Modal, Select } from "antd";
import { getContractApplyBusinessDetail } from "@/requests/contract";

interface PropsType {
  data: OperationLog;
}
enum SignType {
  "线下签订 " = 1,
  "线上签订",
}

enum DisputeResolution {
  "向成都仲裁委员会申请仲裁" = 1,
  "向成都市高新技术开发区人民法院起诉",
  "向其他机构公证处申请赋予强制执行公证",
}
enum FactoringContent {
  "资金融通" = 1,
  "应收账款管理",
  "应收账款催收",
  "应收账款债务人付款担保服务",
}

const ContractApprovalLogDisplayer = (props: PropsType) => {
  const { data } = props;
  const [visible, setVisible] = React.useState(false);
  const [approvalContent, setApprovalContent] = React.useState<
    undefined | ContractApplyBusinessDetailItem
  >(undefined);

  const fetchApprovalContent = async () => {
    if (data.operation_type !== 1) return;
    const res = await getContractApplyBusinessDetail(data.apply_approval_id);
    if (res.code === 0) {
      setApprovalContent(res.data);
    } else {
    }
  };
  React.useEffect(() => {
    fetchApprovalContent();
  }, []);
  if (!data) return null;
  return (
    <>
      <List.Item.Meta
        avatar={
          <Avatar style={{ backgroundColor: "#c37878" }}>
            {data.operation_user_name.slice(0, 1)}
          </Avatar>
        }
        title={
          <a onClick={(e) => e.preventDefault()}>
            操作者：{data.operation_user_name}
          </a>
        }
        description={
          <>
            <span style={{ paddingRight: 16 }}>
              审批结果：{data.operation_result}
            </span>
            <span style={{ paddingRight: 16 }}>审批意见：{data.comment}</span>
            <span style={{ paddingRight: 16 }}>
              审批时间：{data.create_time}
            </span>
          </>
        }
      ></List.Item.Meta>
      {data.operation_type === 1 && (
        <a
          onClick={(e) => {
            e.preventDefault();
            setVisible(true);
          }}
        >
          查看合同业务字段
        </a>
      )}
      {approvalContent && (
        <Modal
          title="提交内容"
          visible={visible}
          okText={"关闭"}
          onCancel={() => setVisible(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          onOk={() => setVisible(false)}
          width={"80%"}
        >
          {data.operation_type === 1 && (
            <div>
              <Descriptions column={2} bordered>
                <Descriptions.Item className="form-item" label="保理服务内容">
                  {approvalContent.factoring_content &&
                    JSON.parse(approvalContent.factoring_content).map(
                      (x: number) => (
                        <span style={{ paddingRight: 5 }}>
                          {FactoringContent[x]}
                        </span>
                      )
                    )}
                </Descriptions.Item>

                <Descriptions.Item className="form-item" label="合同签订地点">
                  {approvalContent.signed_address}
                </Descriptions.Item>
                <Descriptions.Item className="form-item" label="合同签订时间">
                  {approvalContent.signed_time}
                </Descriptions.Item>
                <Descriptions.Item className="form-item" label="合同签订方式">
                  {SignType[approvalContent.signed_type]}
                </Descriptions.Item>

                <Descriptions.Item
                  className="form-item"
                  label="应收账款债权人合同份数"
                >
                  {approvalContent.certificate_contract_number}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="商业保理人合同份数"
                >
                  {approvalContent.factoring_contract_number}
                </Descriptions.Item>
                <Descriptions.Item className="form-item" label="其他合同文件">
                  {approvalContent.other_contract}
                </Descriptions.Item>
                <Descriptions.Item className="form-item" label="其他规范性文件">
                  {approvalContent.other_specification}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="适用于本合同的标准"
                >
                  {approvalContent.contract_specification}
                </Descriptions.Item>

                <Descriptions.Item
                  className="form-item"
                  label="商业保理人指定接收人姓名"
                >
                  {approvalContent.factoring_contacts}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="商业保理人指定送达地址"
                >
                  {approvalContent.factoring_address}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="商业保理人指定接收人电话（座机）"
                >
                  {approvalContent.factoring_phone}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="商业保理人指定接收人手机"
                >
                  {approvalContent.factoring_mobile}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="商业保理人指定接收人电子邮箱"
                >
                  {approvalContent.factoring_email}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="商业保理人指定接收人即时通讯号（微信）"
                >
                  {approvalContent.factoring_wechat}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="转让应收账款应符合的标准"
                >
                  {approvalContent.qualified_specification}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="公开型保理转让通知方式"
                >
                  {approvalContent.open_notice_type}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="隐蔽型保理转让通知方式"
                >
                  {approvalContent.hide_notice_type}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="应收账款转让登记的日期"
                >
                  {approvalContent.transfer_registration_date}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="转让登记费的费用承担"
                >
                  {approvalContent.transfer_registration_cost}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="应收账款债权人转让给商业保理人的从属权利和权益"
                >
                  {approvalContent.transfer_subordinate_rights}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="从属权利和权益转让应完成的手续"
                >
                  {approvalContent.transfer_subordinate_pprocedure}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="从属权利和权益转让手续费的承担"
                >
                  {approvalContent.transfer_subordinate_rights_cost}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="限定债务人姓名或名称"
                >
                  {approvalContent.limited_certificate_name}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="限定债务人身份证件名称及号码"
                >
                  {approvalContent.limited_certificate_certificate}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="限定债务人联系地址"
                >
                  {approvalContent.limited_certificate_address}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="限定债务人法定代表人姓名"
                >
                  {approvalContent.limited_certificate_legal_person}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="限定债务人联系人姓名"
                >
                  {approvalContent.limited_certificate_contact}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="限定债务人联系人电话（座机）"
                >
                  {approvalContent.limited_certificate_phone}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="限定债务人联系人手机号"
                >
                  {approvalContent.limited_certificate_mobile}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="限定债务人联系人即时通讯号（微信）"
                >
                  {approvalContent.limited_certificate_wecht}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="限定债务人联系人电子邮箱"
                >
                  {approvalContent.limited_certificate_email}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="第一期保理服务费金额"
                >
                  {approvalContent.factoring_cost} 元
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="保理服务费承担方"
                >
                  {approvalContent.factoring_cost_bearer}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="保理服务费支付期限"
                >
                  {approvalContent.factoring_cost_deadline}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="杂费的种类、金额以及支付期限"
                >
                  {approvalContent.miscellaneous_cost}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="商业保理人指定银行账户户名"
                >
                  {JSON.parse(approvalContent.pay_type)["户名"]}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="商业保理人指定银行账户开户行"
                >
                  {JSON.parse(approvalContent.pay_type)["开户行"]}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="商业保理人指定银行账户账号"
                >
                  {JSON.parse(approvalContent.pay_type)["账号"]}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="保理融资是否可循环"
                >
                  {approvalContent.factoring_cycle}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="应收账款到期日可否晚于保理融资额度有效期的届满日"
                >
                  {approvalContent.after_expiry_date}
                </Descriptions.Item>
                <Descriptions.Item className="form-item" label="额度承诺费金额">
                  {approvalContent.commitment_fee} 元
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="保证金的金额、支付方式及期限"
                >
                  {approvalContent.security_deposit}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="其他履约担保的金额、方式及期限"
                >
                  {approvalContent.other_security_deposit}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="其他保理融资前提条件"
                >
                  {approvalContent.other_prerequisites}
                </Descriptions.Item>
                <Descriptions.Item className="form-item" label="融资款支付方式">
                  {approvalContent.security_deposit_pay_type}
                </Descriptions.Item>

                <Descriptions.Item
                  className="form-item"
                  label="融资利息计算公式"
                >
                  {approvalContent.interest_calculation_formula}
                </Descriptions.Item>
                <Descriptions.Item className="form-item" label="利率变化方式">
                  {approvalContent.interest_rate_changes}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="提前清偿利率计算方式"
                >
                  {approvalContent.early_payment_interest}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="提前回购利率计算方式"
                >
                  {approvalContent.early_repurchase_interest}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="税负变化时收费调整方式"
                >
                  {approvalContent.tax_burden_changes}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="直接回款保理的回收及分配方式"
                >
                  {approvalContent.pay_back_factoring}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="间接回款保理的回收及分配方式"
                >
                  {approvalContent.pay_back_factoring_synopsis}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="商业保理人的其他违约情形"
                >
                  {approvalContent.breach_contract}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="商业保理人违约责任的其他承担方式和计算方法"
                >
                  {approvalContent.breach_contract_formula}
                </Descriptions.Item>
                <Descriptions.Item className="form-item" label="争议解决">
                  {DisputeResolution[approvalContent.dispute_resolution]}
                </Descriptions.Item>
                <Descriptions.Item
                  className="form-item"
                  label="其他争议解决机构"
                >
                  {approvalContent.dispute_resolution_agency}
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default ContractApprovalLogDisplayer;
