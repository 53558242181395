/// <reference path="type.d.ts" />
/// <reference path="contract.d.ts" />

import request from "./_base";

/**
 * 获取综合授信合同申请列表
 * @param  {GetCompanyListReq} input
 */
export async function getContractApplyList(
  input: GetCompanyListReq
): Promise<ResponseProto<{ data: ContractApplyListItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/contract-approval/candidate/list`,
    data: input,
  }) as any;
}

/**
 * 获取综合授信合同申请业务提交详情
 * @param  {number} id
 */
export async function getContractApplyBusinessDetail(
  id: number
): Promise<ResponseProto<{ data: ContractApplyBusinessDetailItem }>> {
  return request({
    method: "GET",
    url: `/contract-approval/info/${id}`,
  }) as any;
}

/**
 * 获取综合授信合同申请详情
 * @param  {string} id
 */
export async function getContractApplyDetail(
  id: string
): Promise<ResponseProto<{ data: ContractApplyDetail }>> {
  return request({
    method: "GET",
    url: `/contract-approval/candidate/info/${id}`,
  }) as any;
}

/**
 * 获取综合授信合同申请审核记录
 * @param  {string} id
 */
export async function getContractApplyApprovalLogs(
  id: string
): Promise<ResponseProto<{ data: OperationLog[]; total: number }>> {
  return request({
    method: "GET",
    url: `/operation/log/contract_log/${id}`,
  }) as any;
}

/**
 * 综合授信合同业务员审核
 * @param  {ContractApprovalSalesmanApprovalReq} input
 */
export async function contractSalemanApproval(
  input: ContractApprovalSalesmanApprovalReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/contract-approval/salesman/approval`,
    data: input,
  }) as any;
}

/**
 * 综合授信合同业务主管审核
 * @param  {ContractApprovalBaseReq} input
 */
export async function contractBusinessSupervisorApproval(
  input: ContractApprovalBaseReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/contract-approval/business_supervisor/approval`,
    data: input,
  }) as any;
}

/**
 * 综合授信合同风控人员审核
 * @param  {ContractApprovalBaseReq} input
 */
export async function contractRiskControlPersonApproval(
  input: ContractApprovalBaseReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/contract-approval/risk_control_person/approval`,
    data: input,
  }) as any;
}

/**
 * 综合授信合同风控主管审核
 * @param  {ContractApprovalBaseReq} input
 */
export async function contractRiskControlSupervisorApproval(
  input: ContractApprovalBaseReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/contract-approval/risk_control_supervisor/approval`,
    data: input,
  }) as any;
}

/**
 * 综合授信合同财务人员审核
 * @param  {ContractApprovalBaseReq} input
 */
export async function contractTaxationStaffApproval(
  input: ContractApprovalBaseReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/contract-approval/taxation_staff/approval`,
    data: input,
  }) as any;
}

/**
 * 综合授信合同财务人员审核
 * @param  {ContractApprovalBaseReq} input
 */
export async function contractGeneralManagerApproval(
  input: ContractApprovalBaseReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/contract-approval/general_manager/approval`,
    data: input,
  }) as any;
}
