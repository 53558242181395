import * as React from "react";
import {
  message,
  Modal,
  Tag,
  Button,
  Form,
  Input,
  Table,
  Divider,
  Checkbox,
  Row,
  Col,
  Spin,
  Empty,
} from "antd";
import * as authRequests from "@/requests/auth";
import * as UserRequests from "@/requests/user";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { formItemLayout } from "../configs";
import { useHistory } from "react-router";

const UserInfoModal = ({
  user,
  setEditingUser,
}: {
  user: UserInfoItem | undefined;
  setEditingUser: Function;
}) => {
  if (!user) return null;
  const { user_name, phone, email, status } = user;
  return (
    <Modal
      title="用户信息"
      okText="确认"
      cancelText={null}
      visible={!!user}
      onOk={() => setEditingUser(undefined)}
      onCancel={() => setEditingUser(undefined)}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Form {...formItemLayout}>
        <Form.Item label="用户名">
          <Input value={user_name} disabled />
        </Form.Item>

        <Form.Item label="电话">
          <Input value={phone} disabled />
        </Form.Item>
        <Form.Item label="邮箱">
          <Input value={email} disabled />
        </Form.Item>
        <Form.Item label="状态">
          <Input value={status} disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const GrantRoleModal = (props: {
  user: UserInfoItem | undefined;
  setGrantUser: Function;
  grantRoleModalVisible: boolean;
  setGrantRoleModalVisible: Function;
  fetchData: Function;
}) => {
  const {
    user,
    setGrantUser,
    grantRoleModalVisible,
    setGrantRoleModalVisible,
    fetchData,
  } = props;
  const [selectVals, setSelectVals] = React.useState<CheckboxValueType[]>([]);
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [roles, setRoles] = React.useState<RoleItem[]>([]);
  React.useEffect(() => {
    if (user) {
      setSelectVals(user.role_list.map((x) => x.id));
    }
    setLoading(true);
    fetchRoleData();
  }, [user]);

  const fetchRoleData = async () => {
    const res = await authRequests.getRoleList({
      limit: 10000,
      skip: 0,
    });
    if (res.code === 0) {
      setRoles(res.data);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const onChange = (value: CheckboxValueType[]) => {
    setSelectVals(value);
  };

  const updateUserRoles = async () => {
    if (!user) return;
    setFetching(true);
    const res = await UserRequests.updateUserRole({
      id: user.id,
      role_id_list: selectVals as number[],
    });
    if (res.code === 0) {
      message.success("设置成功");
      fetchData();
      setGrantRoleModalVisible(false);
      setGrantUser(undefined);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  const goCreateRoles = () => {
    history.push("/base/auth-management/role");
  };
  if (!user) return null;
  return (
    <Modal
      title="分配角色"
      okText="确认"
      cancelText="取消"
      visible={grantRoleModalVisible}
      onOk={() => {
        updateUserRoles();
      }}
      onCancel={() => {
        setGrantRoleModalVisible(false);
        setGrantUser(undefined);
      }}
      okButtonProps={{ disabled: fetching, loading: fetching }}
      destroyOnClose
    >
      <div style={{ minHeight: 200 }}>
        {loading ? (
          <Spin
            tip="加载中"
            style={{ position: "absolute", top: "50%", left: "50%" }}
          />
        ) : (
          <>
            {roles.length < 1 && (
              <div>
                <Empty description="暂无角色，请先创建角色">
                  <Button type="primary" onClick={goCreateRoles}>
                    去创建
                  </Button>
                </Empty>
              </div>
            )}
            <Checkbox.Group
              style={{ width: "100%" }}
              onChange={onChange}
              value={selectVals}
            >
              <Row>
                {roles.map((x) => {
                  return (
                    <Col span={8} key={x.id} style={{ padding: 10 }}>
                      <Checkbox value={x.id}>{x.name}</Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </>
        )}
      </div>
    </Modal>
  );
};

const UserList = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<UserInfoItem[]>([]);
  const [filterUser, setFilterUser] = React.useState("");
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [editingUser, setEditingUser] = React.useState<
    UserInfoItem | undefined
  >(undefined);

  const [grantRoleModalVisible, setGrantRoleModalVisible] =
    React.useState(false);
  const [grantUser, setGrantUser] = React.useState<UserInfoItem | undefined>(
    undefined
  );

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) page = 1;
    if (!pageSize) pageSize = 10;
    const res = await UserRequests.getUserList({
      limit: pageSize,
      skip: pageSize * (page - 1),
      ...(filterUser && {
        user_name: filterUser,
      }),
    });
    if (res.code === 0) {
      setPagination({
        ...pagination,
        total: res.total,
      });
      setData(res.data);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const colunms = [
    {
      title: "序号",
      dataIndex: "order",
      key: "order",
      align: "center" as "left",
      render: (text: any, record: UserInfoItem, index: number) =>
        `${index + 1}`,
    },
    {
      title: "用户",
      dataIndex: "username",
      key: "username",
      align: "left" as "left",
      render: (text: any, record: UserInfoItem, index: number) => (
        <span>{record.user_name}</span>
      ),
    },
    {
      title: "电话",
      dataIndex: "phone",
      key: "phone",
      align: "left" as "left",
      render: (text: any, record: UserInfoItem, index: number) =>
        `${record.phone ? record.phone : "-"}`,
    },
    {
      title: "职位",
      dataIndex: "role",
      key: "role",
      align: "left" as "left",
      render: (text: any, record: UserInfoItem, index: number) => (
        <span>
          {record.role_list.length > 0
            ? record.role_list.map((x, index) => {
                return (
                  <Tag key={`${x.id}-${index}`} color="volcano">
                    {x.name}
                  </Tag>
                );
              })
            : "无"}
        </span>
      ),
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      align: "left" as "left",
      render: (text: any, record: UserInfoItem, index: number) => {
        return record.status === 1 ? (
          <span style={{ color: "green" }}>启用</span>
        ) : (
          <span style={{ color: "red" }}>停用</span>
        );
      },
    },

    {
      title: "操作",
      key: "actions",
      align: "center" as "left",
      render: (text: any, record: UserInfoItem, index: number) => {
        return (
          <span>
            <a
              key="view"
              onClick={(e) => {
                e.preventDefault();
                setEditingUser(record);
              }}
            >
              查看
            </a>

            <Divider type="vertical" />

            <a
              key="edit"
              onClick={(e) => {
                e.preventDefault();
                setGrantUser(record);
                setGrantRoleModalVisible(true);
              }}
            >
              赋予角色
            </a>
          </span>
        );
      },
    },
  ];
  return (
    <div className="user-manage-page">
      <div className="tools-wrapper">
        <div className="filter-item">
          <Input.Search
            onSearch={() => fetchData()}
            style={{ width: 200 }}
            placeholder="搜索用户"
            value={filterUser}
            onChange={(e) => setFilterUser(e.target.value)}
          ></Input.Search>
        </div>
      </div>
      <div style={{ backgroundColor: "#ffffff" }}>
        <Table
          dataSource={data}
          columns={colunms}
          pagination={{
            ...pagination,
            onChange: (page, pagesize) => {
              fetchData(page, pagesize);
            },
            showTotal: (total: number) => {
              return <span>{`共 ${total} 条数据`}</span>;
            },
            showQuickJumper: true,
            style: { marginRight: 16 },
          }}
          loading={loading}
          rowKey={(record) => record.id.toString()}
        ></Table>
      </div>
      <UserInfoModal user={editingUser} setEditingUser={setEditingUser} />
      {grantRoleModalVisible && (
        <GrantRoleModal
          user={grantUser}
          setGrantUser={setGrantUser}
          grantRoleModalVisible={grantRoleModalVisible}
          setGrantRoleModalVisible={setGrantRoleModalVisible}
          fetchData={fetchData}
        />
      )}
    </div>
  );
};

export default UserList;
