/// <reference path="type.d.ts" />
/// <reference path="template.d.ts" />

import request from "./_base";

/**
 * 获取模板列表
 * @param  {GetTemplateListReq} input
 */
export async function getTemplateList(
  input: GetTemplateListReq
): Promise<ResponseProto<{ data: ContractTemplateItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/contract-template/list`,
    data: input,
  }) as any;
}

/**
 * 新增模板
 * @param  {AddContractTemplateReq} input
 */
export async function addTemplate(
  input: AddContractTemplateReq
): Promise<ResponseProto<{ data: ContractTemplateItem }>> {
  return request({
    method: "POST",
    url: `/contract-template/add`,
    data: input,
  }) as any;
}
