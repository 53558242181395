/// <reference path="type.d.ts" />
/// <reference path="auth.d.ts" />

import request from "./_base";

//创建角色
export async function createRole(
  input: CreateRoleReq
): Promise<ResponseProto<{ data: RoleItem }>> {
  return request({
    url: `/role/role`,
    method: "POST",
    data: input,
  }) as any;
}

//更新角色
export async function updateRole(
  input: UpdateRoleReq
): Promise<ResponseProto<{ data: RoleItem }>> {
  return request({
    url: `/role/role`,
    method: "PUT",
    data: input,
  }) as any;
}

//删除角色
export async function deleteRole(
  input: DeleteRoleReq
): Promise<ResponseProto<{ data: null }>> {
  return request({
    url: `/role/role`,
    method: "DELETE",
    data: input,
  }) as any;
}

//获取角色清单
export async function getRoleList(
  input: BaseListReq
): Promise<ResponseProto<{ data: RoleItem[]; total: number }>> {
  return request({
    url: `/role/list`,
    method: "POST",
    data: input,
  }) as any;
}

//更新角色的系统权限
export async function updateRoleAuth(
  input: UpdateRoleAuthReq
): Promise<ResponseProto<{ data: null }>> {
  return request({
    url: `/role/sys_menu`,
    method: "PUT",
    data: input,
  }) as any;
}

//根据角色id获取权限列表
export async function getAuthListByRoleId(
  id: RoleItem["id"]
): Promise<ResponseProto<{ data: SysMenuItem[] }>> {
  return request({
    url: `/role/sys_menu/${id}`,
    method: "GET",
  }) as any;
}

//获取系统权限列表
export async function getSysMenuList(
  input: BaseListReq
): Promise<ResponseProto<{ data: SysMenuItem[]; total: number }>> {
  return request({
    url: `/sys_menu/list`,
    method: "POST",
    data: input,
  }) as any;
}

/**
 * 创建系统权限
 * @param  {CreateSysMenuReq} input
 */
export async function createSysMenu(
  input: CreateSysMenuReq
): Promise<ResponseProto<{ data: SysMenuItem }>> {
  return request({
    method: "POST",
    url: `/sys_menu/menu`,
    data: input,
  }) as any;
}

/**
 * 删除系统权限
 * @param  {DeleteSysMenuReq} input
 */
export async function deleteSysMenu(
  input: DeleteSysMenuReq
): Promise<ResponseProto<{ data: null }>> {
  return request({
    method: "DELETE",
    url: `/sys_menu/menu`,
    data: input,
  }) as any;
}

/**
 * 更新系统权限
 * @param  {UpdateSysMenuReq} input
 */
export async function updateSysMenu(
  input: UpdateSysMenuReq
): Promise<ResponseProto<{ data: null }>> {
  return request({
    method: "PUT",
    url: `/sys_menu/menu`,
    data: input,
  }) as any;
}
