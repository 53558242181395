/// <reference path="type.d.ts" />
/// <reference path="credit.d.ts" />

import request from "./_base";

export const UPLOAD_BASE_ACTION = `/api/file/quota_application/upload`;

/**
 * 获取授信申请列表
 * @param  {GetCreditListReq} input
 */
export async function getCreditApplyList(
  input: GetCreditListReq
): Promise<ResponseProto<{ data: CreditApplyItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/comprehensive_credit_approval/candidate/list`,
    data: input,
  }) as any;
}

/**
 * 获取授信申请详情
 * @param  {CreditApplyItem['comprehensive_credit_uid']} id
 */
export async function getCreditApplyDetail(
  id: CreditApplyItem["comprehensive_credit_uid"]
): Promise<ResponseProto<{ data: CreditApplyItem }>> {
  return request({
    method: "GET",
    url: `/comprehensive_credit/info/${id}`,
  }) as any;
}

/**
 * 综合授信业务员审核
 * @param  {SalesmanApprovalReq} input
 */
export async function salesmanApproval(
  input: SalesmanApprovalReq
): Promise<ResponseProto<{ data: CreditApplyItem }>> {
  return request({
    method: "POST",
    url: `comprehensive_credit_approval/salesman/approval`,
    data: input,
  }) as any;
}
/**
 * 综合授信业务主管审核
 * @param  {BaseCreditApprovalReq} input
 */
export async function businessSupervisorApproval(
  input: BaseCreditApprovalReq
): Promise<ResponseProto<{ data: CreditApplyItem }>> {
  return request({
    method: "POST",
    url: `/comprehensive_credit_approval/business_supervisor/approval`,
    data: input,
  }) as any;
}
/**
 * 综合授信风控人员审核
 * @param  {RiskControlPersonApprovalReq} input
 */
export async function riskControlPersonApproval(
  input: RiskControlPersonApprovalReq
): Promise<ResponseProto<{ data: CreditApplyItem }>> {
  return request({
    method: "POST",
    url: `/comprehensive_credit_approval/risk_control_person/approval`,
    data: input,
  }) as any;
}
/**
 * 综合授信风控主管审核
 * @param  {BaseCreditApprovalReq} input
 */
export async function riskControlSupervisorApproval(
  input: BaseCreditApprovalReq
): Promise<ResponseProto<{ data: CreditApplyItem }>> {
  return request({
    method: "POST",
    url: `/comprehensive_credit_approval/risk_control_supervisor/approval`,
    data: input,
  }) as any;
}
/**
 * 综合授信财务人员审核
 * @param  {BaseCreditApprovalReq} input
 */
export async function taxationStaffApproval(
  input: BaseCreditApprovalReq
): Promise<ResponseProto<{ data: CreditApplyItem }>> {
  return request({
    method: "POST",
    url: `/comprehensive_credit_approval/taxation_staff/approval`,
    data: input,
  }) as any;
}
/**
 * 综合授信总经理审核
 * @param  {BaseCreditApprovalReq} input
 */
export async function generalManagerApproval(
  input: BaseCreditApprovalReq
): Promise<ResponseProto<{ data: CreditApplyItem }>> {
  return request({
    method: "POST",
    url: `/comprehensive_credit_approval/general_manager/approval`,
    data: input,
  }) as any;
}
/**
 * 获取授信申请的审核日志
 * @param  {string} id
 */
export async function getLogById(
  id: string
): Promise<ResponseProto<{ data: OperationLog[] }>> {
  return request({
    method: "GET",
    url: `/operation/log/credit_log/${id}`,
  }) as any;
}

/**
 * 获取授信审核人员提交内容
 * @param  {OperationLog['apply_approval_id']} id
 */
export async function getApprovalContentById(
  id: OperationLog["apply_approval_id"]
): Promise<ResponseProto<{ data: ApprovalContentItem }>> {
  return request({
    method: "GET",
    url: `/comprehensive_credit_approval/info/${id}`,
  }) as any;
}

/**
 * 通过user_id获取企业基础资料
 * @param  {CreditApplyItem['user_id']} id
 */
export async function getCompanyInfoByUserId(
  id: CreditApplyItem["user_id"]
): Promise<ResponseProto<{ data: CompanyInfoItem }>> {
  return request({
    method: "GET",
    url: `/company_info/info/user_id/${id}`,
  }) as any;
}

// /**
//  * 获取授信详情
//  * @param  {CreditItem['id']} id
//  */
// export async function getCreditApplyById(
//   id: CreditItem["id"]
// ): Promise<ResponseProto<{ data: CreditItem }>> {
//   return request({
//     method: "GET",
//     url: `quota_application/application/${id}`,
//   }) as any;
// }

// /**
//  * 提交授信
//  * @param  {SubmitCreditReq} input
//  */
// export async function submitCredit(
//   input: SubmitCreditReq
// ): Promise<ResponseProto<{ data: null }>> {
//   return request({
//     method: "POST",
//     url: `credit_submission/quota_application/submit`,
//     data: input,
//   }) as any;
// }

// /**
//  * 根据额度申请id查询提交情况
//  * @param  {CreditItem['id']} id
//  */
// export async function getSubMissionById(
//   id: CreditItem["id"]
// ): Promise<ResponseProto<{ data: CreditSubmissionItem }>> {
//   return request({
//     method: "GET",
//     url: `credit_submission/credit_submission/${id}`,
//   }) as any;
// }

// /**
//  * 根据额度申请id查询审批情况
//  * @param  {CreditItem['id']} id
//  */
// export async function getApproveResById(
//   id: CreditItem["id"]
// ): Promise<ResponseProto<{ data: ApprovalResItem }>> {
//   return request({
//     method: "GET",
//     url: `/credit_approval/credit_approval/${id}`,
//   }) as any;
// }

// /**
//  * 审批授信申请
//  * @param  {ApproveReq} input
//  */
// export async function creditApprove(
//   input: ApproveReq
// ): Promise<ResponseProto<{ data: null }>> {
//   return request({
//     method: "POST",
//     url: `/credit_approval/credit_application/approval`,
//     data: input,
//   }) as any;
// }
