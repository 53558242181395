import * as React from "react";
import { Layout, Dropdown, Avatar, Menu, message, Modal, Tabs } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import * as UserRequests from "@/requests/user";
import { useCtxState } from "@/store/App.store";
import { clearCache } from "react-router-cache-route";
import {
  withRouter,
  RouteComponentProps,
  useLocation,
  useHistory,
} from "react-router-dom";
import "./index.less";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { dropByCacheKey } from "react-router-cache-route";

const titleCfg = [
  { path: "/workbench/credit-management/credit-apply-list", title: "授信申请" },
  { path: "/workbench/credit-management/handle-record", title: "审核记录" },
  {
    path: "/workbench/credit-management/credit-apply-list/credit-detail",
    title: "申请详情",
  },
  {
    path: "/workbench/credit-management/credit-apply-list/submit-credit",
    title: "提交审核",
  },
  { path: "/workbench/auth-management/user", title: "成员列表" },
  { path: "/workbench/auth-management/role", title: "角色管理" },
  { path: "/workbench/auth-management/auth", title: "系统权限" },
  {
    path: "/workbench/company-management/company-apply-list",
    title: "基础资料审批",
  },
  {
    path: "/workbench/company-management/company-apply-detail",
    title: "基础资料详情",
  },
  {
    path: "/workbench/company-management/company-info-detail",
    title: "企业详情",
  },
  {
    path: "/workbench/company-management/approval-record",
    title: "审批记录（基础资料）",
  },

  {
    path: "/workbench/company-management/list",
    title: "企业清单",
  },
  { path: "/workbench/quota-management/company-quota", title: "企业额度" },
  {
    path: "/workbench/contract/contract-approval",
    title: "授信合同审批",
  },
  {
    path: "/workbench/contract/template",
    title: "合同模板",
  },
  {
    path: "/workbench/contract/list",
    title: "合同列表",
  },
  {
    path: "/workbench/contract/contract-approval/detail",
    title: "合同申请详情",
  },
  {
    path: "/workbench/product/list",
    title: "产品列表",
  },
  {
    path: "/workbench/product/detail",
    title: "产品详情",
  },
  {
    path: "/workbench/business/apply-list",
    title: "业务审批",
  },
  {
    path: "/workbench/business/business-list",
    title: "业务台账",
  },
  {
    path: "/workbench/business/business-list/detail",
    title: "业务详情",
  },
  {
    path: "/workbench/business/apply-list/detail",
    title: "业务详情",
  },
  {
    path: "/workbench/business/billing-list",
    title: "放款制单",
  },
  {
    path: "/workbench/business/loan-list",
    title: "放款复核",
  },
  {
    path: "/workbench/business/bill-record",
    title: "放款记录",
  },
  {
    path: "/workbench/business/bill-record/detail",
    title: "放款详情",
  },
  {
    path: "/workbench/business/repayment/apply-list",
    title: "还款申请列表",
  },
  {
    path: "/workbench/business/repayment/apply-list/detail",
    title: "还款审核",
  },
];

/**
 * @global_state
 */

const urlMap2Title = (url: string) => {
  const matchedItem = titleCfg.find((x) => x.path === url);
  if (matchedItem) {
    return matchedItem.title;
  } else {
    return "";
  }
};
const MyHeader = (props: RouteComponentProps) => {
  const location = useLocation();
  const history = useHistory();
  const [cachedRoutes, setCachedRoutes] = React.useState<string[]>([]);
  const [activeRoute, setActiveRoute] = React.useState("");
  const [{ userInfo, sidebarCollapsed }, dispatch] = useCtxState();
  const [title, setTitle] = React.useState("资产清单");
  React.useEffect(() => {
    const { pathname } = location;
    setTitle(urlMap2Title(pathname));
    setActiveRoute(pathname);
    const isExist = !!cachedRoutes.find((x) => x === pathname);
    if (!isExist && pathname !== "/workbench") {
      let arr = cachedRoutes.map((x) => x);
      arr.push(pathname);
      setCachedRoutes(arr);
    }
  }, [location]);

  const doLogout = async () => {
    try {
      const res = await UserRequests.logout();
      if (res.code === 12006) {
        clearCache();
        message.warn("您已退出登录，请重新登录！");
        setTimeout(() => window.location.replace(`/#/login`), 500);
      } else {
        message.error(res.code);
      }
    } catch (e) {}
  };
  const handleMenuClick = ({ key }: any) => {
    if (key === "logout") {
      Modal.confirm({
        title: "温馨提示",
        content: "确认退出登录当前账号吗？",
        icon: <ExclamationCircleFilled />,
        onOk() {
          doLogout();
        },
        onCancel() {},
        cancelText: "取消",
        okText: "确认",
      });
    }
  };
  React.useEffect(() => {}, [location]);
  const menu = (
    <Menu onClick={handleMenuClick}>
      {/* <Menu.Item key="setting">
        <span className="dropmenu-item">账号设置</span>
      </Menu.Item> */}
      <Menu.Item key="logout">
        <span className="dropmenu-item">退出登录</span>
      </Menu.Item>
    </Menu>
  );
  if (!userInfo) return null;

  return (
    <div>
      <Layout.Header className="credit_header">
        <span
          style={{ fontSize: "20px", cursor: "pointer" }}
          onClick={() => {
            dispatch({ type: "toggle:sidebarCollapsed" });
          }}
        >
          {sidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          <span
            style={{
              color: "#2d3843",
              fontSize: 20,
              marginLeft: 36,
              fontWeight: 500,
            }}
          >
            {title}
          </span>
        </span>
        <span>
          <span>
            欢迎您，
            <a
              style={{ paddingRight: 16 }}
              onClick={(e) => {
                e.preventDefault();
                // history.push("/userinfo-center");
              }}
            >
              {userInfo.user_name}
            </a>
          </span>
          <Dropdown overlay={menu} className="dropdown-menu">
            <span>
              <Avatar size={40} className="avatar-circle">
                {userInfo.user_name.slice(0, 1)}
              </Avatar>
            </span>
          </Dropdown>
        </span>
      </Layout.Header>
      <div className="card-container">
        <Tabs
          type="editable-card"
          onChange={(activeKey) => {
            history.push(`${activeKey}`);
          }}
          activeKey={activeRoute}
          onEdit={(targetKey, action) => {
            if (action === "remove") {
              if (cachedRoutes.length < 2) {
                return;
              }
              let arr = cachedRoutes.filter((x) => x !== targetKey);

              setCachedRoutes(arr);
              dropByCacheKey(targetKey.toString());
              history.push(`${arr[arr.length - 1]}`);
            }
          }}
        >
          {cachedRoutes.map((route) => {
            return (
              <Tabs.TabPane tab={urlMap2Title(route)} key={route} closable>
                {null}
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default withRouter(MyHeader);
