import * as React from "react";
import { useLocation } from "react-router";
import { getCompanyDetail } from "@/requests/company";

import CompanyInfoDisplayer from "@/ui-components/CompanyInfoDIsplayer";

const CompanyInfoDetail = () => {
  const location = useLocation();
  const [data, setData] = React.useState<CompanyInfoItem | undefined>(
    undefined
  );

  const fetchData = async (id?: number) => {
    if (!id) {
      id = Number(location.search.split("=")[1]);
    }
    const res = await getCompanyDetail(id);
    if (res.code === 0) {
      setData(res.data);
    }
  };

  React.useEffect(() => {
    if (location.state) {
      const { company } = location.state as any;
      setData(company);
    }
    if (!location.state) {
      fetchData();
    }
  }, [location]);
  return (
    <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
      {data && <CompanyInfoDisplayer data={data} />}
    </div>
  );
};

export default CompanyInfoDetail;
