import * as React from "react";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";

interface Props {
  action: string;
  value?: UploadFile<any>[];
  onChange?: (value: UploadFile<any>[]) => void;
  data?: any;
  // type: number;
  // src: string;
}
function getBase64(img: any, callback: (url: any) => void) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file: any) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("只支持上传 JPG/PNG 文件!");
  }
  const isLt5M = file.size / 1024 / 1024 < 5;
  if (!isLt5M) {
    message.error("图片大小不能超过5M!");
  }
  return isJpgOrPng && isLt5M;
}

const UploadPicWidget: React.FC<Props> = (props: Props) => {
  const { action, value, onChange, data } = props;
  const [loading, setLoading] = React.useState(false);
  const [fileList, setUploadList] = React.useState<UploadFile<any>[]>([]);
  const [imageUrl, setImageUrl] = React.useState("");
  const triggerChange = (changedValue: UploadFile<any>[]) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const handleChange = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      setUploadList(info.fileList);
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false);
        setImageUrl(imageUrl);
      });
      triggerChange(info.fileList);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>点击上传</div>
    </div>
  );

  return (
    <Upload
      name="file"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      action={action}
      data={data ? data : undefined}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      fileList={value || fileList}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="avatar"
          style={{ width: "100%", height: "100%" }}
        />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default UploadPicWidget;
