import * as React from "react";
import { useHistory, useLocation } from "react-router";
import {
  Form,
  Button,
  Input,
  Checkbox,
  Radio,
  InputNumber,
  message,
} from "antd";
import { ServiceTypeOptions, formItemLayout } from "../configs";
import { modifyProduct } from "@/requests/product";

const EditProduct = () => {
  const [data, setData] = React.useState<ProductItem | undefined>(undefined);
  const location = useLocation();
  const history = useHistory();
  const [fetching, setFetching] = React.useState(false);
  const [form] = Form.useForm();

  const doUpdate = async (values: ModifyProductReq) => {
    setFetching(true);
    const res = await modifyProduct(values);
    if (res.code === 0) {
      message.success("保存成功");
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };
  const handleOk = (values: any) => {
    if (!data) return;
    doUpdate({
      ...values,
      id: data.id,
      service_type: values.service_type ? values.service_type.toString() : "",
    });
    console.log(values);
  };
  const handleCancel = () => {
    history.goBack();
  };

  React.useEffect(() => {
    if (location.pathname === "/workbench/product/detail") {
      const state = location.state as any;
      if (state) {
        if (state.data) {
          setData(state.data);
        }
      }
    }
  }, [location]);

  return data ? (
    <div style={{ backgroundColor: "#ffffff", padding: 16 }}>
      <Form
        onFinish={handleOk}
        {...formItemLayout}
        style={{ width: 500 }}
        preserve={false}
        form={form}
      >
        <Form.Item
          label="产品名称"
          name="product_name"
          rules={[{ required: true, message: "请输入产品名称" }]}
          initialValue={data.product_name}
        >
          <Input placeholder="请输入产品名称" />
        </Form.Item>
        <Form.Item
          label="产品描述"
          name="product_describe"
          initialValue={data.product_describe}
        >
          <Input.TextArea placeholder="请输入产品描述" />
        </Form.Item>

        <Form.Item
          label="服务类型"
          name="service_type"
          rules={[{ required: true, message: "请选择服务类型" }]}
          initialValue={data.service_type.split(",").map((x) => Number(x))}
        >
          <Checkbox.Group options={ServiceTypeOptions}></Checkbox.Group>
        </Form.Item>
        {/* <Form.Item label="单笔融资最小限额">
          <Form.Item name="min_quota" noStyle initialValue={data.min_quota}>
            <InputNumber min={0}></InputNumber>
          </Form.Item>
          <span style={{ paddingLeft: 10 }}>元</span>
        </Form.Item>
        <Form.Item label="单笔融资最大限额">
          <Form.Item name="max_quota" noStyle initialValue={data.max_quota}>
            <InputNumber min={0}></InputNumber>
          </Form.Item>
          <span style={{ paddingLeft: 10 }}>元</span>
        </Form.Item>
        <Form.Item label="最低综合利率">
          <Form.Item
            name="min_interest_rate"
            noStyle
            initialValue={data.min_interest_rate}
          >
            <InputNumber min={0} max={100}></InputNumber>
          </Form.Item>
          <span style={{ paddingLeft: 10 }}>%</span>
        </Form.Item>
        <Form.Item label="最高综合利率">
          <Form.Item
            name="max_interest_rate"
            noStyle
            initialValue={data.max_interest_rate}
          >
            <InputNumber min={0} max={100}></InputNumber>
          </Form.Item>
          <span style={{ paddingLeft: 10 }}>%</span>
        </Form.Item>
        <Form.Item label="授信总额">
          <Form.Item name="total_quota" noStyle initialValue={data.total_quota}>
            <InputNumber min={0}></InputNumber>
          </Form.Item>
          <span style={{ paddingLeft: 10 }}>元</span>
        </Form.Item>
        <Form.Item label="融资期限">
          <Form.Item name="deadline" noStyle initialValue={data.deadline}>
            <InputNumber min={0}></InputNumber>
          </Form.Item>
          <span style={{ paddingLeft: 10 }}>天</span>
        </Form.Item> */}

        <Form.Item
          label="是否有追索权"
          name="right_of_recourse"
          initialValue={data.right_of_recourse}
        >
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
            <Radio value={2}>不限</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="保理类型"
          name="open_type"
          initialValue={data.open_type}
        >
          <Radio.Group>
            <Radio value={1}>公开</Radio>
            <Radio value={0}>隐蔽</Radio>
            <Radio value={2}>不限</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="是否需要质押"
          name="pledge_need"
          initialValue={data.pledge_need}
        >
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
            <Radio value={2}>不限</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="是否需要抵押"
          name="mortgage_need"
          initialValue={data.mortgage_need}
        >
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
            <Radio value={2}>不限</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="是否需要保证"
          name="guarantee_contract_sign"
          initialValue={data.guarantee_contract_sign}
        >
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
            <Radio value={2}>不限</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="是否启用"
          name="public_sign"
          initialValue={data.public_sign}
        >
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item noStyle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              margin: "36px 0 24px 0",
              padding: "0 100px",
            }}
          >
            <Button
              type="primary"
              htmlType={"submit"}
              style={{ padding: "0 36px" }}
              loading={fetching}
            >
              确认
            </Button>
            <Button onClick={handleCancel} style={{ padding: "0 36px" }}>
              返回
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  ) : null;
};

export default EditProduct;
