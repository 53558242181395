import * as React from "react";
import { getQuotaList } from "@/requests/quota";
import { message, Input, Table } from "antd";
import { toThousands } from "@/utils/string";
import "./index.less";
enum creditTerm {
  "三个月" = 3,
  "六个月" = 6,
  "九个月" = 9,
  "一年" = 12,
  "三年" = 36,
}

const QuotaList = () => {
  const [data, setData] = React.useState<QuotaItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [filterName, setFilterName] = React.useState("");

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    const res = await getQuotaList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
      ...(filterName && {
        company_name: filterName,
      }),
    });
    if (res.code === 0) {
      setData(res.data);
      setPagination({
        ...pagination,
        page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const colunms = [
    {
      title: "序号",
      dataIndex: "order",
      key: "order",
      align: "center" as "left",
      render: (text: any, record: QuotaItem, index: number) => `${index + 1}`,
    },
    {
      title: "公司名称",
      dataIndex: "company_name",
      key: "company_name",
      align: "center" as "center",
      render: (text: any, record: QuotaItem, index: number) =>
        `${record.company_name}`,
    },
    {
      title: "总额度（元）",
      dataIndex: "credit_line",
      key: "credit_line",
      align: "center" as "center",
      render: (text: any, record: QuotaItem, index: number) =>
        `${toThousands(record.credit_line)}`,
    },
    {
      title: "已用额度（元）",
      dataIndex: "used_quota",
      key: "used_quota",
      align: "center" as "center",
      render: (text: any, record: QuotaItem, index: number) =>
        `${toThousands(record.used_quota)}`,
    },
    {
      title: "剩余额度（元）",
      dataIndex: "remain_quota",
      key: "remain_quota",
      align: "center" as "center",
      render: (text: any, record: QuotaItem, index: number) =>
        `${toThousands(record.remain_quota)}`,
    },
    {
      title: "授信开始日期",
      dataIndex: "credit_start_time",
      key: "credit_start_time",
      align: "center" as "center",
      render: (text: any, record: QuotaItem, index: number) =>
        `${record.credit_start_time ? record.credit_start_time : "-"}`,
    },
    {
      title: "额度到期日",
      dataIndex: "credit_end_time",
      key: "credit_end_time",
      align: "center" as "center",
      render: (text: any, record: QuotaItem, index: number) =>
        `${record.credit_end_time}`,
    },
    {
      title: "授信期限",
      dataIndex: "credit_term",
      key: "credit_term",
      align: "center" as "center",
      render: (text: any, record: QuotaItem, index: number) =>
        `${creditTerm[record.credit_term]}`,
    },
  ];
  return (
    <div className="quota-list-page">
      <div className="tools-wrapper">
        <div className="filter-item">
          <Input.Search
            onSearch={() => fetchData()}
            style={{ maxWidth: 200 }}
            placeholder="搜索公司"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          ></Input.Search>
        </div>
      </div>
      <div className="table-container">
        <Table
          dataSource={data}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
            style: {
              marginRight: 16,
            },
          }}
          loading={loading}
          columns={[...colunms]}
        />
      </div>
    </div>
  );
};

export default QuotaList;
