import * as React from "react";
import { getProductList, deleteProduct } from "@/requests/product";
import {
  Form,
  Button,
  List,
  Card,
  Input,
  Tooltip,
  Modal,
  message,
  Badge,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import "./index.less";
import CreateProductModal from "./components/CreateProductModal";
import { useHistory } from "react-router";

const ProductList = () => {
  const [data, setData] = React.useState<ProductItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPaination] = React.useState({
    page: 1,
    pageSize: 30,
    total: 0,
  });

  const history = useHistory();

  const [createVisible, setCreateVisible] = React.useState(false);
  const fetchData = async (page?: number, pageSize?: number) => {
    setLoading(true);
    if (!page) {
      page = pagination.page;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    const res = await getProductList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
    });
    if (res.code === 0) {
      setData(res.data.reverse());
      setPaination({
        page,
        pageSize,
        total: res.total,
      });
    } else {
    }
    setLoading(false);
  };

  const handleDeleteProduct = (id: number) => {
    if (!id) return;
    Modal.confirm({
      title: "温馨提示",
      content: "确认删除该产品吗？",
      okText: "确认",
      onOk: async () => {
        const res = await deleteProduct({
          id,
        });
        if (res.code === 0) {
          message.success("删除成功");
          fetchData();
        } else {
          message.error(res.message);
        }
      },
      cancelText: "取消",
      onCancel: () => {},
    });
  };

  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="product-list-page">
      <div className="tools-wrapper">
        <div className="product-list-filter-input-container">
          <Form>
            <Form.Item label="产品名称" className="filter-item">
              <Input.Search></Input.Search>
            </Form.Item>
          </Form>
        </div>
        <div style={{ display: "flex" }}>
          <Button
            type="primary"
            onClick={() => {
              fetchData();
            }}
            icon={<RedoOutlined />}
            style={{ marginRight: 16 }}
          >
            刷新
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setCreateVisible(true);
            }}
            icon={<PlusOutlined />}
          >
            创建产品
          </Button>
        </div>
      </div>
      <div style={{ backgroundColor: "#FFFFFF", padding: 16 }}>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
            xxl: 4,
          }}
          dataSource={data}
          loading={loading}
          renderItem={(item) => {
            if (item === null) return null;
            return (
              <List.Item key={item.id}>
                <Card
                  title={item.product_name}
                  actions={[
                    <Tooltip title="编辑产品">
                      <span
                        onClick={() => {
                          history.push({
                            pathname: `/workbench/product/detail`,

                            state: {
                              id: item.id,
                              data: item,
                            },
                            search: `id=${item.id}`,
                          });
                        }}
                      >
                        <EditOutlined key="edit" style={{ marginRight: 5 }} />
                        编辑
                      </span>
                    </Tooltip>,
                    <Tooltip title="删除产品">
                      <span
                        onClick={() => {
                          handleDeleteProduct(item.id);
                        }}
                      >
                        <DeleteOutlined
                          key="delete"
                          style={{ marginRight: 5 }}
                        />
                        删除
                      </span>
                    </Tooltip>,
                  ]}
                >
                  <Form labelCol={{ md: 5, lg: 5 }} labelAlign={"left"}>
                    <Form.Item label="产品描述" style={{ marginBottom: 0 }}>
                      <div
                        style={{
                          height: 22,
                          overflow: "hidden",
                          maxWidth: 251,
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.product_describe}
                      </div>
                    </Form.Item>
                    <Form.Item label="创建者" style={{ marginBottom: 0 }}>
                      {item.create_user_name}
                    </Form.Item>
                    <Form.Item label="状态" style={{ marginBottom: 0 }}>
                      {item.public_sign === 1 ? (
                        <Badge status="success" text="使用中" />
                      ) : (
                        <Badge status="default" text="已关闭" />
                      )}
                    </Form.Item>
                  </Form>
                </Card>
              </List.Item>
            );
          }}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
            style: { marginRight: 16 },
            showTotal: (total) => <span>共{total}条数据</span>,
          }}
        />
      </div>
      <CreateProductModal
        visible={createVisible}
        setVisible={setCreateVisible}
        reload={fetchData}
      />
    </div>
  );
};

export default ProductList;
