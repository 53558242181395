/// <reference path="type.d.ts" />
/// <reference path="user.d.ts" />

import request from "./_base";

/**
 * 登录
 * @param  {LoginReq} input
 */
export async function login(
  input: LoginReq
): Promise<ResponseProto<{ data: UserInfoItem }>> {
  return request({
    method: "POST",
    url: `/login`,
    data: input,
  }) as any;
}

/**
 * 获取用户登录信息
 */
export async function userInfo(): Promise<
  ResponseProto<{ data: UserInfoItem }>
> {
  return request({
    method: "GET",
    url: `/user/info`,
  }) as any;
}

/**
 * 注册
 * @param  {RegisterReq} input
 */
export async function register(
  input: RegisterReq
): Promise<ResponseProto<{ data: UserInfoItem }>> {
  return request({
    method: "POST",
    url: `/user/register`,
    data: input,
  }) as any;
}

/**
 * 用户名唯一校验
 * @param  {string} user_name
 */
export async function validateUserName(input: {
  user_name: string;
}): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/user/register/username/unique`,
    data: input,
  }) as any;
}

/**
 * 根据phone获取图片校验码
 * @param  {string} phone
 */
export async function getRegisterImgCode(
  phone: UserInfoItem["phone"]
): Promise<ResponseProto<{}>> {
  return request({
    method: "GET",
    url: `/user/register/image/${phone}`,
  }) as any;
}

/**
 * 退出登录
 */
export async function logout(): Promise<ResponseProto<{}>> {
  return request({
    method: "GET",
    url: `/logout`,
  }) as any;
}

/**
 * 获取注册短信验证码
 * @param {GetRegisterSmCodeReq} input
 */
export async function getRegisterSmCode(
  input: GetRegisterSmCodeReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/user/register/sm/code`,
    data: input,
  }) as any;
}

/**
 * 用户手机号唯一校验
 * @param  {string} phone
 */
export async function validatePhone(input: {
  phone: string;
}): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/user/register/phone/unique`,
    data: input,
  }) as any;
}

/**
 * 用户邮箱唯一校验
 * @param  {string} email
 */
export async function validateEmail(input: {
  email: string;
}): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/user/register/email/unique`,
    data: input,
  }) as any;
}

/**
 * 查询user list
 * @param  {GetUserListReq} input
 */
export async function getUserList(
  input: GetUserListReq
): Promise<ResponseProto<{ data: UserInfoItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/user/list`,
    data: input,
  }) as any;
}

/**
 * 更新用户和角色关系t
 * @param  {UpdateUserRoleReq} input
 */
export async function updateUserRole(
  input: UpdateUserRoleReq
): Promise<ResponseProto<{ data: null }>> {
  return request({
    method: "PUT",
    url: `/user/role`,
    data: input,
  }) as any;
}
