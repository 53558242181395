/// <reference path="type.d.ts" />
/// <reference path="contractCommon.d.ts" />

import request from "./_base";

/**
 * 获取合同列表
 * @param  {GetContractListReq} input
 */
export async function getContractList(
  input: GetContractListReq
): Promise<ResponseProto<{ data: ContractListItem[]; total: number }>> {
  return request({
    method: "POST",
    url: `/contract-common/list`,
    data: input,
  }) as any;
}

/**
 * 合同预览
 * @param  {ContractPreviewReq} input
 */
export async function contractPreview(
  input: ContractPreviewReq
): Promise<ResponseProto<{ data: ContractPreviewRes }>> {
  return request({
    method: "POST",
    url: `/contract-common/sso-link`,
    data: input,
  }) as any;
}

/**
 * 通用发起合同
 * @param  {SendContractReq} input
 */
export async function sendContract(
  input: SendContractReq
): Promise<ResponseProto<{ data: any }>> {
  return request({
    method: "POST",
    url: `/contract-common/launch/new`,
    data: input,
  }) as any;
}
