import * as React from "react";
import { Button, Modal, Form, Radio, Input, message, InputNumber } from "antd";

import { businessCFOApproval } from "@/requests/business";

interface PropsType {
  visible: boolean;
  setVisible: Function;
  fetchData: Function;
  data: BusinessApplyItem;
}

const CFOApprovalModal: React.FC<PropsType> = (props: PropsType) => {
  const { visible, setVisible, fetchData, data } = props;

  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [isPass, setIsPass] = React.useState(0);
  const [hasAdvance, setHasAdvance] = React.useState(1);

  const handleCancel = () => {
    setVisible(false);
  };

  const doApproval = async (input: BusinessCFOApprovalReq) => {
    setLoading(true);
    const res = await businessCFOApproval(input);
    if (res.code === 0) {
      message.success("提交成功");
      fetchData();
      setVisible(false);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const handleOK = (values: any) => {
    console.log(values);
    doApproval({
      ...values,

      task_id: data.task_id,
    });
  };

  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>业务审核
        </span>
      }
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      maskClosable={false}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            justifyContent: "center",
          }}
        >
          <Form form={form} preserve={false} onFinish={handleOK}>
            <Form.Item
              label="审批结果"
              name="approval"
              rules={[{ required: true, message: "请选择审批结果" }]}
              initialValue={isPass}
            >
              <Radio.Group onChange={(e) => setIsPass(e.target.value)}>
                <Radio value={1}>通过</Radio>
                <Radio value={0}>不通过</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="审批意见"
              name="comment"
              rules={[{ required: true, message: "请输入审批意见" }]}
            >
              <Input.TextArea placeholder="请输入审批意见"></Input.TextArea>
            </Form.Item>
            {isPass === 1 && (
              <>
                <Form.Item
                  label="是否有预付款"
                  name="have_advance"
                  rules={[{ required: true, message: "请选择预付款情况" }]}
                  initialValue={hasAdvance}
                >
                  <Radio.Group onChange={(e) => setHasAdvance(e.target.value)}>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                  </Radio.Group>
                </Form.Item>
                {hasAdvance === 1 && (
                  <>
                    <Form.Item
                      label="预付款类目"
                      name="advance_type"
                      rules={[{ required: true, message: "请输入预付款类目" }]}
                    >
                      <Input placeholder="预付款类目" />
                    </Form.Item>
                    <Form.Item label="预付款金额" required>
                      <Form.Item
                        noStyle
                        name="advance_money"
                        rules={[
                          { required: true, message: "请输入预付款类目" },
                        ]}
                      >
                        <InputNumber
                          placeholder="金额"
                          style={{ width: 100 }}
                        />
                      </Form.Item>
                      <span style={{ paddingLeft: 8 }}>元</span>
                    </Form.Item>
                    <Form.Item
                      label="是否到账"
                      name="advance_arrival"
                      rules={[
                        { required: true, message: "请选择预付款是否到账" },
                      ]}
                      initialValue={0}
                    >
                      <Radio.Group>
                        <Radio value={1}>是</Radio>
                        <Radio value={0}>否</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </>
                )}
              </>
            )}

            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 36,
                  padding: "0 100px",
                }}
              >
                <Button
                  style={{ padding: "0 36px" }}
                  loading={loading}
                  onClick={handleCancel}
                >
                  取消
                </Button>
                <Button
                  type="primary"
                  htmlType={"submit"}
                  style={{ padding: "0 36px" }}
                >
                  完成
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default CFOApprovalModal;
