import * as React from "react";
import { getBusinessApplyList } from "@/requests/business";
import { Input, Button, Form, List, DatePicker, Tag } from "antd";
import { RedoOutlined, SearchOutlined } from "@ant-design/icons";
import { toThousands } from "@/utils/string";
import "../styles/index.less";
import { useHistory, useLocation } from "react-router";

enum Status {
  "待业务员审核" = 0,
  "待业务主管审核" = 1,
  "业务员审核失败，申请关闭" = 2,
  "待风控人员审核" = 3,
  "风控部门审核不通过，待业务主管重审核" = 4,
  "待总经理审核" = 5,
  "财务人员审核不通过，待风控主管重审核" = 6,
  "待风控主管审核" = 7,
  "风控人员审核不通过，请重新提交审核" = 8,
  "待财务人员审核" = 9,
  "风控主管审核不通过，待风控人员重审核" = 10,
  "审核通过" = 11,
  "总经理审核不通过，待财务人员重审核" = 12,
}

const StatusTag = ({ status }: { status: number }) => {
  if (status % 2 === 0) {
    if (status === 2) {
      return <Tag color={"red"}>{Status[status]}</Tag>;
    }
    if (status === 0) {
      return <Tag color={"volcano"}> {Status[status]}</Tag>;
    }
    return <Tag color={"warning"}>{Status[status]}</Tag>;
  } else {
    return <Tag color={"cyan"}>{Status[status]}</Tag>;
  }
};

const BusinessApplyList = () => {
  const [data, setData] = React.useState<BusinessApplyItem[]>([]);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = React.useState(false);

  const history = useHistory();
  const location = useLocation();

  const fetchData = async (page?: number, pageSize?: number) => {
    setLoading(true);
    if (!page) {
      page = pagination.page;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    const res = await getBusinessApplyList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
    });
    if (res.code === 0) {
      setData(res.data);
      setPagination({
        ...pagination,
        page: page,
        pageSize: pageSize,
        total: res.total,
      });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (location.pathname === "/workbench/business/apply-list") {
      fetchData();
    }
  }, [location]);
  return (
    <div className="business-apply-list-page">
      <div className="tools-wrapper">
        <Form colon={false} layout="inline">
          <Form.Item label="企业名称" className="filter-item">
            <Input style={{ maxWidth: 200 }} placeholder="全部"></Input>
          </Form.Item>

          <Form.Item label="申请时间" className="filter-item">
            <DatePicker.RangePicker style={{ minWidth: 200 }} showTime />
          </Form.Item>
        </Form>

        <div style={{ display: "flex" }}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            style={{ marginRight: 10 }}
            onClick={() => fetchData()}
          >
            搜索
          </Button>
          <Button icon={<RedoOutlined />}>重置</Button>
        </div>
      </div>
      <div style={{ backgroundColor: "#ffffff", padding: 16, marginTop: 16 }}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          loading={loading}
          renderItem={(item) => {
            return (
              <List.Item
                actions={[
                  <StatusTag status={item.status} />,
                  <a
                    key="view"
                    onClick={() =>
                      history.push({
                        pathname: `/workbench/business/apply-list/detail`,
                        state: {
                          id: item.id,
                        },
                        search: `id=${item.id}`,
                      })
                    }
                  >
                    查看详情
                  </a>,
                ]}
              >
                <List.Item.Meta
                  title={
                    <span style={{ color: "inherit" }}>
                      企业名称：
                      <span>
                        {item.company_name ? item.company_name : "未知"}
                      </span>
                    </span>
                  }
                  description={
                    <div style={{ display: "flex" }}>
                      <span>{`申请额度：${toThousands(item.quota)} 元`}</span>
                      <span
                        style={{ padding: "0 16px" }}
                      >{`申请时间：${item.create_time}`}</span>
                    </div>
                  }
                ></List.Item.Meta>
              </List.Item>
            );
          }}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
          }}
        ></List>
      </div>
    </div>
  );
};

export default BusinessApplyList;
