import { generateStoreManager } from "@/react-helpers/store";
import { RouteComponentProps } from "react-router-dom";

const { attatch, useCtxState } = generateStoreManager();
interface Store {
  sidebarCollapsed: boolean;
  userInfo: UserInfoItem | undefined;
}

const state: Store = {
  sidebarCollapsed: false,
  userInfo: undefined,
};

const reducer: React.Reducer<typeof state, any> = (state, action) => {
  switch (action.type) {
    case "toggle:sidebarCollapsed":
      return {
        ...state,
        sidebarCollapsed:
          typeof action.sidebarCollapsed === "boolean"
            ? action.sidebarCollapsed
            : !state.sidebarCollapsed,
      };
    case "SAVE_INFO":
      return {
        ...state,
        userInfo: action.info,
      };
    default:
      return state;
  }
};

const computer = {};

export const connect = (
  Target:
    | React.Component
    | React.FunctionComponent
    | React.FunctionComponent<RouteComponentProps>
) => attatch(Target, { state, reducer });

export { useCtxState, computer };
